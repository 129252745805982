/**
System: Whistle It
Developer: Front End Team 3 (Safeer Baig, Nabil Ahmad)
Organization: Programmer 
Purpose: This file is responsible for handling all the store functionality, It handle Message Send, Delete, Edit, in store.
 */
import messageAPI from "@/api/messageAPI";
import debug from "@/console";
import axios from "axios";
import Vue from "vue";
import { eventBus } from "@/main";
/**
 * this function returns the data object that will pass to socket.
 * args : (payload)
 */
var messageStore = {
  getters: {

    chatLinkPreview: (state) => state.chatLinkPreview,
    replyLinkPreview: (state) => state.replyLinkPreview,




    /*
     * this getter will get the search message result statue.
     */
    getSearchProgress: (state) => state.searchProgress,
    getPinProgress: (state) => state.pinProgress,
    getFromUnsceen: (state) => state.fromUnsceen,
    getJumpMessage: (state) => state.jumpMessage,
    getSearchedMessages: (state) => state.searchedMessages,
    /**
     * this getter will get all messages array of currently login user from storeData.
     */
    getCurrentMessages: (state, getters, rootState, rootGetters) => {
      if (rootState.messages[rootState.selectedChannel._id]) {
        return rootState.messages[rootState.selectedChannel._id];
      } else return [];
    },
    getMessagesByChannelId: (state, getters, rootState, rootGetters) => (
      channel_id
    ) => rootState.messages[channel_id],
    loggedInUserDetails: (state, getters, rootState) => {
      if (rootState.storeData) {
        return {
          mobile_notification_delay:
            rootState.storeData.mobile_notification_delay,
          msg_input_send: rootState.storeData.msg_input_send,
          allow_access: rootState.storeData.allow_access,
          custom_status: rootState.storeData.custom_status,
          designation: rootState.storeData.designation,
          do_not_disturb: rootState.storeData.do_not_disturb,
          email: rootState.storeData.email,
          email_notification: rootState.storeData.email_notification,
          google_secret: rootState.storeData.google_secret,
          in_app_notification: rootState.storeData.in_app_notification,
          name: rootState.storeData.name,
          notification_sound: rootState.storeData.notification_sound,
          profile_picture: rootState.storeData.profile_picture,
          push_notification: rootState.storeData.push_notification,
          selected_company: rootState.storeData.selected_company,
          status: rootState.storeData.status,
          token: rootState.storeData.token,
          two_fa: rootState.storeData.two_fa,
          version: rootState.storeData.version,
          _id: rootState.storeData._id,
        };
      } else return null;
    },
    /**
     * this getter will login user from storeData array
     */
    getLoggedInUserDetail: (state, getters, rootState) => rootState.storeData,
    /**
     * this getter will take message id and will return message Index
     * args: messageId
     */
    getMessageIndex: (state, getters) => (messageId) => {
      return getters.getCurrentMessages.findIndex(function (item) {
        return item._id == messageId;
      });
    },
    getReplyMessageIndex: (state, getters) => (messageId) => {
      return getters.getReplyedMessages.child.findIndex(function (item) {
        return item._id == messageId;
      });
    },

    getSearchMessageIndex: (state) => (messageId) => {
      return state.jumpMessage.messages.findIndex(function (item) {
        return item._id == messageId;
      });
    },
    /**
     * this getter will take message id and will return message Index
     * args: (messageId, preMessages)
     */
    getSendMessageIndex: () => (messageId, responceId, preMessages) => {
      return preMessages?.findIndex(function (item) {
        return item._id == messageId || item._id == responceId;
      });
    },
    //for normal messages pagination
    getMessagePaginateRes: (state) => state.isNoMsgInRes,
    //for searched previous messages pagination
    getPreMsgPaginateRes: (state) => state.isNoPreMsg,
    //for searched next messages pagination
    getNxtMsgPaginateRes: (state) => state.isNoNextMsg,
    //if user change company, team or channel during screen recording then save previous selected items
    getPrevSelectedItems: (state) => state.prevSelectedItems,
    // Get Pinned Messages for the specific channel
    getPinnedMessages: (state) => state.pinned_messages,
    getReplyedMessages: (state) => state.replyedMessages,
    getReplyStatus: (state) => state.replyStatus,
    getParentReplyId: (state) => state.parentReplyId,
    fetchingChannelMessages: (state) => state.channelMessagesFetched,
  },
  actions: {
  
    async fetchChatLinkPreview({ commit }, { url, isPending, signal }) {
      try {
        const requestBody = { url , isPending};
        const response = await new Promise((resolve, reject) => {
          const apiCall = messageAPI.linkPreveiw(
            requestBody,
            (error, response) => {
              if (error) {
                reject(error);
              } else {
                resolve(response);
              }
            }
          );

          // Use the provided signal to abort the fetch if needed
          signal.addEventListener("abort", () => {
            if (apiCall && typeof apiCall.cancel === "function") {
              apiCall.cancel();
            }
            reject(new DOMException("Aborted", "AbortError"));
          });
        });

        if (typeof response === "string" && response.trim().startsWith("<")) {
          commit("CLEAR_CHAT_LINK_PREVIEW");
          throw new Error("Received HTML instead of JSON");
        } else {
          commit("SET_CHAT_LINK_PREVIEW", { ...response, url });
        }
      } catch (error) {
        if (error.name !== "AbortError") {
          // Ignore AbortError and handle other errors
          commit("CLEAR_CHAT_LINK_PREVIEW");
        }
      }
    },

    async fetchReplyLinkPreview({ commit }, { url, signal }) {
      try {
        const requestBody = { url };
        const response = await new Promise((resolve, reject) => {
          const apiCall = messageAPI.linkPreveiw(
            requestBody,
            (error, response) => {
              if (error) {
                reject(error);
              } else {
                resolve(response);
              }
            }
          );

          // Use the provided signal to abort the fetch if needed
          signal.addEventListener("abort", () => {
            if (apiCall && typeof apiCall.cancel === "function") {
              apiCall.cancel();
            }
            reject(new DOMException("Aborted", "AbortError"));
          });
        });

        if (typeof response === "string" && response.trim().startsWith("<")) {
          commit("CLEAR_REPLY_LINK_PREVIEW");
          throw new Error("Received HTML instead of JSON");
        } else {
          commit("SET_REPLY_LINK_PREVIEW", { ...response, url });
        }
      } catch (error) {
        if (error.name !== "AbortError") {
          commit("CLEAR_REPLY_LINK_PREVIEW");
        }
      }
    },

    resetPagination({ commit }) {
      commit("setPaginationRes", false);
      commit("setPreMsgPaginateRes", false);
      commit("setNxtMsgPaginateRes", false);
    },
    resetSearchMessages({ commit }) {
      commit("setJumpMessage", null);
    },
    removeMessageRequest({ commit, rootState, rootGetters }, payload) {
      commit("removeMessageFromQueue", {
        message: payload.singleMessage,
        queue: rootState.queue,
      });
      if (payload.reply) {
        commit("removeReplyMessageFromView", {
          message: payload.singleMessage,
          channelId: rootGetters.getSelectedChannel._id,
        });
      } else {
        commit("removeMessageFromView", {
          messages: rootState.messages,
          message: payload.singleMessage,
          channelId: rootGetters.getSelectedChannel._id,
        });
      }
    },

    /**
     * this action is responsible for storing messages in vuex store. and calling api to add in backend
     */
    async sendMessageStore(
      { rootGetters, commit, getters, dispatch, rootState },
      messageBody
    ) {
      return new Promise((resolve, reject) => {
        //store reference of previous clicked channel if user immediatly changed channel after send message then update his/her message status after API response
        let previousMessages = getters.getCurrentMessages;
        //if files are attached then make form data object of message
        // let fileMessage = await dispatch("makeFormDataOfFiles", messageBody);
        let channelId = rootState.selectedChannel._id;
        let channel = rootGetters.getSelectedChannel;

        // get the message body channel for update the message
        if (messageBody.channel_id) {
          previousMessages = getters.getMessagesByChannelId(
            messageBody.channel_id
          );
          channelId = messageBody.channel_id;
          channel = rootGetters.getChannelByCompanyIdChannelId(
            messageBody.company_id,
            messageBody.channel_id
          );
        }

        // handle when user send intant message or not
        if (!messageBody.isMsgAlreadyAdded) {
          //add new message in state
          commit("addNewMessage", {
            currentMessages: previousMessages,
            message: messageBody,
          });
        }
        // success callback of adding message
        var done = async (response) => {
          //get message index from store Data
          let index = await getters.getSendMessageIndex(
            messageBody._id,
            response.data != undefined ? response.data._id : null,
            previousMessages
          );
          if (response.status == 200) {
            resolve(response);
            if (index > -1) {
              commit("updateMsgAfterResponse", {
                currentMessages: previousMessages,
                index,
                id: response.data._id,
                data: response.data,
                channel,
              });
              //if messages length is greater then 20, remove last message
            }
          } else {
            reject(response);

            //on message conflict not remove message only update message info
            if (response.response.status == 409) {
              dispatch("conflictMessageHandling", {
                currentMessages: previousMessages,
                index,
                messageBody,
                channelId,
                channel,
              });
            } else if (response.response.status != 401) {
              //handle error in sending message (500: internal server error, 400: not send required fields)
              commit("removeBadRequest", {
                currentMessages: previousMessages,
                index,
              });
            }
          }
        };
        // get the message body channel for send create message call
        let messageTeamId = rootGetters.getSelectedTeam._id;
        if (messageBody.team_id) {
          messageTeamId = messageBody.team_id;
        }
        // calling message client API


        const { chatLinkPreview, replyLinkPreview, ...rest } = messageBody;

        messageAPI.sendMessage(
          channelId,
          messageTeamId,
          rest.audio,
          rest.video,
          rest.message,
          rest.is_important,
          rest.send_after_seconds,
          rest.files,
          rest.mention_users,
          rest._id,
          undefined,
          undefined,
          rest.user_time,
          rest.support_message,
          rest.announcement,
          rest.shared_company_ids,
          rest.shared_team_ids,
          rest.shared_users_ids,
          undefined,
          rest.zoom,
          channel.invited,
          channel.secret_key,
          rest.emoji,
          rest.gifs_data,
          rest,
          chatLinkPreview,
          replyLinkPreview,
          done,

        );

      });
    },
    /**
     * this action is responsible for storing messages in vuex store. and calling api to add in backend
     */
    removeFileFromServer(context, payload) {
      let done = async (response) => {
        debug.log(response, "response");
      };
      // calling message client API
      messageAPI.removeFileFromServer(
        payload,
        context.rootGetters.getSelectedChannel._id,
        context.rootGetters.getSelectedChannel.invited,
        context.rootGetters.getSelectedChannel.secret_key,
        done
      );
    },
    /**
     * make form data if there is any file attached
     * args : ({ rootGetters }, messageBody)
     */
    makeFormDataOfFiles({ rootGetters, rootState }, messageBody) {
      let fileMsgFormData = new FormData();
      if (messageBody.files) {
        fileMsgFormData.append("temp_id", messageBody._id);
        for (var i = 0; i < messageBody.files.length; i++) {
          fileMsgFormData.append("files[]", messageBody.files[i]);
        }
        fileMsgFormData.append("message", messageBody.message);
        fileMsgFormData.append("team_id", rootGetters.getSelectedTeam._id);
        fileMsgFormData.append("channel_id", rootState.selectedChannel._id);
        if (messageBody.replying_id && messageBody.replying_message) {
          fileMsgFormData.append(
            "replying_message",
            messageBody.replying_message
          );
          fileMsgFormData.append("replying_id", messageBody.replying_id);
        }
        if (messageBody.mention_users.length > 0) {
          fileMsgFormData.append("mention_users[]", messageBody.mention_users);
        }
        fileMsgFormData.append("user_time", messageBody.user_time);
        if (messageBody.send_after) {
          fileMsgFormData.append("send_after", messageBody.send_after_seconds);
        }
        if (messageBody.support_message) {
          fileMsgFormData.append(
            "support_message",
            messageBody.support_message
          );
        }
        return fileMsgFormData;
      } else {
        return false;
      }
    },

    /**
     * this function is responsible for handling message conflict error
     * args :(requestBody) currentMessages, index, channelId
     */
    conflictMessageHandling({ commit, rootGetters }, requestBody) {
      // success callback of adding message
      var done = async (response) => {
        if (response.status == 200) {
          if (response.data.messages != null) {
            commit("updateMsgAfterResponse", {
              currentMessages: requestBody.currentMessages,
              index: requestBody.index,
              id: response.data.messages._id,
              data: response.data.messages,
              channel: requestBody.channel,
            });
          } else {
            //handle error in sending message (500: internal server error, 400: not send required fields)
            commit("removeBadRequest", {
              currentMessages: requestBody.currentMessages,
              index: requestBody.index,
            });
          }
        }
      };
      // calling message client API
      messageAPI.readConflictMessage(
        requestBody.channelId,
        requestBody.messageBody.sender_id,
        requestBody.messageBody._id,
        rootGetters.getSelectedChannel.invited,
        rootGetters.getSelectedChannel.secret_key,
        done
      );
    },
    /**
     * this function will delete message from store immediately and then it will call the api at the end and check responce status.
     * args :(messageId)
     */
    deleteMessage({ rootGetters, commit, getters, rootState }, data) {
      // when jump message  is not null then set current messages to jump message
      if (getters.getJumpMessage) {
        commit("directDelete", {
          //  check if message is reply or not
          currentMessages: data.isReply
            ? getters.getReplyedMessages.child
            : getters.getJumpMessage.messages,
          messageIndex: data.isReply
            ? getters.getReplyMessageIndex(data.messageId)
            : getters.getSearchMessageIndex(data.messageId),
        });
      }
      // when jump message is null then delete message from current messages
      else {
        commit("directDelete", {
          currentMessages: data.isReply
            ? getters.getReplyedMessages.child
            : getters.getCurrentMessages,
          messageIndex: data.isReply
            ? getters.getReplyMessageIndex(data.messageId)
            : getters.getMessageIndex(data.messageId),
        });
      }
      // // success callback of adding message
      var done = (response) => {
        if (response.status == 200) {
          debug.log("success");
        }
      };
      // calling message client API
      messageAPI.updateMessage(
        data.messageId,
        rootState.selectedChannel._id,
        data.messageId.message, //undefined
        data.messageId.delete_after, //undefined
        1, //delete_at
        data.messageId.is_read, //undefined
        data.messageId.single_read, //undefined
        undefined,
        undefined,
        rootGetters.getSelectedChannel.invited,
        rootGetters.getSelectedChannel.secret_key,
        undefined,
        done
      );
    },
    /**
     * this function will delete message from store immediately and then it will call the api at the end and check responce status.
     * args :(messageId)
     */
    messageReaction({ rootGetters, commit, getters, rootState }, data) {
      // when jump message  is not null then set current messages to jump message
      if (getters.getJumpMessage) {
        commit("messageReaction", {
          currentMessages: data.isReply
            ? getters.getReplyedMessages.child
            : getters.getJumpMessage.messages,
          messageIndex: data.isReply
            ? getters.getReplyMessageIndex(data.messageId)
            : getters.getSearchMessageIndex(data.messageId),
          reaction: data.reaction,
          user_id: getters.loggedInUserDetails._id,
        });
      } else {
        commit("messageReaction", {
          currentMessages: data.isReply
            ? getters.getReplyedMessages.child
            : getters.getCurrentMessages,
          messageIndex: data.isReply
            ? getters.getReplyMessageIndex(data.messageId)
            : getters.getMessageIndex(data.messageId),
          reaction: data.reaction,
          user_id: getters.loggedInUserDetails._id,
        });
      }
      // // success callback of adding message
      var done = (response) => {
        if (response.status == 200) {
          debug.log("success");
        }
      };
      // calling message client API
      messageAPI.updateMessage(
        data.messageId,
        rootState.selectedChannel._id,
        data.messageId.message, //undefined
        data.messageId.delete_after, //undefined
        undefined,
        data.messageId.is_read, //undefined
        data.messageId.single_read, //undefined
        1,
        data.reaction,
        rootGetters.getSelectedChannel.invited,
        rootGetters.getSelectedChannel.secret_key,
        undefined,
        done
      );
    },
    /*
     * this function will edit message from store immediately and then it will call the api at the end and check responce status.
     * args: ({ rootGetters, commit, getters }, requestBody)
     */
    editMessage({ rootState, commit, getters, rootGetters }, messageBody) {
      // passing parameters to editMessage mutation to update edit message.
      // when jump message is not null then set current messages to jump message
      if (getters.getJumpMessage) {
        commit("editMessage", {
          // check if message is reply or not
          currentMessages: messageBody.isReply
            ? getters.getReplyedMessages.child
            : getters.getJumpMessage.messages,
          messageIndex: messageBody.isReply
            ? getters.getReplyMessageIndex(messageBody.messageId)
            : getters.getSearchMessageIndex(messageBody.messageId),
          editedText: messageBody.editedText,
          emoji: messageBody.emoji,
        });
      } else {
        commit("editMessage", {
          currentMessages: messageBody.isReply
            ? getters.getReplyedMessages.child
            : getters.getCurrentMessages,
          messageIndex: messageBody.isReply
            ? getters.getReplyMessageIndex(messageBody.messageId)
            : getters.getMessageIndex(messageBody.messageId),
          editedText: messageBody.editedText,
          emoji: messageBody.emoji,
        });
      }
      // success callback of edit message
      var done = (response) => {
        if (response.status == 200) debug.log("successfuuly updated");
      };
      // // calling message client API
      messageAPI.updateMessage(
        messageBody.messageId,
        rootState.selectedChannel._id,
        messageBody.editedText,
        messageBody.delete_after, //undefined
        messageBody.delete_at, //undefined
        messageBody.is_read, //undefined
        messageBody.single_read, //undefined
        undefined,
        undefined,
        rootGetters.getSelectedChannel.invited,
        rootGetters.getSelectedChannel.secret_key,
        messageBody.emoji,
        done
      );
    },
    /**
     * this function will forward message from store immediately and then it will call the api at the end and check responce
     * status.
     * args: (rootGetters, requestBody)
     */
    forwardMessage({ rootGetters }, requestBody) {
      // success callback of forward message
      return new Promise((resolve, reject) => {
        var done = (response) => {
          if (response.status == 200) resolve(response);
          else reject(response);
        };
        debug.log("requestBody.channel_ids:::::", requestBody.channel_ids);
        // calling message client API
        messageAPI.forwardMessage(
          requestBody.user_time,
          requestBody.message_id,
          requestBody.channel_ids,
          requestBody.include_thread,
          requestBody.original_sender,
          rootGetters.getSelectedTeam._id,
          done
        );
      });
    },
    /*
     * this function is responsible for schedule Delete Message from store. It sets the delete_after and then in success it sets the delete_at and remove delete_after.
     * args: (context, requestBody)
     */
    scheduleDeleteMessage(
      { rootState, commit, getters, rootGetters },
      requestBody
    ) {
      if (getters.getJumpMessage) {
        commit("scheduleDeleteMessage", {
          //  check if message is reply or not
          currentMessages: requestBody.isReply
            ? getters.getReplyedMessages.child
            : getters.getJumpMessage.messages,
          messageIndex: requestBody.isReply
            ? getters.getReplyMessageIndex(requestBody.messageId)
            : getters.getSearchMessageIndex(requestBody.messageId),
          delete_after: Date.now() + requestBody.delete_after * 1000,
        });
      } else {
        commit("scheduleDeleteMessage", {
          currentMessages: requestBody.isReply
            ? getters.getReplyedMessages.child
            : getters.getCurrentMessages,
          messageIndex: requestBody.isReply
            ? getters.getReplyMessageIndex(requestBody.messageId)
            : getters.getMessageIndex(requestBody.messageId),
          delete_after: Date.now() + requestBody.delete_after * 1000,
        });
      }
      // success callback of adding message
      var done = (response) => {
        if (response.status == 200) {
          debug.log("success");
        } else {
          debug.log("failed");
        }
      };
      // calling message client API
      messageAPI.updateMessage(
        requestBody.messageId,
        rootState.selectedChannel._id,
        requestBody.editedText, //undefined
        requestBody.delete_after,
        requestBody.delete_at, //undefined
        requestBody.is_read, //undefined
        requestBody.single_read, //undefined
        undefined,
        undefined,
        rootGetters.getSelectedChannel.invited,
        rootGetters.getSelectedChannel.secret_key,
        undefined,
        done
      );
    },
    /*
     * this function is responsible for search Message in current channel. It passes the Text to be search and channel id to the API client.
     * args: (context, searchText)
     */
    searchMessage({ rootState, commit, rootGetters }, payload) {
      return new Promise((resolve, reject) => {
        commit("setSearchProgress", true);
        // success callback of adding message
        var done = (response) => {
          commit("setSearchProgress", false);
          if (response.status == 200) {
            (response.channelId = rootState.selectedChannel._id),
              commit(
                "setSearchedMessages",
                response.data[rootState.selectedChannel._id].data
              );
            resolve(response);
          } else {
            debug.log("failed");
            reject(response);
          }
        };
        // calling message client API
        messageAPI.searchMessage(
          rootState.selectedChannel._id,
          payload.search,
          payload.start_date,
          payload.end_date,
          payload.search_in_webhook,
          payload.from,
          payload.page,
          rootGetters.getSelectedChannel.invited,
          rootGetters.getSelectedChannel.secret_key,
          done
        );
      });
    },
    /*
     * this function is responsible for sending the next 20 messages after clicking the jump of searched message. It passes the message of current searched message and channel id to the API client.
     * args: (context, searchText)
     */
    jumpToMessage({ getters, rootState, commit, rootGetters }, messageId) {
      // success callback of adding message
      commit("setJumpMessage", null);
      var done = (response) => {
        try {
          if (response.status == 200) {
            response.data.messages =
              response.data[rootState.selectedChannel._id];
            commit("setJumpMessage", response.data);
            setTimeout(function () {
              if (getters.getJumpMessage != null) {
                document
                  .getElementById(
                    getters.getJumpMessage.messages[
                      getters.getSearchMessageIndex(messageId.messageId)
                    ]._id
                  )
                  .classList.add("reply-bg");
                document
                  .getElementById(
                    getters.getJumpMessage.messages[
                      getters.getSearchMessageIndex(messageId.messageId)
                    ]._id
                  )
                  .scrollIntoView({
                    block: "center",
                  });
              }
            }, 300);
            setTimeout(function () {
              if (getters.getJumpMessage != null) {
                let messageID =
                  getters.getJumpMessage.messages[
                    getters.getSearchMessageIndex(messageId.messageId)
                  ]._id;
                if (messageID) {
                  document
                    .getElementById(messageID)
                    .classList.remove("reply-bg");
                }
              }
            }, 3500);
          } else {
            debug.log("failed");
          }
        } catch (error) {
          return;
        }
      };
      // calling message client API
      messageAPI.specificMessage(
        rootState.selectedChannel._id,
        messageId,
        rootGetters.getSelectedChannel.invited,
        rootGetters.getSelectedChannel.secret_key,
        done
      );
    },
    /**
     * this function is responsible for message pagination
     * args: (getters, rootGetters)
     */
    messagePagination({ getters, rootState, commit, rootGetters }) {
      let previousMessages = getters.getCurrentMessages;
      return new Promise((resolve) => {
        // success callback of adding message
        var done = (response) => {
          if (
            response.data &&
            response.data[rootState.selectedChannel._id].length == 0
          ) {
            commit("setPaginationRes", true);
          }
          commit("addPaginatedMessages", {
            newMessages: response.data[rootState.selectedChannel._id],
            previousMessages,
          });
          resolve(response);
        };
        // calling message client API
        messageAPI.messagePagination(
          rootState.selectedChannel._id,
          previousMessages[previousMessages.length - 1]._id,
          "previous",
          rootGetters.getSelectedChannel.invited,
          rootGetters.getSelectedChannel.secret_key,
          done
        );
      });
    },
    /**
     *  this function is responsible for serached message pagination (previous)
     */
    searchPreMsgPagination({ commit, getters, rootState, rootGetters }) {
      return new Promise((resolve) => {
        var done = (response) => {
          //if there is no more messages on response
          if (response.data[rootState.selectedChannel._id].length == 0) {
            //set paginationResn to true to show channel meta data
            commit("setPreMsgPaginateRes", true);
          }

          commit(
            "addPreSearchMsgsPag",
            response.data[rootState.selectedChannel._id]
          );
          resolve(response);
        };
        messageAPI.messagePagination(
          rootState.selectedChannel._id,
          getters.getJumpMessage.messages[0]._id,
          "previous",
          rootGetters.getSelectedChannel.invited,
          rootGetters.getSelectedChannel.secret_key,
          done
        );
      });
    },
    /**
     * this function is responsible for serached message pagination (next)
     */
    searchNextMsgPagination({ commit, getters, rootState, rootGetters }) {
      return new Promise((resolve, reject) => {
        var done = (response) => {
          if (response.status == 200) {
            if (response.data[rootState.selectedChannel._id].length == 0) {
              commit("setNxtMsgPaginateRes", true);
            }
            commit(
              "addNextSearchMsgsPag",
              response.data[rootState.selectedChannel._id]
            );
            resolve(response);
          } else {
            reject(response);
          }
        };
        messageAPI.messagePagination(
          rootState.selectedChannel._id,
          getters.getJumpMessage.messages[
            getters.getJumpMessage.messages.length - 1
          ]._id,
          "next",
          rootGetters.getSelectedChannel.invited,
          rootGetters.getSelectedChannel.secret_key,
          done
        );
      });
    },
    //update state when user start and stop screen recording (if user switch channel while recording then go back to channel where he/she is started)
    updatePrevSelectedItems({ commit }, payload) {
      commit("setPrevSelectedItems", payload);
    },
    pinMessageForAllUser(context, data) {
      return new Promise((resolve, reject) => {
        const done = (response) => {
          if (response.status === 200) {
            // Commit the mutation to update the pinned message state
            context.commit("SET_PINNED_FOR_ALL_USER", {
              message_id: data.message_id,
              channel_id: data.channel_id,
              pinForAll: data.pinForAll,
              rootGetters: context.rootGetters,
              channel: context.rootGetters.getSelectedChannel,
            });

            resolve(response);
          } else {
            reject(response);
          }
        };

        // Call the API to pin the message for all users
        messageAPI.pinMessageForAllUser(
          data.message_id,
          data.channel_id,
          data.pinForAll,
          context.rootGetters.getSelectedChannel.invited,
          context.rootGetters.getSelectedChannel.secret_key,
          done
        );
      });
    },
    // Unpin Message
    unPinMessageForAllUser(context, data) {
      return new Promise((resolve, reject) => {
        const done = (response) => {
          if (response.status === 200) {
            // Commit the mutation with the updated message data
            context.commit("SET_UNPINNED_FOR_ALL_USER", {
              message_id: data.message_id,
              channel_id: data.channel_id,
              pinForAll: data.pinForAll,
              rootGetters: context.rootGetters,
            });

            resolve(response);
          } else {
            eventBus.$emit("showSnackbar", {
              snackbar: true,
              snackbarColor: "error",
              messsage: "You are not authorized to perform this action.",
            });
            reject(response);
          }
        };

        // Call the API with the data
        messageAPI.pinMessageForAllUser(
          data.message_id,
          data.channel_id,
          data.pinForAll,
          context.rootGetters.getSelectedChannel.invited,
          context.rootGetters.getSelectedChannel.secret_key,
          done
        );
      });
    },

    // Get Pinned Messages
    async getPinnedMessages(context, data) {
      return new Promise((resolve, reject) => {
        var done = (response) => {
          if (response.status == 200) {
            resolve(response);
            context.commit("setPinProgress", false);
            context.commit("SET_PINNED_MESSAGES", response.data);
          } else {
            reject(response);
          }
        };

        messageAPI.pinnedMessages(
          data.channel_id,
          data.pinned_messages,
          context.rootGetters.getSelectedChannel.invited,
          context.rootGetters.getSelectedChannel.secret_key,
          done
        );
      });
    },

    pinMessage(context, data) {
      return new Promise((resolve, reject) => {
        var done = (response) => {
          if (response.status == 200) {
            resolve(response);
            context.commit("SET_PINNED", {
              message_id: data.message_id,
              channel_id: data.channel_id,
              pin: data.pin,
              rootGetters: context.rootGetters,
              channel: context.rootGetters.getSelectedChannel, //for pinned_messages key to set true
            });
          } else {
            reject(response);
          }
        };
        messageAPI.pinMessage(
          data.message_id,
          data.channel_id,
          data.pin,
          context.rootGetters.getSelectedChannel.invited,
          context.rootGetters.getSelectedChannel.secret_key,
          done
        );
      });
    },

    // Unpin Message
    unpinMessage(context, data) {
      return new Promise((resolve, reject) => {
        var done = (response) => {
          if (response.status == 200) {
            resolve(response);
            context.commit("SET_UNPINNED", {
              message_id: data.message_id,
              channel_id: data.channel_id,
              pin: data.pin,
              rootGetters: context.rootGetters,
            });
          } else {
            reject(response);
          }
        };
        messageAPI.pinMessage(
          data.message_id,
          data.channel_id,
          data.pin,
          context.rootGetters.getSelectedChannel.invited,
          context.rootGetters.getSelectedChannel.secret_key,
          done
        );
      });
    },
    // Get Pinned messages from pagination
    getPinnedPagination(context, data) {
      // new Promise = ((resolve, reject) => {
      let customAxios = axios.create({
        headers: {
          token: JSON.parse(localStorage.getItem("token")),
        },
      });

      customAxios
        .post(
          `${process.env.VUE_APP_BASE_URL}/message/read?page=${data.page_number}`,
          data
        )
        .then((res) => {
          context.commit("SET_PINNED_MESSAGES", res.data);
        });
    },
    setDeleteFileStatus(context, data) {
      context.commit("setAttachmentsDelete", {
        currentMessages: data.isReply
          ? context.getters.getReplyedMessages.child
          : context.getters.getCurrentMessages,
        messageIndex: data.isReply
          ? context.getters.getReplyMessageIndex(data.message_id)
          : context.getters.getMessageIndex(data.message_id),
        delete_attachments: data.status,
        delete_attachments_after: data.time,
      });
      var done = (response) => {
        debug.log(response);
      };
      messageAPI.setDeleteFileStatus(
        data.message_id,
        context.rootState.selectedChannel._id,
        data.status,
        context.rootGetters.getSelectedChannel.invited,
        context.rootGetters.getSelectedChannel.secret_key,
        done
      );
    },
    async replyMessage(
      { rootGetters, rootState, commit, getters, dispatch },
      messageBody
    ) {
      return new Promise((resolve, reject) => {
        //store reference of previous clicked channel if user immediatly changed channel after send message then update his/her message status after API response
        let previousMessages = getters.getReplyedMessages.child;
        //if files are attached then make form data object of message
        // let fileMessage = await dispatch("makeFormDataOfFiles", messageBody);
        let channelId = rootState.selectedChannel._id;
        let channel = rootGetters.getSelectedChannel;
        let parentIndex = getters.getMessageIndex(messageBody.replying_id);
        let parentIndexSearchMessage = null;
        if (getters.getJumpMessage) {
          parentIndexSearchMessage = getters.getSearchMessageIndex(
            messageBody.replying_id
          );
        }

        // get the message body channel for update the message
        if (messageBody.channel_id) {
          channelId = messageBody.channel_id;
          channel = rootGetters.getChannelByCompanyIdChannelId(
            messageBody.company_id,
            messageBody.channel_id
          );
        }

        // handle when user send intant message or not
        if (!messageBody.isReplyMsgAlreadyAdded) {
          //add new message in state
          commit("addNewReplyMessage", {
            currentMessages: previousMessages,
            message: messageBody,
          });
        }
        // success callback of adding message
        var done = async (response) => {
          //get message index from store Data
          let index = await getters.getSendMessageIndex(
            messageBody._id,
            response.data != undefined ? response.data._id : null,
            previousMessages
          );
          if (response.status == 200) {
            resolve(response);
            if (index > -1) {
              commit("updateMsgAfterResponse", {
                currentMessages: previousMessages,
                index,
                id: response.data._id,
                data: response.data,
                channel,
              });
              commit("updateReplyInParent", {
                currentMessages: previousMessages,
                parentMessages: getters.getCurrentMessages,
                searchedMessages: getters.getJumpMessage,
                parentIndexSearchMessage,
                parentIndex,
                id: response.data._id,
                data: response.data,
              });
              //if messages length is greater then 20, remove last message
            }
          } else {
            reject(response);
            //on message conflict not remove message only update message info
            if (response.response.status == 409) {
              dispatch("conflictMessageHandling", {
                currentMessages: previousMessages,
                index,
                messageBody,
                channelId,
              });
            } else if (response.response.status != 401) {
              //handle error in sending message (500: internal server error, 400: not send required fields)
              commit("removeBadRequest", {
                currentMessages: previousMessages,
                index,
              });
            }
          }
        };
        // get the message body channel for send create message call
        let messageTeamId = rootGetters.getSelectedTeam._id;
        if (messageBody.team_id) {
          messageTeamId = messageBody.team_id;
        }
        // calling message client API
        messageAPI.sendMessage(
          channelId,
          messageTeamId,
          messageBody.audio,
          messageBody.video,
          messageBody.message,
          messageBody.is_important,
          messageBody.send_after_seconds,
          messageBody.files,
          messageBody.mention_users,
          messageBody._id,
          messageBody.replying_id,
          messageBody.replying_message,
          messageBody.user_time,
          false,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          messageBody.zoom,
          channel.invited,
          channel.secret_key,
          messageBody.emoji,
          messageBody.gifs_data,
          messageBody,
          messageBody.chatLinkPreview,
          messageBody.replyLinkPreview,

          done
        );
      });
    },

    /*
    Function to retrieve and process replied messages
    */
    replyedMessages({ commit, rootState, rootGetters }, ids) {
      return new Promise((resolve, reject) => {
        commit("setReplyedMessages", { queue: rootState.queue, response: [] });
        commit("setReplyStatus", false);
        var done = (response) => {
          if (response.status == 200) {
            commit("setReplyedMessages", {
              queue: rootState.queue,
              response: response.data,
            });
            commit("setReplyStatus", true);
            commit("removeReplySceen", {
              unsceenReply:
                rootGetters.getSelectedChannel.thread_child_messages,
              replyId: ids.child_id,
            });
            commit("ADD_INSTANT_REPLY_MESSAGE_ON_CALL", {
              getInstantMsgs: rootGetters.getInstantMsgs,
              parentDetail: response.data.parent,
              childMessages: response.data.child,
            });
            resolve(response.data);
          } else {
            reject(response);
          }
        };

        messageAPI.replyedMessages(
          rootState.selectedChannel._id,
          ids.parent_id,
          rootGetters.getSelectedChannel.invited,
          rootGetters.getSelectedChannel.secret_key,
          done
        );
      });
    },
    markSceanAllReplies({ commit, rootState, rootGetters }, ids) {
      commit("markSceanAllReplies", {
        unsceenReply: rootGetters.getSelectedChannel.thread_child_messages,
      });
      var done = (response) => {
        if (response.status == 200) {
          debug.log("success");
        } else {
          debug.log("failed");
        }
      };
      messageAPI.markSceanAllReplies(
        rootState.selectedChannel._id,
        ids,
        rootGetters.getSelectedChannel.invited,
        rootGetters.getSelectedChannel.secret_key,
        done
      );
    },
    addReplied_id({ rootState, rootGetters }, id) {
      var done = (response) => {
        if (response.status == 200) {
          debug.log("this is resp", response);
        } else {
          debug.log("failed");
        }
      };
      messageAPI.sceenReply(
        rootState.selectedChannel._id,
        id,
        rootGetters.getSelectedChannel.invited,
        rootGetters.getSelectedChannel.secret_key,
        done
      );
    },
    setParentReplyId({ commit }, id) {
      commit("setParentReplyId", id);
    },
    setFromUnsceen({ commit }, id) {
      commit("setFromUnsceen", id);
    },
    /**
     * this function is responsible for get messages from API
     * args: (getters, rootGetters, channel id)
     */ 
    getMessagesFromAPI(context, dont_show_loader) {
      // if recent chat drawer is open do not make API call
      if (context.rootGetters.getIsUnreadMessage) {
        return;
      }
      let channel = context.rootGetters.getSelectedChannel;
      if (
        channel &&
        context.rootGetters.getChannelMessages(channel._id) == undefined
      ) {
        if (!dont_show_loader) {
          context.commit("setFetchingChannelMessagesFlag", true);
        }

        return new Promise((resolve, reject) => {
          // success callback of adding message
          var done = async (response) => {
            if (response.status == 200) {
              await context.commit("setMessages", {
                messages: response.data,

                channel_id: channel._id,
              });
              context.commit("removeNewMessages", {
                channel_id: channel._id,
              });
              resolve(response);
              context.commit("setFetchingChannelMessagesFlag", false);
            } else {
              reject(response);
              context.commit("setFetchingChannelMessagesFlag", false);
              eventBus.$emit("errorInFetchingMessages");
            }
          };
          let get_messages = true;
          // calling message client API
          messageAPI.getMessagesFromAPI(
            context.rootState.selectedChannel._id,
            get_messages,
            context.rootGetters.getSelectedChannel.invited,
            context.rootGetters.getSelectedChannel.secret_key,
            done
          );
        });
      }
    },
    // this action is to call api to get last message for quick reply
    async notificationMessageData(context, data) {
      return new Promise((resolve) => {
        var done = (response) => {
          resolve(response);
        };
        messageAPI.getNotificationChannelData(data, done);
      });
    },
    // this action is to reply from quick reply component
    async sendQuickReply(context, data) {
      return new Promise((resolve) => {
        var done = (response) => {
          resolve(response);
        };
        messageAPI.replyMessageFromQuickReply(data, done);
      });
    },
    /**
     * this function is responsible for get Unread messages againts all channels from API
     * @param {} empty just passing token
     * @return {Array of objects}
     */
    async unreadMessages({ commit }, data) {
      return new Promise((resolve, reject) => {
        const done = (response) => {
          // Check for error in the response and reject if needed
          if (response.error) {
            reject(response.error);
          } else {
            resolve({ ...response, requestId: data.requestId });
          }
        };
        messageAPI.unreadMessageRequest(data, done);
      });
    },
    /**
     * this function is responsible for get recent files from API
     * args: (getters, rootGetters, company id)
     */
    getRecentAttachments(context) {
      return new Promise((resolve, reject) => {
        var done = async (response) => {
          if (response.status == 200) {
            resolve(response);
          } else {
            reject(response);
          }
        };
        messageAPI.getRecentAttachmentsFromApi(
          context.rootGetters.getSelectedCompany._id,
          done
        );
      });
    },
  },
  mutations: {
    SET_CHAT_LINK_PREVIEW(state, data) {
      const existingPreviewIndex = state.chatLinkPreview.findIndex(
        (preview) => preview.url === data.url
      );

      if (existingPreviewIndex === -1) {
        state.chatLinkPreview.push({
          url: data.url,
          site: data["og:site_name"] || "",
          title: data["og:title"] || "",
          description: data["og:description"] || "",
          image: data["og:image"] || "",
          videoUrl: data["og:video:secure_url"] || "",
        });
      } else {
        state.chatLinkPreview[existingPreviewIndex] = {
          url: data.url,
          site: data["og:site_name"] || "",
          title: data["og:title"] || "",
          description: data["og:description"] || "",
          image: data["og:image"] || "",
          link: data["og:url"] || "",
          videoUrl: data["og:video:secure_url"] || "",
        };
      }
    },
    CLEAR_CHAT_LINK_PREVIEW(state) {
      state.chatLinkPreview = [];
    },
    REMOVE_CHAT_LINK_PREVIEW(state, url) {
      state.chatLinkPreview = state.chatLinkPreview.filter(
        (preview) => preview.url !== url
      );
    },

    SET_REPLY_LINK_PREVIEW(state, data) {
      const existingPreviewIndex = state.replyLinkPreview.findIndex(
        (preview) => preview.url === data.url
      );

      if (existingPreviewIndex === -1) {
        state.replyLinkPreview.push({
          url: data.url,
          site: data["og:site_name"] || "",
          title: data["og:title"] || "",
          description: data["og:description"] || "",
          image: data["og:image"] || "",
          videoUrl: data["og:video:secure_url"] || "",
        });
      } else {
        state.replyLinkPreview[existingPreviewIndex] = {
          url: data.url,
          site: data["og:site_name"] || "",
          title: data["og:title"] || "",
          description: data["og:description"] || "",
          image: data["og:image"] || "",
          link: data["og:url"] || "",
          videoUrl: data["og:video:secure_url"] || "",
        };
      }
    },
    CLEAR_REPLY_LINK_PREVIEW(state) {
      state.replyLinkPreview = [];
    },
    REMOVE_REPLY_LINK_PREVIEW(state, url) {
      state.replyLinkPreview = state.replyLinkPreview.filter(
        (preview) => preview.url !== url
      );
    },

    markSceanAllReplies(state, payload) {
      let len = payload.unsceenReply.length;
      payload.unsceenReply.splice(0, len + 1);
    },
    removeReplySceen(state, payload) {
      let index = payload.unsceenReply.findIndex((item) => {
        return item._id == payload.replyId;
      });

      if (index > -1) payload.unsceenReply.splice(index, 1);
    },
    /**
     * this mutation will add previous messages in searched messages array on pagination
     * args:((state, payload))
     */
    addPreSearchMsgsPag: (state, payload) => {
      for (const message of payload) {
        state.jumpMessage.messages.unshift(message);
      }
    },
    /**
     * this mutation will add next messages in searched messages array on pagination
     * args:((state, payload))
     */
    addNextSearchMsgsPag: (state, payload) => {
      for (const message of payload) {
        state.jumpMessage.messages.push(message);
      }
    },
    /**
     * this mutation will set value of search message progress.
     * args:((state, payload))
     */
    setSearchProgress: (state, payload) => {
      state.searchProgress = payload;
    },
    /**
     * this mutation will add new messages to existing messages
     * args:((state, payload))
     */
    addPaginatedMessages: (state, payload) => {
      for (const message of payload.newMessages) {
        payload.previousMessages.push(message);
      }
    },
    /**
     * this mutation will remove last message from storeData if messages length is greater than 20.
     * args:((state, payload))
     */
    removeLastMessage: (state, payload) => {
      payload.pop();
    },
    /**
     * this mutation will add new message in storeData.
     * args:((state, payload))
     */
    addNewMessage: (state, payload) => {
      payload.currentMessages.unshift(payload.message);
    },
    /**
     * this mutation success case of send message.
     * args:((state, payload))
     */
    updateMsgAfterResponse: (state, payload) => {
      payload.channel.last_message_at = payload.data.created_at;
      // globalPin_by index
      let value = payload.currentMessages[payload.index]["globalPin_by"]
      payload.currentMessages[payload.index].globalPin_by = value ? value : ""
      payload.currentMessages[payload.index]._id = payload.data._id;
      payload.currentMessages[payload.index].replied_ids =
        payload.data.replied_ids;
      payload.currentMessages[payload.index].created_at =
        payload.data.created_at;
      if (payload.data.attachments !== undefined) {
        payload.currentMessages[payload.index].attachments =
          payload.data.attachments;
        delete payload.currentMessages[payload.index].files;
      }
      if (payload.data.audio_video_file !== undefined) {
        payload.currentMessages[payload.index].audio_video_file =
          payload.data.audio_video_file;
      }
      if (payload.data.delete_attachments !== undefined) {
        payload.currentMessages[payload.index].delete_attachments =
          payload.data.delete_attachments;
      }
      if (payload.data.delete_attachments_after !== undefined) {
        payload.currentMessages[payload.index].delete_attachments_after =
          payload.data.delete_attachments_after;
      }
      if (payload.currentMessages[payload.index].poll_data) {
        payload.currentMessages[payload.index].poll_data = payload.poll_data;
      }

      if (payload.data.zoom !== undefined) {
        Vue.set(
          payload.currentMessages[payload.index],
          "zoom",
          payload.data.zoom
        );
      }
    },
    /**
     * this mutation is failure case of send message.
     * args:((state, payload))
     */
    removeBadRequest: (state, payload) => {
      // remove failed message
      if (payload.index != -1) {
        payload.currentMessages.splice(payload.index, 1);
      }
    },
    /**
     * this mutation will direct delete message from storeData. this function is for Sender Delete.
     * args:((state, payload))
     */
    directDelete: (state, payload) => {
      Vue.set(payload.currentMessages[payload.messageIndex], "deleted_at", 1);
      Vue.set(
        payload.currentMessages[payload.messageIndex],
        "delete_after",
        null
      );
      Vue.set(
        payload.currentMessages[payload.messageIndex],
        "send_after",
        null
      );
    },
    /**
     * this mutation will add reaction to the message.
     * args:((state, payload))
     */
    messageReaction: (state, payload) => {
      let reaction = payload.currentMessages[
        payload.messageIndex
      ].reactions.find((item) => {
        return item.reaction.aliases[0] == payload.reaction.aliases[0];
      });
      if (reaction) {
        let userIndex = reaction.user_ids.findIndex(
          (id) => payload.user_id == id
        );
        if (userIndex > -1) {
          if (reaction.user_ids.length == 1) {
            let reactionIndex = payload.currentMessages[
              payload.messageIndex
            ].reactions.findIndex((item) => {
              return item.reaction.aliases[0] == payload.reaction.aliases[0];
            });
            payload.currentMessages[payload.messageIndex].reactions.splice(
              reactionIndex,
              1
            );
          } else {
            reaction.user_ids.splice(userIndex, 1);
          }
        } else {
          reaction.user_ids.push(payload.user_id);
        }
      } else {
        let user_ids = [];
        user_ids.push(payload.user_id);
        payload.currentMessages[payload.messageIndex].reactions.push({
          reaction: payload.reaction,
          user_ids,
        });
      }
    },
    /**
     * this mutation will set delete after of message in message store.
     * args:((state, payload))
     */
    scheduleDeleteMessage: (state, payload) => {
      payload.currentMessages[payload.messageIndex].delete_after =
        payload.delete_after;
    },
    /**
     * this mutation will edit message from storeData.
     * args:((state, payload))
     */
    editMessage: (state, payload) => {
      payload.currentMessages[payload.messageIndex].message =
        payload.editedText;
      payload.currentMessages[payload.messageIndex].is_edited = true;
      payload.currentMessages[payload.messageIndex].emoji = payload.emoji;
    },
    /**
     * this mutation will reset edit message in storeData if there is any failure in edit message.
     * args:((state, payload))
     */
    editMessageFailed: (state, payload) => {
      payload.currentMessages[payload.messageIndex].message = payload.oldText;
      payload.currentMessages[payload.messageIndex].is_edited =
        payload.editConfirm;
    },
    /**
     * this mutation will set the searched messages result in array.
     * args:((state, payload))
     */
    setSearchedMessages: (state, payload) => {
      state.searchedMessages = payload;
    },
    /**
     * this mutation will set the jumpMessage messages result in array.
     * args:((state, payload))
     */
    setJumpMessage: (state, payload) => {
      state.jumpMessage = payload;
    },

    setPaginationRes: (state, payload) => {
      state.isNoMsgInRes = payload;
    },
    setPreMsgPaginateRes: (state, payload) => {
      state.isNoPreMsg = payload;
    },
    setNxtMsgPaginateRes: (state, payload) => {
      state.isNoNextMsg = payload;
    },
    setPrevSelectedItems: (state, payload) => {
      state.prevSelectedItems = payload;
    },
    SET_PINNED_MESSAGES: (state, payload) => {
      state.pinned_messages = payload;
    },
    SET_PINNED: (state, payload) => {
      if (state.jumpMessage != null) {
        state.jumpMessage.messages
          .find((message) => message._id == payload.message_id)
          .pinned_by.push(payload.rootGetters.loggedInUserDetails._id);
      } else {
        payload.rootGetters.getCurrentMessages
          .find((message) => message._id == payload.message_id)
          .pinned_by.push(payload.rootGetters.loggedInUserDetails._id);
      }
      //for pinned_messages key to set true when any message is pinned in channel
      payload.channel.pinned_messages = true;
    },
    SET_UNPINNED: (state, payload) => {
      if (state.jumpMessage != null) {
        state.jumpMessage.messages
          .find((message) => message._id == payload.message_id)
          .pinned_by.splice(
            state.jumpMessage.messages.find(
              (message) => message._id == payload.message_id
            ),
            1
          );
      } else {
        payload.rootGetters.getCurrentMessages
          .find((message) => message._id == payload.message_id)
          .pinned_by.splice(
            payload.rootGetters.getCurrentMessages.find(
              (message) => message._id == payload.message_id
            ),
            1
          );
      }
    },
    SET_PINNED_FOR_ALL_USER: (state, payload) => {
      const message = (state.jumpMessage?.messages || payload.rootGetters.getCurrentMessages)
        .find(m => m._id === payload.message_id);
      if (message) {
        const currentUserId = payload.rootGetters.loggedInUserDetails._id;
        delete message["globalPin_by"]
        Vue.set(message, "globalPin_by", currentUserId)
        message.pinned_by.push(currentUserId);
        message.pinned_by = [...new Set(message.pinned_by)];
        payload.channel.pinned_messages = true;
      } else {
        console.error("Message not found for pinning.");
      }
    },

    SET_UNPINNED_FOR_ALL_USER: (state, payload) => {
      const message = (state.jumpMessage?.messages || payload.rootGetters.getCurrentMessages)
        .find(m => m._id === payload.message_id);
      if (message) {
        delete message["globalPin_by"]
        Vue.set(message, "pinned_by", [])
        Vue.set(message, "globalPin_by", "")
      } else {
        console.error("Message not found for unpinning.");
      }
    },

    setAttachmentsDelete(state, payload) {
      payload.currentMessages[payload.messageIndex].delete_attachments =
        payload.delete_attachments;
      payload.currentMessages[payload.messageIndex].delete_attachments_after =
        payload.delete_attachments_after;
    },
    setReplyedMessages: (state, payload) => {
      if (payload?.response?.parent) {
        let queueMessage = [];

        payload.queue.forEach((queueItem) => {
          if (
            queueItem.endpoint == "message/create" &&
            queueItem.body.requestBody.channel_id ==
            payload.response.parent.channel_id &&
            queueItem.body.messageBody?.replying_id != null &&
            queueItem.body.messageBody?.replying_id != undefined &&
            queueItem.body.messageBody?.replying_id ==
            payload.response.parent._id
          ) {
            queueMessage.unshift(queueItem.body.messageBody);
          } else if (
            queueItem.endpoint == "message/sendGuestMessage" &&
            queueItem.body.requestBody.channel_id ==
            payload.response.parent.guest_data.guest_channel_id &&
            queueItem.body.messageBody?.replying_id != null &&
            queueItem.body.messageBody?.replying_id != undefined &&
            queueItem.body.messageBody?.replying_id ==
            payload.response.parent._id
          ) {
            queueMessage.unshift(queueItem.body.messageBody);
          }
        });

        if (queueMessage.length) {
          state.replyedMessages = payload.response;
          state.replyedMessages.child = [
            ...state.replyedMessages.child,
            ...queueMessage,
          ];
        } else {
          state.replyedMessages = payload.response;
        }
      } else {
        state.replyedMessages = payload.response;
      }
    },
    addNewReplyMessage: (state, payload) => {
      if (payload.currentMessages) {
        payload.currentMessages.push(payload.message);
      }
    },
    updateReplyInParent: (state, payload) => {
      try {
        let index = payload.parentMessages[
          payload.parentIndex
        ].replied_ids.findIndex((item) => {
          return item == payload.data.temp_id || item == payload.data._id;
        });
        if (index > -1)
          payload.parentMessages[payload.parentIndex].replied_ids[index] =
            payload.id;
        else {
          payload.parentMessages[payload.parentIndex].replied_ids.push(
            payload.id
          );
        }
      } catch {
        if (payload.searchedMessages) {
          let index = payload.searchedMessages.messages[
            payload.parentIndexSearchMessage
          ].replied_ids.findIndex((item) => {
            return item == payload.data.temp_id || item == payload.data._id;
          });
          if (index > -1)
            payload.searchedMessages.messages[
              payload.parentIndexSearchMessage
            ].replied_ids[index] = payload.id;
          else {
            payload.searchedMessages.messages[
              payload.parentIndexSearchMessage
            ].replied_ids.push(payload.id);
          }
        }
      }
    },
    setReplyStatus: (state, payload) => {
      state.replyStatus = payload;
    },
    setParentReplyId: (state, payload) => {
      state.parentReplyId = payload;
    },
    setFromUnsceen: (state, payload) => {
      state.fromUnsceen = payload;
    },
    setPinProgress: (state, payload) => {
      state.pinProgress = payload;
    },
    setFetchingChannelMessagesFlag(state, payload) {
      state.channelMessagesFetched = payload;
    },
    removeMessageFromView(state, payload) {
      let index = payload.messages[payload.channelId].findIndex((item) => {
        return item._id == payload.message._id;
      });

      if (index > -1) {
        payload.messages[payload.channelId].splice(index, 1);
      }
    },
    removeReplyMessageFromView(state, payload) {
      let index = state.replyedMessages?.child.findIndex((item) => {
        return item._id == payload.message._id;
      });

      if (index > -1) {
        state.replyedMessages?.child.splice(index, 1);
      }
    },
    removeMessageFromQueue(state, payload) {
      let index = payload.queue.findIndex((item) => {
        if (
          item.endpoint == "message/create" ||
          item.endpoint == "message/sendGuestMessage"
        ) {
          return item.body.messageBody._id == payload.message._id;
        }
      });

      if (index > -1) {
        payload.queue.splice(index, 1);
      }
    },
    // append instant state message for display in reply message drawer on each call of get replies
    ADD_INSTANT_REPLY_MESSAGE_ON_CALL(state, payload) {
      if (payload.getInstantMsgs.length) {
        // this will first filter out the reply messages of parent
        let findedReplyInstantMsgs = payload.getInstantMsgs.filter(
          (instantMsg) => instantMsg.replying_id == payload.parentDetail._id
        );

        if (findedReplyInstantMsgs.length) {
          // remove from the instant msgs array of reply messages which is send
          payload.childMessages.forEach((childMsg) => {
            let instantReplyIndex = findedReplyInstantMsgs.findIndex(
              (findInstantMsgs) => findInstantMsgs._id == childMsg.temp_id
            );
            if (instantReplyIndex >= 0) {
              findedReplyInstantMsgs.splice(instantReplyIndex, 1);
            }
          });
          state.replyedMessages.child = state.replyedMessages.child.concat(
            findedReplyInstantMsgs
          );
        }
      }
    },
    // update the channel reply messages
    UPDATE_CHANNEL_REPLY_MSG_FILES(state, payload) {
      if (state.replyedMessages?.child?.length) {
        let channelReplyMessages = [...state.replyedMessages.child];
        let replyMsgIndex = channelReplyMessages.findIndex(
          (replyMsg) => replyMsg._id == payload.newMessage._id
        );
        if (replyMsgIndex >= 0) {
          channelReplyMessages[replyMsgIndex] = payload.newMessage;
          state.replyedMessages.child = channelReplyMessages;
        }
      }
    },
    // remove the reply message from the reply message message state
    REMOVE_REPLY_MSG_OF_CHANNEL(state, payload) {
      if (state.replyedMessages?.child?.length) {
        let channelReplyMessages = [...state.replyedMessages.child];
        let replyMsgIndex = channelReplyMessages.findIndex(
          (replyMsg) => replyMsg._id == payload.removeMessage._id
        );
        if (replyMsgIndex >= 0) {
          channelReplyMessages.splice(replyMsgIndex, 1);
          state.replyedMessages.child = channelReplyMessages;
        }
      }
    },
  },
  state: {
    chatLinkPreview: [],
    replyLinkPreview: [],

    searchedMessages: undefined,
    jumpMessage: null,
    isNoMsgInRes: false,
    searchProgress: false,
    prevSelectedItems: null,
    isNoNextMsg: false,
    isNoPreMsg: false,
    pinned_messages: "",
    replyedMessages: [],
    replyStatus: false,
    parentReplyId: null,
    fromUnsceen: false,
    pinProgress: false,
    channelMessagesFetched: false,
  },
};

export default messageStore;
