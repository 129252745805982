/*
System: Whistle It
Developer: Ahmad Hannan
Organization: Whistle It
Purpose: This file is used for making api calls for all user api's
*/
import requestQueue from "@/service/requestQueue";
import debug from "@/console";
var requestBody;
var requestEndPoint;
var token;
const userAPI = {
  fetchUsers: (requestBody, done) => {
    requestEndPoint = "adminPanel/getUsers";
    token = JSON.parse(localStorage.getItem("token"));
    requestQueue.post(requestEndPoint, token, requestBody, done);
  },
  updateCustomStatus: (requestObj, done) => {
    requestBody = requestObj;
    requestEndPoint = "user/update";
    token = JSON.parse(localStorage.getItem("token"));
    requestQueue.post(requestEndPoint, token, requestBody, done);
  },
  /*
This function is responsible for making api call for updating user status
args:(company_id, user_id, name), callback function for done
*/
  updateUserStatus: (requestObj, done) => {
    requestBody = requestObj;
    requestEndPoint = "user/update";
    token = JSON.parse(localStorage.getItem("token"));
    requestQueue.post(requestEndPoint, token, requestBody, done);
  },
  /*
    This function is responsible for making api call for updating user hide email status
    args:(hide_email), callback function for done
    */
  updateUserHideEmailStatus: (hide_email, done) => {
    requestBody = { hide_email };
    requestEndPoint = "user/update";
    token = JSON.parse(localStorage.getItem("token"));
    requestQueue.post(requestEndPoint, token, requestBody, done);
  },
  /*
This function is responsible for making api call for updating user name
args:(company_id, user_id, name), callback function for done
*/
  updateUserName: (payload, done) => {
    //debug due to eslint error

    requestBody = payload;
    requestEndPoint = "user/update";
    token = JSON.parse(localStorage.getItem("token"));
    requestQueue.post(requestEndPoint, token, requestBody, done);
  },

  // Forgot Password
  forgotPassword: (email, done) => {
    requestBody = {
      email: email,
    };
    requestEndPoint = "forgotPassword";
    token = "null";
    requestQueue.post(requestEndPoint, token, requestBody, done);
  },

  // SAML Login
  samlLogin: (email, done) => {
    requestBody = {
      email: email,
    };
    requestEndPoint = "sso/login";
    token = "null";
    requestQueue.post(requestEndPoint, token, requestBody, done);
  },

  // Reset Password
  resetPassword: (new_password, confirm_new_password, token, done) => {
    requestBody = {
      new_password,
      confirm_new_password,
    };
    requestEndPoint = "resetPassword";
    requestQueue.post(requestEndPoint, token, requestBody, done);
  },

  /*
This function is responsible for making api call for update admin profile
args:(company_id, full_name,company_name), callback function for done
*/
  updateAdminProfile: (payload, done) => {
    requestBody = payload;
    debug.log(requestBody);
    requestEndPoint = "user/update";
    token = JSON.parse(localStorage.getItem("token"));
    requestQueue.post(requestEndPoint, token, requestBody, done);
  },
  updateNotificationSetting(
    in_app_notification,
    push_notification,
    notification_sound,
    email_notification,
    mobile_notification_delay,
    company_id,
    done
  ) {
    requestBody = {
      in_app_notification,
      push_notification,
      notification_sound,
      email_notification,
      mobile_notification_delay,
      company_id,
    };
    requestEndPoint = "user/update";
    token = JSON.parse(localStorage.getItem("token"));
    requestQueue.post(requestEndPoint, token, requestBody, done);
  },

  // Remove Admin Profile Picture

  removeAdminProfilePic: (profile_picture, two_fa, company_id, done) => {
    requestBody = {
      profile_picture,
      two_fa,
      company_id,
    };

    requestEndPoint = "user/update";
    token = JSON.parse(localStorage.getItem("token"));
    requestQueue.post(requestEndPoint, token, requestBody, done);
  },

  // Remove Uaser Profile Picture

  removeUserProfilePic: (profile_picture, two_fa, company_id, done) => {
    requestBody = {
      profile_picture,
      two_fa,
      company_id,
    };

    requestEndPoint = "user/update";
    token = JSON.parse(localStorage.getItem("token"));
    requestQueue.post(requestEndPoint, token, requestBody, done);
  },
  /*
This function is responsible for making api call for update admin profile picture
args:(company_id, profile_picture), callback function for done
*/
  updateAdminPicture: (data, done) => {
    requestEndPoint = "user/update";
    token = JSON.parse(localStorage.getItem("token"));
    requestQueue.post(
      requestEndPoint,
      token,
      data.formData,
      done,
      data.progress
    );
  },

  /*
This function is responsible for making api call for update admin password
args:(company_id, old_password,new_password), callback function for done
*/
  updateAdminPassword: (
    current_password,
    new_password,
    confirm_new_password,
    fcm_token,
    done
  ) => {
    requestBody = {
      current_password,
      new_password,
      confirm_new_password,
      fcm_token,
    };
    requestEndPoint = "user/updatePassword";
    token = JSON.parse(localStorage.getItem("token"));
    requestQueue.post(requestEndPoint, token, requestBody, done);
  },

  setUsersPermission: (permissions, user_id, company_id, done) => {
    requestBody = {
      permissions,
      user_id,
      company_id,
    };
    requestEndPoint = "permission/update";
    token = JSON.parse(localStorage.getItem("token"));
    requestQueue.post(requestEndPoint, token, requestBody, done);
  },

  getPermissions: (user_id, company_id, done) => {
    requestBody = {
      user_id,
      company_id,
    };
    requestEndPoint = "permission/read";
    token = JSON.parse(localStorage.getItem("token"));
    requestQueue.post(requestEndPoint, token, requestBody, done);
  },

  setAllowAccess: (user_id, allow_access, company_id, done) => {
    requestBody = {
      user_id,
      allow_access,
      company_id,
    };
    requestEndPoint = "user/updateAllowAccess";
    token = JSON.parse(localStorage.getItem("token"));
    requestQueue.post(requestEndPoint, token, requestBody, done);
  },

  resetUsers2FA: (data, done) => {
    requestBody = data;
    requestEndPoint = "user/resetUser2fa";
    token = JSON.parse(localStorage.getItem("token"));
    requestQueue.post(requestEndPoint, token, requestBody, done);
  },
  resendAccountSetupEmail: (user_id, company_id, done) => {
    requestBody = {
      user_id,
      company_id,
    };
    requestEndPoint = "user/resendAccountSetupEmail";
    token = JSON.parse(localStorage.getItem("token"));
    requestQueue.post(requestEndPoint, token, requestBody, done);
  },

  /*
    This function is responsible for making api call for get user's teams 
    args:(company_id, user_id), callback function for done
    */
  getUserTeamsDetails: (company_id, user_id, done) => {
    requestBody = {
      company_id,
      user_id,
    };
    requestEndPoint = "getTeamDetails";
    token = JSON.parse(localStorage.getItem("token"));
    requestQueue.post(requestEndPoint, token, requestBody, done);
  },
  /*
     This function is responsible for making api call for change user info
     args:(company_id, user_id, department_name, department_id), callback function for done
     */
  changeUserInfo: (requestBody, done) => {
    requestEndPoint = "user/adminUpdateUser";
    token = JSON.parse(localStorage.getItem("token"));
    requestQueue.post(requestEndPoint, token, requestBody, done);
  },
  /*
    This function is responsible for making api call to update mark as unread channel
    args:(channel_id, unread_channel), callback function for done
    */
  updateMarkAsUnread: (data, done) => {
    requestEndPoint = "user/update";
    token = JSON.parse(localStorage.getItem("token"));
    requestQueue.post(requestEndPoint, token, data, done);
  },
  /*
    This function is responsible for making api call to update user info
    args:(data, done), callback function for done
    */
  updateUserInfo: (data, done) => {
    requestEndPoint = "user/update";
    token = JSON.parse(localStorage.getItem("token"));
    requestQueue.post(requestEndPoint, token, data, done);
  },
  /*   This function is responsible for making api call to update user Audio speed
    args:(data, done), callback function for done  */
  updateUserAudioSpeed: (data, done) => {
    requestEndPoint = "user/update";
    token = JSON.parse(localStorage.getItem("token"));
    requestQueue.post(requestEndPoint, token, data, done);
  },
  /*  This function is responsible for making api call to update user Timezone
    args:(data, done), callback function for done  */
  currentUserTimezone: (data, done) => {
    requestEndPoint = "timezone";
    token = JSON.parse(localStorage.getItem("token"));
    requestQueue.post(requestEndPoint, token, data, done);
  },
};
export default userAPI;
