import requestQueue from "@/service/requestQueue";
const samlAPI = {
  /*
    send endpoint, token, requestBody and callback for done to read saml domains
    args:(requestBody, done)
    */
  readSamlDomains(requestBody, done) {
    requestQueue.post(
      "saml2/readTenant",
      JSON.parse(localStorage.getItem("token")),
      requestBody,
      done
    );
  },
  /*
    send endpoint, token, requestBody and callback for done to generate saml credentials
    args:(requestBody, done)
    */
  generateSamlKeys(requestBody, done) {
    requestQueue.post(
      "saml2/createTenant",
      JSON.parse(localStorage.getItem("token")),
      requestBody,
      done
    );
  },
  /*
    send endpoint, token, requestBody and callback for done to integrate saml credentials
    args:(requestBody, done)
    */
  integrateSaml(requestBody, done) {
    requestQueue.post(
      "saml2/updateTenant",
      JSON.parse(localStorage.getItem("token")),
      requestBody,
      done
    );
  },
  /*
    send endpoint, token, requestBody and callback for done to delete saml credentials
    args:(requestBody, done)
    */
  deleteSaml(requestBody, done) {
    requestQueue.post(
      "saml2/deleteTenant",
      JSON.parse(localStorage.getItem("token")),
      requestBody,
      done
    );
  },
};

export default samlAPI;
