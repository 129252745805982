/**
 * System: Whistle It
 * Developer: Nabil Ahmad and Ahmad Hanan
 * Organization: Whistle It
 * Purpose: This file is for listening and changing updated data in admin store for teams.
 */
import debug from "@/console";
//verify if logged-in user has udate permissions in team
let isTeamSubAdmin = (permissionsObj) => {
  if (permissionsObj.team) {
    return permissionsObj.team.includes("update");
  } else {
    return false;
  }
};

var teamSocket = {
  state: {},
  getters: {},
  actions: {
    /**
     * This action is listened by all users who added in team and  name of that particular team change
     *
     * @param {Object} data recieved from socket
     * @return {void}
     */
    socket_teamNameUpdate(context, data) {
      //find company
      let company = context.rootGetters.getCompanyById(data.company_id);
      //if company found
      if (company) {
        //call mutation to update team nake
        context.commit("updateTeamName", {
          team: company.teams.find((team) => team._id == data.team_id),
          data: data,
        });
      }
    },

    /**
     * This action is listened by all users who added in team and updated there team Profile picture
     *
     * @param {Object} data contains team_id, profile_picture
     * @return {void}
     */
    socket_teamProfileUpdate(context, data) {
      //find company
      let company = context.rootGetters.getCompanyById(data.company_id);
      //if company exist
      if (company) {
        //call mutation to update team profile picture
        context.commit("updateTeamProfile", {
          team: company.teams.find((team) => team._id == data.team_id),
          data,
        });
      }
    },

    /**
     * This action is listened by admin of the company. New team creation observed by
     * every logged in instance of admin
     *
     * @param {Object} data recieved from socket
     * @return {void}
     */
    socket_newTeamCreated(context, data) {
      let companies = context.rootState.storeData.companies;
      //find company index
      let companyIndex = companies.findIndex(
        (company) => company._id == data.company_id
      );
      //fidn team index
      let teamIndex = companies[companyIndex].teams.findIndex(
        (team) => team.name.toLowerCase() == data.team.name.toLowerCase()
      );

      //add team data to local store
      let body = {
        teamIndex: teamIndex,
        data: data,
        teams: companies[companyIndex].teams,
        company: companies[companyIndex],
      };
      //call mutation to add new created team
      context.commit("createNewTeam", body);
    },

    /**
     * This action is listened by all users who are added in team
     *
     * @param {Object} data recieved from socket
     * @return {void}
     */
    socket_addedInTeam(context, data) {
      //find company
      let company = context.rootGetters.getCompanyById(data.company_id);
      //call mutation to add user in team
      context.commit("addedinTeam", {
        teams: company.teams,
        newData: data,
        companyChannels: company.channels,
      });
      //when user added in one team
      let joinedTeams = company.teams.filter(
        (team) => team.joined && team.archived == null
      );
      //if only 1 team then select that team
      if (
        company._id == context.rootGetters.getSelectedCompany._id &&
        joinedTeams.length == 1
      ) {
        context.dispatch("selectTeam", joinedTeams[0]._id);
      }
    },

    /**
     * this function is listened by all previous added members of team and admin
     * New member added in there local store
     * args:(data)
     */
    socket_newUserAddedInTeam(context, data) {
      //push hash in array
      let company = context.rootState.storeData.companies.find(
        (company) => company._id == data.company_id
      );
      context.commit("newUserAddedInTeam", {
        companyChannels: company.channels,
        team: company.teams.find((team) => team._id == data.team_id),
        user_ids: data.user_ids,
      });
    },
    /**
     * this function inform all teams about removal of user from specific team
     *  member removed from there local store
     * args:(data)
     */
    async socket_userLeftTeam(context, data) {
      // login user id
      let loginUserId = await context.rootGetters.loggedInUserDetails._id;
      let companyId = await context.rootGetters.getSelectedCompany._id;
      //select other team if user is on selected channel
      if (
        data.team_id == context.rootState.selectedTeamId &&
        data.user_ids.includes(loginUserId)
      ) {
        await context.dispatch("selectCompany", companyId);
      } else {
        let company = context.rootState.storeData.companies.find(
          (company) => company._id == data.company_id
        );
        context.commit("userLeftTeam", {
          team: company.teams.find((team) => team._id == data.team_id),
          user_ids: data.user_ids,
          company,
        });
      }
    },
    /**
     * this function inform that user who removed from team
     *  that removed user splice team data from its store
     * args:(data)
     */
    async socket_removedFromTeam(context, data) {
      let isTeamSelected = false;
      //select other team if user is on selected channel
      if (data.team_id == context.rootState.selectedTeamId) {
        isTeamSelected = true;
        if (context.rootGetters.getJoinedTeams.length > 1) {
          await context.dispatch(
            "selectTeam",
            context.rootGetters.getSelectedCompany.teams.find(
              (team) =>
                team.joined == true &&
                team.archived == null &&
                team._id != data.team_id
            )._id
          );
        }
      }
      context.commit("removedfromTeam", {
        company: context.rootState.storeData.companies.find(
          (company) => company._id == data.company_id
        ),
        team_id: data.team_id, //remove particular team from user side
        user_id: data._id,
      });
      if (isTeamSelected && context.rootGetters.getJoinedTeams.length == 0) {
        context.dispatch("changeHomeRoute");
      }
    },
    /**
     * this function (team archived) recieved from change-stream and update store of company
     * args:(data)
     */
    async socket_teamArchived(context, data) {
      let company = context.rootGetters.getCompanyById(data.company_id);
      let isTeamSelected = false;
      //if selected team is archived then change current team
      if (data.team_id == context.rootState.selectedTeamId) {
        isTeamSelected = true;
        if (context.rootGetters.getJoinedTeams.length > 1) {
          await context.dispatch(
            "selectTeam",
            context.rootGetters.getSelectedCompany.teams.find(
              (team) =>
                team.joined == true &&
                team.archived == null &&
                team._id != data.team_id
            )._id
          );
        }
      }
      //this emit recieved to both admin and user
      context.commit("USER_ARCHIVE_TEAM", {
        company,
        team_id: data.team_id,
      });
      if (isTeamSelected && context.rootGetters.getJoinedTeams.length == 0) {
        context.dispatch("changeHomeRoute");
      }
    },
    /**
     * this function (team Unarchived) recieved from change-stream and update store of company
     * args:(data)
     */
    socket_teamUnArchived(context, data) {
      let company = context.rootGetters.getCompanyById(data.company_id);
      if (
        data.team.users.includes(context.rootGetters.loggedInUserDetails._id)
      ) {
        context.commit("USER_UNARCHIVE_TEAM", {
          company,
          newData: data,
        });
      }
      if (
        context.rootGetters.getSelectedCompany._id == data.company_id &&
        context.rootGetters.getJoinedTeams.length == 1
      ) {
        context.dispatch(
          "selectTeam",
          context.rootGetters.getSelectedCompany.teams.find(
            (team) => team.joined == true && team.archived == null
          )._id
        );
      }
    },
    /**
     * this function inform all teams about removal of user from specific team
     *  member removed from there local store
     * args:(data)
     */
    async socket_removeGroupChannels(context, data) {
      // login user id
      let loginUserId = await context.rootGetters.loggedInUserDetails._id;
      let company = context.rootState.storeData.companies.find(
        (company) => company._id == data.company_id
      );
      //find removed channel is selected then select another channel
      data.details.forEach((detail) => {
        if (detail.user_id == loginUserId) {
          detail.channel_ids.forEach((removedChannelId) => {
            let channeldata = company.direct.find(
              (channel) => channel._id == removedChannelId
            );
            if (
              channeldata &&
              context.rootState.selectedChannel &&
              channeldata._id == context.rootState.selectedChannel._id &&
              context.rootGetters.getSelectedTeam
            ) {
              //find default channel of that user
              let selectedChannel = context.rootGetters.getSelectedCompany.channels.find(
                (channel) =>
                  channel.default == true &&
                  channel.team_id == context.rootGetters.getSelectedTeam._id
              );
              //select default channel
              context.dispatch("selectChannel", {
                _id: selectedChannel._id,
                type: selectedChannel.type,
              });
            }
          });
        }
      });
      //remove channel or users
      if (company) {
        context.commit("groupChannelUpdation", {
          loginUserId,
          channelsDetails: data.details,
          directChannels: company.direct,
        });
      }
    },
    teamLinkInvite(context, data) {
      this._vm.$socket.client.emit("teamLink", data);
    },
  },

  mutations: {
    /**
     * this mutation push new team in team array
     * args:(teamArray,data)
     */
    createNewTeam(state, payload) {
      if (payload.teamIndex == -1) {
        //add newly created team in teams array
        payload.teams.push(payload.data.team);
        //add public channel of new created team in company public channel array
        payload.data.channels.forEach((teamChannel) => {
          let channelIndex = payload.company.channels.findIndex(
            (channel) => channel._id == teamChannel._id
          );
          if (channelIndex == -1) {
            payload.company.channels.push(teamChannel);
          }
        });
      } else {
        Object.assign(payload.teams[payload.teamIndex], payload.data.team);
        //add/update public channel of new created team in company public channel array
        payload.data.channels.forEach((teamChannel) => {
          let channelIndex = payload.company.channels.findIndex(
            (channel) => channel._id == teamChannel._id
          );
          if (channelIndex == -1) {
            payload.company.channels.push(teamChannel);
          } else {
            Object.assign(payload.company.channels[channelIndex], teamChannel);
          }
        });
      }
    },
    /**
     * this uodates team name
     * args:(name,data)
     */
    updateTeamName(state, payload) {
      if (payload.team) {
        payload.team.name = payload.data.name;
      }
    },

    /**
     * this mutation updates team Profile picture
     * args:(team,Data(team_id,profile-picture))
     */
    updateTeamProfile(state, payload) {
      if (payload.team) {
        payload.team.profile_picture = payload.data.profile_picture;
      }
    },
    /**
     * this mutation push new user in team
     * args:(team,Data(teamArray,user_ids))
     */
    newUserAddedInTeam(state, payload) {
      if (payload.team) {
        payload.user_ids.forEach((userId) => {
          if (payload.team && !payload.team.users.includes(userId)) {
            //add user id in team
            payload.team.users.push(userId);
          }
          let channel = payload.companyChannels.find(
            (channel) => channel.team_id == payload.team._id && channel.default
          );
          if (channel && !channel.users.includes(userId)) {
            //add user id in default channel of team
            channel.users.push(userId);
          }
        });
      }
    },
    /**
     * this mutation remove  user from team
     * args:(team,Data(teamArray,user_ids))
     */
    userLeftTeam(state, payload) {
      if (payload.team) {
        payload.user_ids.forEach((userId) => {
          // remove the user from all channels of specific team from where user is removed/left
          for (let publicChannel in payload.company.channels) {
            if (publicChannel.team_id == payload.team._id) {
              publicChannel.users = publicChannel.users.filter(
                (user) => user != userId
              );
            }
          }
          // remove that user from  team
          payload.team.users = payload.team.users.filter(
            (user) => user != userId
          );
        });
      }
    },
    //specific team added in user side
    addedinTeam(state, payload) {
      let teamIndex = payload.teams.findIndex(
        (team) => team._id == payload.newData.team._id
      );
      teamIndex > -1
        ? Object.assign(payload.teams[teamIndex], payload.newData.team)
        : payload.teams.push(payload.newData.team);
      //add in public channel (i.e. announcements)
      payload.newData.channels.forEach((newChannel) => {
        let channelIndex = payload.companyChannels.findIndex(
          (channel) => newChannel._id == channel._id
        );
        channelIndex > -1
          ? Object.assign(payload.companyChannels[channelIndex], newChannel)
          : payload.companyChannels.push(newChannel);
      });
    },
    /**
     * user remove team from its local store
     * args:(teams array, team_id)
     */
    removedfromTeam(state, payload) {
      let teamIndex = payload.company.teams.findIndex(
        (team) => team._id == payload.team_id
      );
      if (teamIndex != -1) {
        // remove all channels of specific team from where user is removed
        payload.company.channels = payload.company.channels.filter(
          (channel) =>
            (channel.type != "support" && channel.team_id != payload.team_id) ||
            (channel.type == "support" &&
              (channel?.team_ids?.length == 0 ||
                (channel?.team_ids?.length == 1 &&
                  !channel.team_ids.includes(payload.team_id))))
        );
        //now remove team from which user is removed
        payload.company.teams.splice(teamIndex, 1);
      }
    },

    /**
     * team archived status updates (this mutation works in user side)
     * args:(teams array, team_id)
     */
    USER_ARCHIVE_TEAM(state, payload) {
      //remove all public channel of archived team
      payload.company.channels = payload.company.channels.filter(
        (channel) =>
          (channel.type != "support" && channel.team_id != payload.team_id) ||
          (channel.type == "support" &&
            (channel?.team_ids?.length == 0 ||
              (channel?.team_ids?.length == 1 &&
                !channel.team_ids.includes(payload.team_id))))
      );
      //remove archived team
      payload.company.teams = payload.company.teams.filter(
        (team) => team._id != payload.team_id
      );
    },

    /**
     * team archived status updates (this mutation works in user side)
     * args:(teams array, team_id)
     */
    USER_UNARCHIVE_TEAM(state, payload) {
      // Object.assign(payload.teams[payload.teamIndex], payload.data.team);
      let teamIndex = payload.company.teams.findIndex(
        (team) => team._id == payload.newData.team._id
      );
      teamIndex == -1
        ? payload.company.teams.push(payload.newData.team)
        : Object.assign(payload.company.teams[teamIndex], payload.newData.team);
      //add all channel of unarchived team
      payload.newData.channels.forEach((channel) => {
        if (
          payload.company.channels.findIndex(
            (singleChannel) => singleChannel._id == channel._id
          ) == -1
        ) {
          payload.company.channels.push(channel);
        }
      });
    },
    /**
     * remove user id or group channel
     * args:(channelsDetails, loginUserId, directChannels)
     */
    groupChannelUpdation(state, payload) {
      payload.channelsDetails.forEach((detail) => {
        if (detail.user_id == payload.loginUserId) {
          detail.channel_ids.forEach((removedChannelId) => {
            let channelIndex = payload.directChannels.findIndex(
              (channel) => channel._id == removedChannelId
            );
            if (channelIndex > -1) {
              payload.directChannels.splice(channelIndex, 1);
            }
          });
        } else {
          detail.channel_ids.forEach((removedChannelId) => {
            let channelIndex = payload.directChannels.findIndex(
              (channel) => channel._id == removedChannelId
            );
            if (channelIndex > -1) {
              payload.directChannels[
                channelIndex
              ].users = payload.directChannels[channelIndex].users.filter(
                (userId) => userId != detail.user_id
              );
            }
          });
        }
      });
    },
  },
};

export default teamSocket;
