var errorSockets = {
  state: {},
  getters: {},
  actions: {
    /**
     * this action is responsible for new single message seen
     * (1): first it find company index, team index and channel index from channel id
     * (2): then this action commit new_message_count
     * args:(context, data)
     */
    socket_messageReadEvent(context, data) {
      let channelDetail = context.getters.getMessagesDetail(data);
      
      if (channelDetail.channel) {
       
        if (
          data.event == "single_read" &&
          context.rootGetters.loggedInUserDetails._id == data.reader_id
        ) {
          //if channel new_message_count is greater than zero then remove message count from channel, team and company
          if (channelDetail.channel.new_message_count) {
            //change new_message_count of channel
            context.commit("updateMessageRead", {
              channel: channelDetail.channel,
            });
          }
        } else if (
          data.event == "single_read" &&
          context.rootGetters.loggedInUserDetails._id != data.reader_id &&
          context.rootGetters.getChannelMessages(channelDetail.channel._id)
        ) {
          let messageIndex = context.rootGetters
            .getChannelMessages(channelDetail.channel._id)
            .findIndex((item) => {
              return item._id == data.message_id;
            });
          //if message found then update message is_read property
          if (messageIndex != -1) {
            context.commit("singleMsgReadMutation", {
              user_id: data.reader_id,
              loginUserId: context.rootGetters.loggedInUserDetails._id,
              singleMessage: context.rootGetters.getChannelMessages(
                channelDetail.channel._id
              )[messageIndex],
              channelUsers: channelDetail.channel.users,
              channel_bot_id: channelDetail.channel.bot_id,
            });
          }
        } else if (
          data.event == "multiple_read" &&
          context.rootGetters.loggedInUserDetails._id == data.reader_id
        ) {
          if (channelDetail.channel.new_message_count) {
            //change new_message_count of channel
            context.commit("updateMessageRead", {
              channel: channelDetail.channel,
            });
          }
        } else if (
          data.event == "multiple_read" &&
          context.rootGetters.loggedInUserDetails._id != data.reader_id
        ) {
          if (
            context.rootGetters.getChannelMessages(channelDetail.channel._id) &&
            context.rootGetters.getChannelMessages(channelDetail.channel._id)
              .length
          ) {
            context.commit("multipleMessagesRead", {
              user_id: data.reader_id,
              channelMessages: context.rootGetters.getChannelMessages(
                channelDetail.channel._id
              ),
              loginUserId: context.rootGetters.loggedInUserDetails._id,
              channelUsers: channelDetail.channel.users,
              channel_bot_id: channelDetail.channel.bot_id,
            });
          }
        }
      }
    },
    // Vuex action for handling socket events
 

    /**
     * this action is responsible for new single message seen
     * (1): first it find company index, team index and channel index from channel id
     * (2): then this action commit new_message_count
     * args:(context, data)
     */
    socket_replyReadEvent(context, data) {
      let channelDetail = context.getters.getMessagesDetail(data);
      if (channelDetail.channel) {
        if (
          data.event == "read_thread" &&
          context.rootGetters.getParentReplyId ==
            JSON.parse(data.message).replying_id &&
          context.rootGetters.loggedInUserDetails._id != data.reader_id
        ) {
          //if channel new_message_count is greater than zero then remove message count from channel, team and company
          context.commit("addUserInChildRead", {
            user_id: context.rootGetters.loggedInUserDetails._id,
            channel: channelDetail.channel,
            message: JSON.parse(data.message),
            replyMessages: context.rootGetters.getReplyedMessages.child,
            parentReplyId: context.rootGetters.getParentReplyId,
            readerId: data.reader_id,
            channelUsers: channelDetail.channel.users,
            channel_type: data.type,
          });
        } else if (
          (data.event == "read_thread" || data.event == "get_replies") &&
          context.rootGetters.loggedInUserDetails._id == data.reader_id
        ) {
          context.commit("markSceenReplyThread", {
            channel: channelDetail.channel,
            message: JSON.parse(data.message),
            event: data.event,
          });
        } else if (
          data.event == "get_replies" &&
          context.rootGetters.loggedInUserDetails._id != data.reader_id
        ) {
          context.commit("multipleReplyRead", {
            user_id: data.reader_id,
            channelMessages: context.rootGetters.getReplyedMessages.child,
            loginUserId: context.rootGetters.loggedInUserDetails._id,
            channelUsers: channelDetail.channel.users,
            channel_bot_id: channelDetail.channel.bot_id,
          });
        } else if (
          data.event == "allThreadSeen" &&
          context.rootGetters.loggedInUserDetails._id == data.reader_id
        ) {
          context.commit("markSceanAllReplies", {
            unsceenReply: channelDetail.channel.thread_child_messages,
          });
        } else if (
          data.event == "allThreadSeen" &&
          context.rootGetters.loggedInUserDetails._id != data.reader_id &&
          data.message_ids.includes(context.rootGetters.getParentReplyId)
        ) {
          context.commit("multipleReplyRead", {
            user_id: data.reader_id,
            channelMessages: context.rootGetters.getReplyedMessages.child,
            loginUserId: context.rootGetters.loggedInUserDetails._id,
            channelUsers: channelDetail.channel.users,
            channel_bot_id: channelDetail.channel.bot_id,
          });
        }
      }
    },
  },
  mutations: {
    /**
     * this mutation is responsible removing message (i.e. read message) from thread_child_messages (unseen messages)
     * (1): find reply's index
     * (2): if Index found remove reply message from thread_child_messages (unseen messages).
     * args: (state, payload)
     */
    markSceenReplyThread(state, payload) {
      let replyIndex;
      if (payload.event == "read_thread") {
        replyIndex = payload.channel.thread_child_messages.findIndex(
          (message) => {
            return payload.message._id == message._id;
          }
        );
      } else if (payload.event == "get_replies") {
        replyIndex = payload.channel.thread_child_messages.findIndex(
          (message) => {
            return payload.message._id == message.parent._id;
          }
        );
      }
      if (replyIndex > -1) {
        payload.channel.thread_child_messages.splice(replyIndex, 1);
      }
    },


    /**
     * this mutation is responsible for update new_message_count of channel
     * args: (state, payload)
     */
    updateMessageRead(state, payload) {
      payload.channel.new_message_count = 0;
    },
    /**
     * this mutation is responsible for adding user id in single message read
     * args: (state, payload)
     */
    singleMsgReadMutation(state, payload) {
      if (
        payload.singleMessage.sender_id == payload.loginUserId &&
        payload.singleMessage.is_read.indexOf(payload.user_id) === -1
      ) {
        payload.singleMessage.is_read.push(payload.user_id);
      } else if (
        payload.singleMessage.bot_sender_id == payload.loginUserId &&
        payload.channelUsers.indexOf(payload.singleMessage.bot_sender_id) >=
          0 &&
        payload.singleMessage.is_read.indexOf(payload.user_id) === -1
      ) {
        payload.singleMessage.is_read.push(payload.user_id);
      }
    },
    
    /**
     * this mutation is responsible for adding new user id to is_read property in messages
     * args: (state, payload)
     */
    multipleMessagesRead(state, payload) {
      //which read messages
      if (payload.user_id == payload.loginUserId) {
        payload.channelMessages.forEach(function(part, index) {
          if (
            this[index].sender_id != payload.loginUserId &&
            this[index].is_read.indexOf(payload.user_id) === -1
          ) {
            this[index].is_read.push(payload.user_id);
          }
        }, payload.channelMessages);
      }
      //for other users
      else {
        payload.channelMessages.forEach(function(part, index) {
          if (
            this[index].sender_id == payload.loginUserId &&
            this[index].is_read.indexOf(payload.user_id) === -1
          ) {
            this[index].is_read.push(payload.user_id);
          } else if (
            this[index].bot_sender_id == payload.loginUserId &&
            payload.channelUsers.indexOf(this[index].bot_sender_id) >= -1 &&
            this[index].is_read.indexOf(payload.user_id) === -1
          ) {
            this[index].is_read.push(payload.user_id);
          }
        }, payload.channelMessages);
      }
    },
    // Vuex mutation for updating the state

    /**
     * this mutation is responsible adding reader's id in the reply's child_read to mark read message in sender side (i.e read by)
     * (1): check parent message should be same as the replying_id (i.e reply's parent)
     *      (a): find the index of message in the repliedMessages.
     *      (b): if reader in not in child_read the push reader's id.
     * args: (state, payload)
     */
    addUserInChildRead(state, payload) {
      let replyIndex = payload.replyMessages.findIndex((message) => {
        return payload.message._id == message._id;
      });
      if (
        replyIndex > -1 &&
        payload.replyMessages[replyIndex].child_read.findIndex((user_id) => {
          return user_id == payload.readerId;
        }) == -1
      ) {
        payload.replyMessages[replyIndex].child_read.push(payload.readerId);
      } else if (
        replyIndex > -1 &&
        payload.channel_type == "support" &&
        payload.message.bot_sender_id == payload.user_id &&
        payload.replyMessages[replyIndex].child_read.findIndex((user_id) => {
          return user_id == payload.readerId;
        }) == -1
      ) {
        payload.replyMessages[replyIndex].child_read.push(payload.readerId);
      }
    },
    multipleReplyRead(state, payload) {
      //which read messages
      if (payload.user_id == payload.loginUserId) {
        if (payload.channelMessages) {
          payload.channelMessages.forEach(function(part, index) {
            if (
              this[index].sender_id != payload.loginUserId &&
              this[index].child_read.indexOf(payload.user_id) === -1
            ) {
              this[index].child_read.push(payload.user_id);
            }
          }, payload.channelMessages);
        }
      }
      //for other users
      else {
        payload.channelMessages.forEach(function(part, index) {
          if (
            this[index].sender_id == payload.loginUserId &&
            this[index].child_read.indexOf(payload.user_id) === -1
          ) {
            this[index].child_read.push(payload.user_id);
          } else if (
            this[index].bot_sender_id == payload.loginUserId &&
            payload.channelUsers.indexOf(this[index].bot_sender_id) >= -1 &&
            this[index].child_read.indexOf(payload.user_id) === -1
          ) {
            this[index].child_read.push(payload.user_id);
          }
        }, payload.channelMessages);
      }
    },
  },
};
export default errorSockets;
