/**
 * System: Whistle It
 * Developer: Nabil Ahmad
 * Organization: Whistle It
 * Purpose: This file is for calling API for support channels
 */
import channelAPI from "@/api/channelAPI";
const actions = {
  /*
    This function is responsible for making api call for add members in already existing support channel
    args:(add_company_id ,support_channel_id, add_user_ids,add_team_ids ) done (callback)
    */
  updateFollowers(context, payload) {
    return new Promise((resolve, reject) => {
      var done = (response) => {
        if (response.status == 200) {
          resolve(response);
        } else {
          reject(response);
        }
      };
      channelAPI.updateChannel(payload, done);
    });
  },
  /*
    This function is responsible for making api call for add members in already existing support channel
    args:(add_company_id ,support_channel_id, add_user_ids,add_team_ids ) done (callback)
    */
  getFollowersDataAPI(context, payload) {
    return new Promise((resolve, reject) => {
      var done = (response) => {
        if (response.status == 200) {
          resolve(response);
        } else {
          reject(response);
        }
      };
      channelAPI.getFollowers(payload, done);
    });
  },
  /*
      This function is responsible for making api call to update channel custom name detail
      args:(company_id ,support_channel_id, updated custom name)
      */
  updateChannelCustomNameData(context, payload) {
    return new Promise((resolve, reject) => {
      var done = (response) => {
        if (response.status == 200) {
          context.commit("updateCustomName", {
            ...payload,
            companyChannels: context.rootGetters.getSelectedCompany.channels,
          });
          resolve(response);
        } else {
          reject(response);
        }
      };
      channelAPI.updateChannel(payload, done);
    });
  },
  /*
      This function is responsible for making api call to get announcement message detail
      args:(message_id)
      */
  fetchAnnouncementDetails({ rootState }, message_id) {
    return new Promise((resolve, reject) => {
      var done = (response) => {
        if (response.status == 200) {
          resolve(response);
        } else {
          reject(response);
        }
      };
      channelAPI.fetchAnnouncementDetails(
        { message_id, channel_id: rootState.selectedChannel._id },
        done
      );
    });
  },
};
const mutations = {
  //update custom name of channel
  updateCustomName(state, payload) {
    let findedChannel = payload.companyChannels.find(
      (channel) => channel._id == payload.channel_id
    );
    if (findedChannel) {
      if ("display_default_name" in payload) {
        findedChannel.display_default_name = payload.display_default_name;
        findedChannel.display_alias = null;
        findedChannel.display_user_name = false;
      } else if ("display_user_name" in payload) {
        findedChannel.display_user_name = payload.display_user_name;
        findedChannel.display_alias = null;
        findedChannel.display_default_name = null;
      } else if ("display_alias" in payload) {
        findedChannel.display_alias = payload.alias;
        findedChannel.display_default_name = null;
        findedChannel.display_user_name = false;
      }
    }
  },
};
export default {
  mutations,
  actions,
};
