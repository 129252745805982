import teamAPI from "@/api/teamAPI.js";
import axios from "axios";
// import debug from "@/console";
const state = {};
const getters = {
  //this getter will return reply count in all private and public channel's replies.
  getMsgsCountTeam: (getters, rootGetters) => (teamId) => {
    let teamMsgCount = 0;
    rootGetters.getSelectedCompany.channels.map((channel) => {
      if ( 
        !channel.muted &&
        ((channel.type != "support" && channel.team_id == teamId) ||
          (channel.type == "support" &&
            channel?.team_ids?.length &&
            channel.team_ids[0] == teamId)) &&
        channel.joined == true &&
        channel.archived == null
      ) {
        teamMsgCount +=
          channel.new_message_count + channel.thread_child_messages.length;
      }
    });
    return teamMsgCount;
  },
  /**
   * This getter will return true if there is any channel which is marked as unread otherwise it returns false
   *
   * @param {string} teamId
   * @return boolean
   */
  getUnreadCountTeam: (getters, rootGetters) => (teamId) => {
    let isAnyUnreadChannel = 0;
    rootGetters.getSelectedCompany.channels.map((channel) => {
      if (
        channel.unread_channel &&
        ((channel.type != "support" && channel.team_id == teamId) ||
          (channel?.team_ids?.length && channel.team_ids[0] == teamId)) &&
        channel.joined == true &&
        channel.archived == null
      ) {
        isAnyUnreadChannel = true;
      }
    });
    return isAnyUnreadChannel;
  },
  //get team  by passing team_id
  getClickedTeam: (getters, rootGetters) => (team_id) => {
    return rootGetters.getTeams.find((item) => {
      return item._id == team_id;
    });
  },
};
const actions = {
  /*
    this method fetch selected company data from API
    args (company_id)
       */
  fetchTeams: (context, body) => {
    return new Promise((resolve, reject) => {
      var done = (response) => {
        if (response.status == 200) {
          resolve(response);
        } else {
          reject(response);
        }
      };
      teamAPI.fetchTeams(body, done);
    });
  },
  /*
    this method fetch non members of specific team
    args (team_id)
       */
  fetchTeamNonMembers: (context, body) => {
    return new Promise((resolve, reject) => {
      var done = (response) => {
        if (response.status == 200) {
          resolve(response);
        } else {
          reject(response);
        }
      };
      teamAPI.fetchTeamNonMembers(body, done);
    });
  },
  /*
    this method fetch  members of specific team
    args (team_id)
       */
  fetchTeamMembers: (context, body) => {
    return new Promise((resolve, reject) => {
      var done = (response) => {
        if (response.status == 200) {
          resolve(response);
        } else {
          reject(response);
        }
      };
      teamAPI.fetchTeamMembers(body, done);
    });
  },
  //Create New Team
  // This Action is Dispatched From AddTeam Modal File
  // Data to send { CompanyID,TeamName, ProfilePicture }
  addTeam: (context, body) => {
    return new Promise((resolve, reject) => {
      let teamArray = context.rootGetters.getTeams;
      let company = context.rootGetters.getSelectedCompany;
      context.commit("createTeam", {
        teamArray: teamArray,
        newTeamObj: body.teamObj,
      });
      var done = (response) => {
        if (response.status == 201) {
          resolve(response);
          context.commit("createTeamResponse", {
            teamArray: teamArray,
            company,
            tempId: body.teamObj._id,
            newId: response.data.team._id,
            teamData: response.data,
          });
        }
        //remove specific team from store if API send error response on team creation
        else {
          context.commit("REMOVE_TEAM_FROM_STORE", {
            teamArray: teamArray,
            teamId: body.teamObj._id,
          });
          reject(response);
        }
      };
      teamAPI.createTeam(body.formData, done);
    });
  },

  //add members to team (args : user_ids,team_id)
  addTeamMembers: (context, body) => {
    let company = context.rootGetters.getSelectedCompany;
    return new Promise((resolve, reject) => {
      var done = (response) => {
        if (response.status == 200) {
          resolve(response);
          context.commit("ADD_TEAM_MEMBERS", {
            teamArray: context.rootGetters.getClickedTeam(body.team_id),
            company,
            user_ids: body.user_ids,
            teamNewData: response.data,
          });
        } else {
          reject(response);
        }
      };
      teamAPI.addUsersInTeam(body.team_id, body.user_ids, done);
    });
  },
  //remove team members (args : user_ids,team_id)
  removeTeamMembers: (context, body) => {
    return new Promise((resolve) => {
      var done = (response) => {
        resolve(response);
        context.commit("REMOVE_TEAM_MEMBERS", {
          teamArray: context.rootGetters.getClickedTeam(body.team_id),
          user_ids: body.user_ids,
        });
      };
      teamAPI.removeUsersInTeam(body.team_id, body.user_ids, done);
    });
  },

  //Archive Team
  // This Action is Dispatched From TeamsPannel File
  // Data to send { CompanyID, TeamID }
  archiveTeam: ({ commit, rootGetters }, body) => {
    let company = rootGetters.getSelectedCompany;
    return new Promise((resolve) => {
      teamAPI.archiveTeam(
        body.company_id,
        body.team_id,
        body.deleted_at,
        async (response) => {
          resolve(response);
          if (response.status == 200) {
            if (rootGetters.getSelectedTeam) {
              let teamId = await rootGetters.getSelectedTeam._id;
              if (
                teamId == body.team_id &&
                rootGetters.getJoinedTeams.length > 1
              ) {
                teamId = await rootGetters.getSelectedCompany.teams.find(
                  (team) => team.joined == true && team.archived == null
                )._id;
                let channelId = await rootGetters.getSelectedCompany.channels.find(
                  (channel) =>
                    channel.team_id == teamId &&
                    channel.joined &&
                    channel.archived == null
                )._id;
                // set first join team as selected
                commit("setSelectedTeam", teamId);
                //select joined channel
                commit("setSelectedChannel", {
                  _id: channelId,
                  type: "public",
                });
              }
              commit("ARCHIVE_TEAM", {
                teamArray: rootGetters.getTeams,
                company,
                teamObj: body,
              });
            }
          }
        }
      );
    });
  },

  // Unarchive Team
  // This Action is Dispatched From TeamsPannel File
  // Data to send { CompanyID, TeamID }
  unarchiveTeam: ({ commit, rootGetters }, body) => {
    let company = rootGetters.getSelectedCompany;
    return new Promise((resolve) => {
      teamAPI.unarchiveTeam(
        body.company_id,
        body.team_id,
        body.deleted_at,
        (response) => {
          resolve(response);
          if (response.status == 200) {
            commit("UNARCHIVE_TEAM", {
              teamObj: body,
              teamNewData: response.data,
              company,
            });
          }
        }
      );
    });
  },

  // Update Team Name
  // This Action is Dispatched From EditTeamModal File
  // Data to send { CompanyID, TeamName, TeamID  }
  updateTeamName: (context, body) => {
    return new Promise((resolve) => {
      teamAPI.updateTeamName(
        body.team_id,
        body.name,
        body.profile_picture,
        body.deleted_at,
        (response) => {
          //send back response to calling function
          resolve(response);
          if (response.status == 200) {
            context.commit("UPDATE_TEAM_NAME", {
              teamArray: context.rootGetters.getTeams,
              newTeamObj: body,
            });
          }
        }
      );
    });
  },
  // Delete Team Icon
  // This Action is Dispatched From EditTeamModal File
  // Data to send { CompanyID, TeamID  }
  updateTeamIcon: (context, requestObj) => {
    return new Promise((resolve) => {
      var done = (response) => {
        //send back response to caller function
        resolve(response);
      };
      teamAPI.updateTeamIcon(requestObj, done);
    });
  },
  removeTeamIcon: (context, requestObj) => {
    return new Promise((resolve) => {
      var done = (response) => {
        if (response.status == 200) {
          context.commit("UPDATE_TEAM_ICON", {
            teamArray: context.rootGetters.getTeams,
            newTeamObj: requestObj,
          });
        }
        //send back response to caller function
        resolve(response);
      };
      teamAPI.deleteTeamIcon(
        requestObj.team_id,
        requestObj.profile_picture,
        done
      );
    });
  },
  // Invite user by sending invitation link
  teamInvitation: (context, data) => {
    return new Promise((resolve, reject) => {
      let customAxios = axios.create({
        headers: {
          key: data.key,
        },
      });

      customAxios
        .post(process.env.VUE_APP_BASE_URL + "/company/member/add", data.obj)
        .then((res) => {
          resolve(res);
          if (res.status == 200) {
            let socketData = {
              company_id: data.obj.company_id,
              team_ids: data.obj.team_ids,
              key: data.key,
              users: res.data.users,
            };
            context.dispatch("teamLinkInvite", socketData);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
const mutations = {
  //This will create Team in selected company
  createTeam: (state, payload) => {
    payload.teamArray.push(payload.newTeamObj);
  },
  //This will change Team data after api response
  createTeamResponse: (state, payload) => {
    //add announcement channel count to company
    payload.company.new_message_count = 0;
    //find team with temp id and replace it with original id after response
    payload.teamArray.splice(
      payload.teamArray.findIndex((item) => {
        return item._id == payload.tempId || item._id == payload.newId;
      }),
      1,
      payload.teamData.team
    );
    //add public channel of new created team in company public channel array
    payload.teamData.channels.forEach((teamChannel) => {
      let channelIndex = payload.company.channels.findIndex(
        (channel) => channel._id == teamChannel._id
      );
      if (channelIndex == -1) {
        payload.company.channels.push(teamChannel);
      }
    });
  },
  //remove duplicate team from store
  REMOVE_TEAM_FROM_STORE: (state, payload) => {
    let teamIndex = payload.teamArray.findIndex((item) => {
      return item._id == payload.teamId;
    });
    if (teamIndex != -1) {
      payload.teamArray.splice(teamIndex, 1);
    }
  },
  //Add ids of users to local store
  ADD_TEAM_MEMBERS: (state, payload) => {
    //add all public channel of unarchived team
    if (payload?.teamNewData?.team) {
      Object.assign(payload.teamArray, payload.teamNewData.team);
      //add default channel of unarchived team
      payload.teamNewData.channels.forEach((channel) => {
        if (
          payload.company.channels.findIndex(
            (publicChannel) => publicChannel._id == channel._id
          ) == -1
        ) {
          payload.company.channels.push(channel);
        }
      });
    }

    let index = payload.company.channels.findIndex(
      (channel) =>
        channel.default == true && channel.team_id == payload.teamArray._id
    );
    for (let userId of payload.user_ids) {
      if (!payload.teamArray.users.includes(userId)) {
        payload.teamArray.users.push(userId);
      }
      if (
        index != -1 &&
        !payload.company.channels[index].users.includes(userId)
      ) {
        //push user id in defualt channel
        payload.company.channels[index].users.push(userId);
      }
    }
  },

  //remove ids of users from local store
  REMOVE_TEAM_MEMBERS: (state, payload) => {
    for (let userId of payload.user_ids) {
      let index = payload.teamArray.users.findIndex((item) => {
        return item == userId;
      });
      if (index != -1) {
        payload.teamArray.users.splice(index, 1);
      }
    }
  },

  //This will archive team
  ARCHIVE_TEAM: (state, payload) => {
    //set team to archived
    //find that team  and set archived to current date
    let team = payload.teamArray.find((team) => {
      return team._id == payload.teamObj.team_id;
    });
    if (team) {
      team.archived = new Date().toLocaleString("sv-SE");
      //remove all channels of archived team
      payload.company.channels = payload.company.channels.filter(
        (channel) =>
          channel.type != "support" &&
          channel.team_id != payload.teamObj.team_id
      );
    }
  },

  //   Unarchive Team
  UNARCHIVE_TEAM: (state, payload) => {
    //set team to unarchived
    //find that team and set unarchived to null
    let teamIndex = payload.company.teams.findIndex(
      (team) => team._id == payload.teamObj.team_id
    );
    if (teamIndex > -1) {
      payload.company.teams[teamIndex].archived = null;
      // teamIndex == -1 ?  payload.company.teams.push(payload.newData.team) : Object.assign(payload.company.teams[teamIndex], payload.newData.team);
      //add all public channel of unarchived team
      if (payload.teamNewData.team) {
        Object.assign(
          payload.company.teams[teamIndex],
          payload.teamNewData.team
        );
        payload.teamNewData.channels.forEach((channel) => {
          if (
            payload.company.channels.findIndex(
              (singleChannel) => singleChannel._id == channel._id
            ) == -1
          ) {
            payload.company.channels.push(channel);
          }
        });
      }
    }
  },

  //This will update team name data in state
  UPDATE_TEAM_NAME: (state, payload) => {
    //set new name of tea,
    //find that team  and set archived to current date
    payload.teamArray.find(
      (item) => item._id == payload.newTeamObj.team_id
    ).name = payload.newTeamObj.name;
  },
  //This will update team icon data in state
  UPDATE_TEAM_ICON: (state, payload) => {
    //find that team  and set DP
    payload.teamArray.find(
      (item) => item._id == payload.newTeamObj.team_id
    ).profile_picture = payload.newTeamObj.profile_picture;
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
