/**
System: Whistle It
Developer: Nabil Ahmad
Organization: Programmer 
Purpose: This file is responsible for handling errors in vue components and send webhok.
 */
import axios from "axios";
// import router from "@/router";
const actions = {
    /**
     * This action is responsible for sending error
     * args:(context)
     */
    sendWebHook(context, requestBody) {
        let data = {
            attachments: [
                {
                    color: "#FF8000",
                    text: requestBody.err.toString(),
                    pretext: requestBody.info
                }
            ]
        }
        axios
            .post("https://schat.pf.com.pk/api/webhooks/612c997bd32356513743e300", data)
            .then(() => {
            })
            .catch(() => {
            });
    },
};

export default {
    actions,
};