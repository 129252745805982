import samlAPI from "@/api/samlAPI";
const actions = {
  /*
    Responsible to read saml domains
    args:(context, payload)
    */
  readSamlDomains(context, payload) {
    return new Promise((resolve, reject) => {
      samlAPI.readSamlDomains(payload, (response) => {
        if (response.status == 200) {
          resolve(response);
        } else {
          reject(response);
        }
      });
    });
  },
  /*
    Responsible to generate saml credentials
    args:(context, payload)
    */
  generateSamlKeys(context, payload) {
    return new Promise((resolve, reject) => {
      samlAPI.generateSamlKeys(payload, (response) => {
        if (response.status == 200 || response.status == 201) {
          resolve(response);
        } else {
          reject(response);
        }
      });
    });
  },
  /*
    Responsible to integrate saml credentials into okta
    args:(context, payload)
    */
  integrateSaml(context, payload) {
    return new Promise((resolve, reject) => {
      samlAPI.integrateSaml(payload, (response) => {
        if (response.status == 200) {
          resolve(response);
        } else {
          reject(response);
        }
      });
    });
  },
  /*
    Responsible to delete saml integration
    args:(context, payload)
    */
  deleteSaml(context, payload) {
    return new Promise((resolve, reject) => {
      samlAPI.deleteSaml(payload, (response) => {
        if (response.status == 200) {
          resolve(response);
        } else {
          reject(response);
        }
      });
    });
  },
};
export default {
  actions,
};
