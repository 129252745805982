/*
System: Whistle It
Developer: Ahmad Hannan
Organization: Whistle It
Purpose: This file is used for making api calls for channels
*/
import requestQueue from "@/service/requestQueue";
var requestBody;
var requestEndPoint;
var token;

const channelAPI = {
  /* Channel CRUD */

  /*
This function is responsible for making api call for create channel
args:(channel name,channel type, team id, channel description, company id), callback function for done
*/
  createChannel: (
    company_id,
    team_id,
    name,
    type,
    description,
    direct_channel_id,
    user_ids,
    secret_key_url,
    guest_email,
    guest_domain,
    guest_company_id,
    done
  ) => {
    requestBody = {
      company_id,
      team_id,
      name,
      type,
      description,
      direct_channel_id,
      user_ids,
      secret_key_url,
      guest_email,
      guest_domain,
      guest_company_id,
    };
    //for direct channel with guest users
    if (guest_domain) {
      requestEndPoint = "directCloudChannel/create";
    } else if (secret_key_url) {
      requestEndPoint = "guestChannel/connectHostChannel";
    } else {
      requestEndPoint = "channel/create";
    }
    token = JSON.parse(localStorage.getItem("token"));
    requestQueue.post(requestEndPoint, token, requestBody, done);
  },
  /*
   this method fetch selected company archived channels from API
   args (company_id)
      */
  fetchChannels: (requestBody, done) => {
    requestEndPoint = "adminPanel/getChannels";
    token = JSON.parse(localStorage.getItem("token"));
    requestQueue.post(requestEndPoint, token, requestBody, done);
  },
  /*
This function is responsible for making api call for add user or join in channel
args:(user_id[] users to add, channel_id), callback function for done
*/
  addUserInChannel: (user_ids, channel_id, done) => {
    requestBody = {
      user_ids,
      channel_id,
    };
    requestEndPoint = "channel/member/add";
    token = JSON.parse(localStorage.getItem("token"));
    requestQueue.post(requestEndPoint, token, requestBody, done);
  },
  /*
This function is responsible for making api call for remove users from channel
args:(user_id[] users to remove, channel_id), callback function for done
*/
  removeUsersFromChannel: (user_ids, channel_id, done) => {
    requestBody = {
      user_ids,
      channel_id,
    };
    requestEndPoint = "channel/member/remove";
    token = JSON.parse(localStorage.getItem("token"));
    requestQueue.post(requestEndPoint, token, requestBody, done);
  },

  archiveChannelFromAdmin: (body, done) => {
    requestBody = body;
    requestEndPoint = "channel/archive";
    token = JSON.parse(localStorage.getItem("token"));
    requestQueue.post(requestEndPoint, token, requestBody, done);
  },
  /*
This function is responsible for making api call for edit channel information
args:(name to edit, description, channel id, company id ,team id), callback function for done
*/
  editChannelInfo: (
    name,
    description,
    channel_id,
    company_id,
    team_id,
    done
  ) => {
    requestBody = {
      name,
      description,
      channel_id,
      company_id,
      team_id,
    };
    requestEndPoint = "channel/update";
    token = JSON.parse(localStorage.getItem("token"));
    requestQueue.post(requestEndPoint, token, requestBody, done);
  },

  /*
This function is responsible for making api call for Mute Channel
args:(channel_id, company_id, team_id), callback function for done
*/
  muteChannel: (channel_id, muted_channel, mute_timer, done) => {
    requestBody = {
      channel_id,
      muted_channel,
    };
    if (mute_timer) {
      requestBody.mute_timer = mute_timer;
    }
    requestEndPoint = "user/update";
    token = JSON.parse(localStorage.getItem("token"));
    requestQueue.post(requestEndPoint, token, requestBody, done);
  },
  /*
    This function is responsible for making api call for flage Channel
    args:(channel_id, flag status), callback function for done
    */
  flagChannel: (channel_id, flagged_channel, done) => {
    requestBody = {
      channel_id,
      flagged_channel,
    };
    requestEndPoint = "user/update";
    token = JSON.parse(localStorage.getItem("token"));
    requestQueue.post(requestEndPoint, token, requestBody, done);
  },
  /*
This function is responsible for making api call for leave channel
args:(user_id[] users to leave, channel_id, creator a new creator which user set value before leave), callback function for done
*/
  leaveChannel: (user_ids, channel_id, creator, done) => {
    requestBody = {
      user_ids,
      channel_id,
      creator,
      method: 'leave'
    };
    requestEndPoint = "channel/member/remove";
    token = JSON.parse(localStorage.getItem("token"));
    requestQueue.post(requestEndPoint, token, requestBody, done);
  },
  /*
This function is responsible for making api call for archive channel
args:(channel_id, company_id, team_id), callback function for done
*/
  archiveChannel: (team_id, channel_id, deleted_at, done) => {
    requestBody = {
      team_id,
      channel_id,
      deleted_at,
    };
    requestEndPoint = "channel/update";
    token = JSON.parse(localStorage.getItem("token"));
    requestQueue.post(requestEndPoint, token, requestBody, done);
  },
  /*
This function is responsible for making api call for unarchive channel
args:(channel_id, company_id, team_id), callback function for done
*/
  unarchiveChannel: (channel_id, team_id, deleted_at, done) => {
    requestBody = {
      channel_id,
      team_id,
      deleted_at,
    };
    requestEndPoint = "channel/update";
    token = JSON.parse(localStorage.getItem("token"));
    requestQueue.post(requestEndPoint, token, requestBody, done);
  },
  /*
This function is responsible for making api call for readOnly feature
args:(channel_id, company_id, team_id), callback function for done
*/
  readOnly: (
    channel_id,
    team_id,
    read_only,
    user_ids,
    allowed_senders,
    done
  ) => {
    requestBody = read_only
      ? {
          channel_id,
          team_id,
          read_only,
          user_ids,
          allowed_senders,
        }
      : {
          channel_id,
          team_id,
          read_only,
        };
    requestEndPoint = "channel/update";
    token = JSON.parse(localStorage.getItem("token"));
    requestQueue.post(requestEndPoint, token, requestBody, done);
  },

  /*
This function is responsible for making api call for allowed senders
args:(channel_id, company_id, team_id), callback function for done
*/
  readonlyAllowedusers: (
    channel_id,
    team_id,
    allowed_senders,
    user_ids,
    read_only,
    done
  ) => {
    requestBody = {
      channel_id,
      team_id,
      allowed_senders,
      user_ids,
      read_only,
    };
    requestEndPoint = "channel/update";
    token = JSON.parse(localStorage.getItem("token"));
    requestQueue.post(requestEndPoint, token, requestBody, done);
  },
  /*
  This function is responsible for making api call to update connect/secret url 
  of selected channel
  args:(channel_id), callback function for done
  */
  updateSecretKey: (channel_id, done) => {
    requestBody = {
      channel_id,
    };
    requestEndPoint = "channel/updateSecretKey";
    token = JSON.parse(localStorage.getItem("token"));
    requestQueue.post(requestEndPoint, token, requestBody, done);
  },
  /*
  This function is responsible for making api call to get cloud channels count
  args: callback function for done
  */
  getCloudChannelsMsgsCount: (payload, done) => {
    requestEndPoint = "guestChannelCount?page=" + payload.page;
    let requestData;
    if (payload.channel_id) {
      requestData = {
        channel_id: payload.channel_id,
      };
    }
    token = JSON.parse(localStorage.getItem("token"));
    requestQueue.post(requestEndPoint, token, requestData, done);
  },
  /*
  This function is responsible for making api call to update channel
  args: callback function for done
  */
  updateChannel: (payload, done) => {
    requestEndPoint = "channel/update";
    token = JSON.parse(localStorage.getItem("token"));
    requestQueue.post(requestEndPoint, token, payload, done);
  },
  /*
  This function is responsible for making api call to update channel
  args: payload and callback function for done
  */
  getFollowers: (payload, done) => {
    if (payload.isAnnouncement) {
      requestEndPoint = "channel/getAnnouncementUsers";
    } else {
      requestEndPoint = "channel/getFollowers";
    }
    token = JSON.parse(localStorage.getItem("token"));
    requestQueue.post(requestEndPoint, token, payload, done);
  },
  /*
  This function is responsible for making api call to make existing channel to cloud channel
  args: callback function for done
  */
  convertChannelToCloud: (payload, done) => {
    requestEndPoint = "guestChannel/connectExistingChannelWithHost";
    token = JSON.parse(localStorage.getItem("token"));
    requestQueue.post(requestEndPoint, token, payload, done);
  },
  /*
    This function is responsible for making api call for fetching  announcement message details
    args: (message_id, support_channel_id)
    */
  fetchAnnouncementDetails: (requestBody, done) => {
    requestEndPoint = "channel/getUserDetails";
    token = JSON.parse(localStorage.getItem("token"));
    requestQueue.post(requestEndPoint, token, requestBody, done);
  },
  /*
    This function is responsible for removing guest user from cloud channel
    args: (req)
    */
  fetchGuestWorkspaces: (payload, done) => {
    requestEndPoint = "channel/readGuest";
    token = JSON.parse(localStorage.getItem("token"));
    requestQueue.post(requestEndPoint, token, payload, done);
  },
  /*
    This function is responsible for removing guest user from cloud channel
    args: (req)
    */
  removeGuestFromChannel: (payload, done) => {
    requestEndPoint = "channel/removeGuest";
    token = JSON.parse(localStorage.getItem("token"));
    requestQueue.post(requestEndPoint, token, payload, done);
  },
  /*
    This function is responsible for reading channel of selected company according to channel type
    args: (payload, done)
    */
  readChannls: (payload, done) => {
    requestEndPoint = "channel/read";
    token = JSON.parse(localStorage.getItem("token"));
    requestQueue.post(requestEndPoint, token, payload, done);
  },
};

export default channelAPI;