/**
 * System: Whistle It
 * Developer: Ahmad Hanan & Nabil Ahmad
 * Organization: Whistle it
 * Purpose: This file is for listening and changing updated data in admin store.
 */

var departmentSocket = {
  state: {},
  getters: {},
  actions: {
    /**
     * This action listen for socket whenever user department is updated
     *
     * @param {Object} payload recieved from socket
     * @return {void}
     */
    socket_userDepartmentUpdated(context, payload) {
      //find company
      let company = context.rootGetters.getCompanyById(payload.company_id);
      if (company) {
        //find the department of that company
        let department = company.departments.find(
          (department) => department._id == payload.department_id
        );
        //call mutation to change user department
        context.commit("changeUserDepartment", {
          company: company,
          companyUsers: company.users,
          department: department,
          payload,
        });
      }
    },

    /**
     * This action listen for socket whenever new department is created
     *
     * @param {Object} payload recieved from socket
     * @return {void}
     */
    socket_newDepartment(context, payload) {
      //find company
      let company = context.rootGetters.getCompanyById(payload.company_id);
      //find the department index already present in temp state
      let departmentIndex = company.departments.findIndex(
        (department) =>
          //check id or name return department
          department._id == payload.department._id ||
          department.name.toLowerCase() == payload.department.name.toLowerCase()
      );
      //call mutation to add new department
      context.commit("newDepartmentCreated", {
        company,
        departmentIndex,
        payload,
      });
    },

    /**
     * This action listen for socket department name changed
     *
     * @param {Object} payload recieved from socket
     * @return {void}
     */
    socket_departmentNameChanged(context, payload) {
      //find company
      let company = context.rootGetters.getCompanyById(payload.company_id);
      if (company) {
        //find department
        let department = company.departments.find(
          (department) => department._id == payload.department_id
        );
        //call mutation to change department name
        context.commit("departmentNameChanged", {
          company,
          department,
          payload,
        });
      }
    },

    /**
     * This action listen for socket when a department is archived
     *
     * @param {Object} payload recieved from socket
     * @return {void}
     */
    socket_departmentArchived(context, payload) {
      //find company
      let company = context.rootGetters.getCompanyById(payload.company_id);
      //call mutation to archive department
      context.commit("departmentDeleted", {
        company,
        payload,
      });
    },

    /**
     * This action listen for socket when a department is unarchived
     *
     * @param {Object} payload recieved from socket
     * @return {void}
     */
    socket_departmentUnarchived(context, payload) {
      //find company
      let company = context.rootGetters.getCompanyById(payload.company_id);
      //call mutation to unarchive department
      context.commit("departmentUnarchived", {
        company: company,
        payload,
      });
    },
  },

  mutations: {
    /**
     * This mutation update department name for all the users belong to that department
     *
     * @param {Object} payload recieved from action
     * @return {void}
     */
    departmentNameChanged: (state, payload) => {
      //if department exist
      if (payload.department)
        //assign new na,e
        payload.department.name = payload.payload.department;
      //loop through each user and update department name
      payload.payload.user_ids.forEach((userId) => {
        let user = payload.company.users.find(
          (companyUser) => companyUser._id == userId
        );
        if (user) user.department = payload.payload.department;
      });
    },

    /**
     * This mutation update user department
     *
     * @param {Object} payload recieved from action
     * @return {void}
     */
    changeUserDepartment: (state, payload) => {
      //loop through all user to find the user
      payload.payload.user_ids.forEach((userId) => {
        let user = payload.companyUsers.find((user) => user._id == userId);
        //if user find
        if (user) {
          user.department = payload.payload.department;
        }
        // this condition will run when user add in department
        //remove user id from other previous department
        if (payload.payload.member_add) {
          payload.company.departments.forEach((department) => {
            if (
              department._id != payload.payload.department_id &&
              department.user_ids.includes(userId)
            ) {
              let index = department.user_ids.findIndex((id) => id == userId);
              if (index > -1) department.user_ids.splice(index, 1);
            }
          });
          if (payload.department) {
            if (!payload.department.user_ids.includes(userId))
              payload.department.user_ids.push(userId);
          }
        }
        // this else will run when user removed from department
        else {
          if (payload.department) {
            let userIdIndex = payload.department.user_ids.findIndex(
              (id) => id == userId
            );
            if (userIdIndex > -1)
              payload.department.user_ids.splice(userIdIndex, 1);
          }
        }
      });
    },

    /**
     * This mutation add new department in the company
     *
     * @param {Object} payload recieved from action
     * @return {void}
     */
    newDepartmentCreated: (state, payload) => {
      //if company find
      if (payload.company) {
        //if company already exist
        if (payload.departmentIndex > -1) {
          // replace department object
          Object.assign(
            payload.company.departments[payload.departmentIndex],
            payload.payload.department
          );
        }
        // if not found push
        else {
          payload.company.departments.push(payload.payload.department);
        }
      }
    },

    /**
     * This mutation archive department in the company
     *
     * @param {Object} payload recieved from action
     * @return {void}
     */
    departmentDeleted: (state, payload) => {
      //if company find
      if (payload.company) {
        //find department index
        let departmentNameIndex = payload.company.departments.findIndex(
          (department) => department.name == payload.payload.department_name
        );
        //if find set archived date
        if (departmentNameIndex > -1) {
          payload.company.departments[
            departmentNameIndex
          ].archived = new Date().toLocaleString("sv-SE");
          payload.company.departments[departmentNameIndex].user_ids = [];
        }
        //set all user of that department to null
        payload.company.users.forEach((user) => {
          if (user.department == payload.payload.department_name) {
            user.department == null;
          }
        });
      }
    },

    /**
     * This mutation unarchive department in the company
     *
     * @param {Object} payload recieved from action
     * @return {void}
     */
    departmentUnarchived: (state, payload) => {
      //if company exist
      if (payload.company) {
        //find department index
        let departmentExistIndex = payload.company.departments.findIndex(
          (department) => department._id == payload.payload.department_id
        );
        let departmentNameIndex = payload.company.departments.findIndex(
          (department) => department.name == payload.payload.department_name
        );
        if (departmentExistIndex > -1) {
          payload.company.departments[departmentExistIndex].archived = null;
        } else if (departmentNameIndex > -1) {
          payload.company.departments[departmentNameIndex].archived = null;
        }
      }
    },
  },
};

export default departmentSocket;
