import axios from "axios";

import newMessageSound from "@/assets/notification/message_notification.mp3";

const state = {
  timeOutPolling: -1,
};
const actions = {
  channelsPolling(context) {
    //clear first previous timeout
    if (context.state.timeOutPolling > -1) {
      window.clearTimeout(context.state.timeOutPolling);
    }
    //if channel is selected
    let requestBody = {};
    if (context.rootState.selectedChannel) {
      requestBody.channel_id = context.rootState.selectedChannel._id;
    }
    //if reply drawer is open then send it's id to get all unread replies
    if (context.rootGetters.getParentReplyId) {
      requestBody.parent_id = context.rootGetters.getParentReplyId;
    }
    let token = JSON.parse(localStorage.getItem("token"));
    let customAxios = axios.create({
      headers: {
        Token: token,
      },
    });
    customAxios
      .post(
        process.env.VUE_APP_BASE_URL + "/polling/channelPolling",
        requestBody
      )
      .then(async (response) => {
        for (const channelDetail of response.data.data) {
          if (
            channelDetail.channel_id == context.rootState.selectedChannel?._id
          ) {
            for (const message of channelDetail.messages) {
              await context.dispatch("callSocketsActions", {
                name: "socket_newMessage",
                message,
              });
            }
            for (const message of channelDetail.thread_child_messages) {
              //if reply does not exist in thread_child_messages array then add reply to channel's array
              let reply = context.rootGetters.getSelectedChannel.thread_child_messages.find(
                (reply) => reply._id == message.data._id
              );
              if (!reply) {
                await context.dispatch("callSocketsActions", {
                  name: "socket_newReplyMessage",
                  message,
                });
              }
            }
          } else {
            let storeChannelDetail = await context.rootGetters.getMessagesDetail(
              channelDetail
            );
            if (storeChannelDetail?.channel) {
              if (
                storeChannelDetail.channel.new_message_count <
                  channelDetail.new_message_count ||
                storeChannelDetail.channel?.thread_child_messages?.length <
                  channelDetail?.thread_child_messages?.length
              ) {
                let messages = {};
                messages[channelDetail.channel_id] = undefined;
                //remove already visited channel messages
                context.commit("setMessages", {
                  messages,
                  channel_id: channelDetail.channel_id,
                });
                //count update new message
                context.commit("updateCountOfChannel", {
                  channel: storeChannelDetail.channel,
                  newData: channelDetail,
                });
              }
            }
          }
        }

        context.state.timeOutPolling = setTimeout(() => {
          context.dispatch("channelsPolling");
        }, 60000);
      })
      .catch((error) => {
        if (error.response && error.response.status == 401) {
          context.dispatch("logOut");
        } else {
          context.state.timeOutPolling = setTimeout(() => {
            context.dispatch("channelsPolling");
          }, 60000);
        }
      });
  },
  callSocketsActions(context, data) {
    return new Promise((resolve) => {
      setTimeout(() => {
        context.dispatch(data.name, data.message);
        resolve();
      }, 500);
    });
  },
};

const mutations = {
  clearSelectedChannelPollingState(state) {
    state.unReadThreads = [];
  },
  addUnThread(state, payload) {
    if (!state.unReadThreads.includes(payload)) {
      state.unReadThreads.push(payload);
    }
  },
  /**
   * this mutation is responsible for update new_message_count of channel
   * args: (state, payload)
   */
  updateCountOfChannel(state, payload) {
    const audio = new Audio(newMessageSound);
    audio
      .play()
      .then(() => {})
      .catch((err) => {});
    payload.channel.new_message_count = payload.newData.new_message_count;
    if (
      payload.channel.thread_child_messages.length <
      payload.newData.thread_child_messages.length
    ) {
      payload.channel.thread_child_messages = [];
      payload.newData.thread_child_messages.forEach((replyData) => {
        payload.channel.thread_child_messages.push(replyData.data);
      });
    }
    payload.channel.is_displayed = true;
  },
};

export default {
  state,
  mutations,
  actions,
};
