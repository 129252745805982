import requestQueue from "@/service/requestQueue";
const customAppsAPI = {
  /*
    send endpoint, token, requestBody and callback for done to read apps
    args:(requestBody, done)
    */
  readApps(requestBody, done) {
    requestQueue.post(
      "apps/read",
      JSON.parse(localStorage.getItem("token")),
      requestBody,
      done
    );
  },
  /*
    send endpoint, token, requestBody and callback for done to create new app
    args:(requestBody, done)
    */
  createNewApp(requestBody, done) {
    requestQueue.post(
      "apps/create",
      JSON.parse(localStorage.getItem("token")),
      requestBody,
      done
    );
  },
  /*
    send endpoint, token, requestBody and callback for done to update new app
    args:(requestBody, done)
    */
  updateApp(requestBody, done) {
    requestQueue.post(
      "apps/update",
      JSON.parse(localStorage.getItem("token")),
      requestBody,
      done
    );
  },
  /*
    send endpoint, token, requestBody and callback for done to delete app
    args:(requestBody, done)
    */
  removeApp(requestBody, done) {
    requestQueue.post(
      "apps/delete",
      JSON.parse(localStorage.getItem("token")),
      requestBody,
      done
    );
  },
};

export default customAppsAPI;
