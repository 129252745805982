import channelAPI from "@/api/channelAPI.js";
const actions = {
  /*
    this method fetch selected company archived channels from API
    args (company_id)
       */
  fetchChannels: (context, body) => {
    return new Promise((resolve, reject) => {
      var done = (response) => {
        if (response.status == 200) {
          resolve(response);
        } else {
          reject(response);
        }
      };
      channelAPI.fetchChannels(body, done);
    });
  },
  //Unarchive channel
  // This Action is Dispatched From ArchivedChannelsPannel File
  // Data to send { CompanyID, ChannelID, TeamID  }
  unArchiveChanel: (context, requestBody) => {
    let company = context.rootGetters.getSelectedCompany;
    return new Promise((resolve, reject) => {
      channelAPI.unarchiveChannel(
        requestBody.channel_id,
        requestBody.team_id,
        "null",
        (response) => {
          if (response.status == 200) {
            context.commit("UNARCHIVE_CHANEL", {
              company,
              channel: requestBody,
              newChannelData: response.data.channel,
            });
            resolve(response);
          } else {
            reject(response);
          }
        }
      );
    });
  },
  //Archive channel
  // This Action is Dispatched From ArchivedChannelsPannel File
  // Data to send {ChannelID, DeleteAt  }
  archiveThisChannel: (context, requestBody) => {
    return new Promise((resolve, reject) => {
      channelAPI.archiveChannelFromAdmin(
        requestBody,
        (response) => {
          if (response.status == 200) {
            resolve(response);
          } else {
            reject(response);
          }
        }
      );
    });
  },
};
const mutations = {
  //This will add channel to unarchive
  UNARCHIVE_CHANEL: (state, payload) => {
    // find channel
    let channelIndex = payload.company.channels.findIndex(
      (channel) => channel._id == payload.channel.channel_id
    );
    if (channelIndex > -1) {
      let team = payload.company.teams.find(
        (team) => team._id == payload.company.channels[channelIndex].team_id
      );
      //if admin/subadmin are member of unarchived channel then replace channel data with new channel data
      if (payload.newChannelData) {
        Object.assign(
          payload.company.channels[channelIndex],
          payload.newChannelData
        );
      }
      //if channel is not private (i.e. public or query)
      else if (
        team &&
        team.joined &&
        payload.company.channels[channelIndex].type == "public"
      ) {
        //set only archived key to null
        payload.company.channels[channelIndex].archived = null;
      } else {
        //if channel type is private then remove that channel
        payload.company.channels.splice(channelIndex, 1);
      }
    } else {
      payload.company.channels.push(payload.newChannelData);
    }
  },
};

export default {
  actions,
  mutations,
};
