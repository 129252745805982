/*
System: Whistle It
Developer: Jazib Nasir
Organization: Whistle It
Purpose: This file is used for making api calls for teams
*/
import requestQueue from "@/service/requestQueue";
import debug from "@/console";
var requestBody;
var requestEndPoint;
var token;

const departmentAPI = {
/*
this method fetch selected company departments from API
args (company_id)
*/
fetchDepartments: (requestBody, done) => {
        requestEndPoint = "adminPanel/getCompanyDepartments";
        token = JSON.parse(localStorage.getItem("token"));
        requestQueue.post(requestEndPoint, token, requestBody, done);
    },
 /*
this method fetch non members of specific department
args (department_id)
*/
fetchDepartmentNonMembers: (requestBody, done) => {
        requestEndPoint = "adminPanel/nonDepartmentCompanyUsers";
        token = JSON.parse(localStorage.getItem("token"));
        requestQueue.post(requestEndPoint, token, requestBody, done);
    },
/*
this method fetch  members of specific department
args (department_id)
*/
fetchDepartmentMembers: (requestBody, done) => {
        requestEndPoint = "adminPanel/departmentUsers";
        token = JSON.parse(localStorage.getItem("token"));
        requestQueue.post(requestEndPoint, token, requestBody, done);
    },
    /*
This function is responsible for making api call for add department
args:(name,company_id,profile_picture), callback function for done
*/
    createDepartment: (company_id, name, done) => {
        requestBody = {
            company_id,
            name,
        };
        requestEndPoint = "department/create";
        token = JSON.parse(localStorage.getItem("token"));
        requestQueue.post(requestEndPoint, token, requestBody, done);
    },
    /*
This function is responsible for making api call for add users in department
args:(department_ids[],users[]), callback function for done
*/
    addUsersInDepartment: (department_id, user_ids, company_id, done) => {
        requestBody = {
            department_id,
            user_ids: user_ids,
            company_id
        };
        requestEndPoint = "department/addMember";
        token = JSON.parse(localStorage.getItem("token"));
        requestQueue.post(requestEndPoint, token, requestBody, done);
    },

    /*
This function is responsible for making api call for remove users in department
args:(department_ids[],users[]), callback function for done
*/
    removeUsersFromDepartment: (department_id, user_ids, company_id, done) => {
        requestBody = {
            department_id,
            user_ids: user_ids,
            company_id
        };
        requestEndPoint = "department/removeMember";
        token = JSON.parse(localStorage.getItem("token"));
        requestQueue.post(requestEndPoint, token, requestBody, done);
    },
    /*
This function is responsible for making api call for update department
args:(department_name,team_id), callback function for done
*/
    updateDepartmentName: (company_id, name, department_id, done) => {
        requestBody = {
            department_id,
            name,
            company_id
        };
        requestEndPoint = "department/update";
        token = JSON.parse(localStorage.getItem("token"));
        requestQueue.post(requestEndPoint, token, requestBody, done);
    },
    /*
This function is responsible for making api call for update department
args:(department_id, company_id, department_icon), callback function for done
*/
    updateDepartmentIcon: (requestObj, done) => {
        requestBody = requestObj;
        requestEndPoint = "department/update";
        token = JSON.parse(localStorage.getItem("token"));
        requestQueue.post(requestEndPoint, token, requestBody, done);
    },
    /*
This function is responsible for making api call for delete department icon
args:(team_id, company_id), callback function for done
*/
    deleteDepartmentIcon: (department_id, profile_picture, done) => {
        requestBody = {
            department_id: department_id,
            profile_picture: profile_picture,
        };
        requestEndPoint = "department/update";
        token = JSON.parse(localStorage.getItem("token"));
        requestQueue.post(requestEndPoint, token, requestBody, done);
    },
    /*
This function is responsible for making api call for archive department
args:(company_id, department_id), callback function for done
*/
    archiveDepartment: async (company_id, department_id, deleted_at, done) => {
        requestBody = {
            company_id: company_id,
            department_id: department_id,
            deleted_at: deleted_at,
        };
        requestEndPoint = "department/update";
        token = JSON.parse(localStorage.getItem("token"));
        requestQueue.post(requestEndPoint, token, requestBody, done);
    },
    /*
This function is responsible for making api call for unarchive department
args:(company_id, team_id), callback function for done
*/
    unarchiveDepartment: (company_id, department_id, deleted_at, done) => {
        requestBody = {
            company_id: company_id,
            department_id: department_id,
            deleted_at: deleted_at,
        };
        requestEndPoint = "department/update";
        token = JSON.parse(localStorage.getItem("token"));
        requestQueue.post(requestEndPoint, token, requestBody, done);
    },
    /*
This function is responsible for making api call for leave team
args:(user_id[] users to leave, team_id), callback function for done
*/
    leaveTeam: (user_ids, team_id, done) => {
        requestBody = {
            user_ids,
            team_id,
        };
        requestEndPoint = "team/member/remove";
        token = JSON.parse(localStorage.getItem("token"));
        requestQueue.post(requestEndPoint, token, requestBody, done);
    },
    /*
    This function is responsible for making api call for change user department
    args:(), callback function for done
    */
    changeUserDepartment: (requestBody, done) => {

        requestEndPoint = "department/addMember";
        token = JSON.parse(localStorage.getItem("token"));
        requestQueue.post(requestEndPoint, token, requestBody, done);
    },
};

export default departmentAPI;