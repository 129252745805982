import channelAPI from "../../../api/channelAPI";
import broadcastChannel from "@/service/broadcastChannel.js";
const state = {
  unreadChannelsList: {
    direct: [],
    channels: [],
  },
  isHideFromTopUnreadChannel: false,
  isHideFromBottomUnreadChannel: false,
};
const getters = {
  getIsHideFromTopUnreadChannel: (state) => state.isHideFromTopUnreadChannel,
  getIsHideFromBottomUnreadChannel: (state) =>
    state.isHideFromBottomUnreadChannel,
  getUnreadChannelsList: (state) => state.unreadChannelsList,
};
const actions = {
  displayOnlyTenChannels(context) {
    //limit channels to 10
    context.commit("limitChannelsToTen", {
      joinedChannels: context.rootGetters.getSelTeamJoinedChannels,
      nonJoinedChannels: [],
    });
    //limit direct channels to 10
    context.commit("limitChannelsToTen", {
      joinedChannels: context.rootGetters.getDirectChannels,
      nonJoinedChannels: context.rootGetters.getNotDirectChannels,
    });
  },
  /**
   * maintain only five direct channels
   *
   * @param {Array} payload list of displayed channels
   */
  maintainOnlyLimitedDirectChannels(context, payload) {
    context.commit("MAINTAIN_ONLY_LIMITED_CHANNELS", {
      displayedChannels: payload,
      selectedChannelId: context.rootState.selectedChannel?._id,
      joinedChannels: context.rootGetters.getDirectChannels,
      nonJoinedChannels: context.rootGetters.getNotDirectChannels,
    });
  },
  /**
   * maintain only five other than direct channels
   *
   * @param {Array} payload list of displayed channels
   */
  maintainOnlyLimitedOtherChannels(context, payload) {
    context.commit("MAINTAIN_ONLY_LIMITED_CHANNELS", {
      displayedChannels: payload,
      selectedChannelId: context.rootState.selectedChannel?._id,
      joinedChannels: context.rootGetters.getSelTeamJoinedChannels,
      nonJoinedChannels: [],
    });
  },
  /**
   * maintain only five other than direct channels
   *
   * @param {Array} payload list of displayed channels
   */
  readChannels(context, payload) {
    return new Promise((resolve, reject) => {
      channelAPI.readChannls(payload, (res) => {
        if (res.status === 200) {
          resolve(res);
        } else {
          reject(res);
        }
      });
    });
  },

  openCompanyInNewTab({ state, rootGetters, commit, dispatch }, companyId) {
    // Send a message using the Broadcast Channel API to signal the intention to close an already open window.
    broadcastChannel.postMessage({
      type: "closeAlreadyOpenWindow",
      companyId,
    });
    let URL = window.location.origin + "/";
    // commit("setSelectedCompany", companyId);
    let findedCompany = rootGetters.getLoggedInUserDetail.companies.find(
      (company) => company._id == companyId
    );

    //set first joined team
    if (findedCompany.teams.filter((team) => team.joined).length > 0) {
      let selectedTeam = findedCompany.teams.find(
        (team) => team.joined == true && team.archived == null
      );

      //select first joined public channel
      let selectedChannel = findedCompany.channels.find(
        (channel) =>
          channel.joined == true &&
          channel.archived == null &&
          channel.team_id == selectedTeam._id
      );
      //set selected channel

      URL +=
        findedCompany._id + "/" + selectedTeam._id + "/" + selectedChannel._id;
    } else if (findedCompany.blocked) {
      URL += findedCompany._id + "/team_ip_blocked/channel_ip_blocked";
    } else {
      URL += findedCompany._id + "/no_team/no_channel";
    }

    window.open(URL, "_balnk" + Math.floor(Math.random() * 90000) + 10000);
  },
};

const mutations = {
  //show limited direct channels
  limitChannelsToTen(state, payload) {
    let maxChannels = 0;
    try {
      maxChannels =
        document.body.scrollWidth > 959
          ? Math.floor((document.body.scrollHeight - 100) / 2 / 32)
          : Math.floor((document.body.scrollHeight - 100) / 2 / 45);
    } catch (error) {
      console.log("error in document heigh", error);
      maxChannels = 6;
    }

    let channelCount = 0;
    //first find channel in which their is new message count or thread reply count to show in direct channels
    payload.joinedChannels.forEach((channel) => {
      if (
        !channel.muted &&
        (channel.flagged ||
          channel.new_message_count ||
          channel?.thread_child_messages?.length)
      ) {
        channel.is_displayed = true;
        ++channelCount;
      } else {
        channel.is_displayed = false;
      }
    });
    //if less than 10 channels then display latest chat channels
    if (channelCount < maxChannels) {
      payload.joinedChannels.sort((firstChannel, secondChannel) => {
        //if both channel have messages
        //if both channel have messages
        if (firstChannel.last_message_at && secondChannel.last_message_at) {
          if (
            firstChannel.last_message_at > secondChannel.last_message_at &&
            !firstChannel.muted
          ) {
            return -1;
          } else if (
            firstChannel.last_message_at < secondChannel.last_message_at &&
            !secondChannel.muted
          ) {
            return 1;
          }
        }
        //if first channel have messages then sort only first channel
        if (firstChannel.last_message_at && !firstChannel.muted) {
          return -1;
        }
        //if second channel have messages then sort only second channel
        if (secondChannel.last_message_at && !secondChannel.muted) {
          return 1;
        }
        return 0;
      });

      payload.joinedChannels.forEach((channel) => {
        if (
          !(
            !channel.muted &&
            (channel.flagged ||
              channel.new_message_count ||
              channel?.thread_child_messages?.length)
          )
        ) {
          if (channelCount < maxChannels) {
            channel.is_displayed = true;
            ++channelCount;
          } else {
            channel.is_displayed = false;
          }
        }
      });
      //after direct channels still there are not 10 channels then find in non direct channels
      if (channelCount < maxChannels) {
        payload.nonJoinedChannels.sort((firstChannel, secondChannel) => {
          return firstChannel.name.toLowerCase() >
            secondChannel.name.toLowerCase()
            ? 1
            : -1;
        });
        payload.nonJoinedChannels.forEach((channel) => {
          if (channelCount < maxChannels) {
            channel.is_displayed = true;
            ++channelCount;
          } else {
            channel.is_displayed = false;
          }
        });
      }
    }
  },
  //remove specific direct channel from Direct channels list
  removeChannelFromList(state, payload) {
    //first find in direct channels
    let channel = payload.joinedChannels.find(
      (channel) => channel._id == payload.channel._id
    );
    if (channel) {
      channel.is_displayed = false;
    } else {
      channel = payload.nonJoinedChannels.find(
        (channel) => channel._id == payload.channel._id
      );
      if (channel) {
        channel.is_displayed = false;
      }
    }
  },
  /**
   * maintain only five channels
   *
   */
  MAINTAIN_ONLY_LIMITED_CHANNELS(state, payload) {
    let lastChannel = null;
    //find last channel which is not selected channel or muted or contain new message or reply count
    for (
      let index = payload.displayedChannels.length - 1;
      index >= 0;
      index--
    ) {
      if (
        !(
          !payload.displayedChannels[index].muted &&
          (payload.displayedChannels[index]?.new_message_count ||
            payload.displayedChannels[index]?.thread_child_messages?.length)
        ) &&
        payload.displayedChannels[index]._id !== payload.selectedChannelId
      ) {
        lastChannel = payload.displayedChannels[index];
        break;
      }
    }
    if (lastChannel) {
      //find from joind channels
      let channel = payload.joinedChannels.find(
        (channel) => channel._id === lastChannel._id
      );
      if (channel) {
        channel.is_displayed = false;
      } else {
        //find from unjoind channels
        channel = payload.nonJoinedChannels.find(
          (channel) => channel._id === lastChannel._id
        );
        if (channel) {
          channel.is_displayed = false;
        }
      }
    }
  },
  UPDATE_TOP_UNREAD_CHANNELS_LIST(state, payload) {
    state.isHideFromTopUnreadChannel = payload;
  },
  UPDATE_BOTTOM_UNREAD_CHANNELS_LIST(state, payload) {
    state.isHideFromBottomUnreadChannel = payload;
  },
  UPDATE_UNREAD_DIRECT_CHANNELS_LIST(state, payload) {
    state.unreadChannelsList.direct = payload;
  },
  UPDATE_UNREAD_NOT_DIRECT_CHANNELS_LIST(state, payload) {
    state.unreadChannelsList.channels = payload;
  },
  ADD_CHANNEL_TO_UNREAD_LIST(state, payload) {
    if (
      !state.unreadChannelsList[payload.updateArray].includes(payload.channelId)
    ) {
      state.unreadChannelsList[payload.updateArray].unshift(payload.channelId);
    }
    let channelElement = document.getElementById(payload.channelId);
    if (channelElement) {
      // Get it's position in the viewport
      let bounding = channelElement.getBoundingClientRect();
      if (
        !(
          bounding.top >= 120 &&
          bounding.left >= 0 &&
          bounding.right <=
            (window.innerWidth || document.documentElement.clientWidth) &&
          bounding.bottom + 90 <=
            (window.innerHeight || document.documentElement.clientHeight)
        )
      ) {
        if (bounding.top - 120 < 0) {
          state.isHideFromTopUnreadChannel = true;
        } else {
          state.isHideFromBottomUnreadChannel = true;
        }
      }
    }
  },
};
export default {
  state,
  getters,
  actions,
  mutations,
};
