/**
 * System: Whistle It
 * Developer: Nabil Ahmad
 * Organization: Whistle It
 * Purpose: This file is for calling API for channel updations like select company, team and channel, create channel etc.
 */
import channelAPI from "@/api/channelAPI";
import messageAPI from "@/api/messageAPI";
import router from "@/router";
import debug from "@/console";
import Vue from "vue";
import userAPI from "@/api/userAPI";
import cloneDeep from 'lodash/cloneDeep';
const getters = {
  /**
   * this getter returns the ids of selected team users
   */
  getUsers: (state, getters) => getters.getSelectedChannel.users,
  /**
   * this getter return the detail of selected company
   */
  getSelectedCompany: (state, getters, rootState) =>
    rootState.storeData.companies.find((company) => {
      return company._id == rootState.selectedCompanyId;
    }),
  findUserRole: (state, getters) => (user_id) => {
    return getters.getSelectedCompany.users.find((item) => {
      return item.role_name == "admin" && item._id == user_id;
    });
  },
  /**
   * this getter return the role of selected company
   */
  getUserRole: (state, getters) => {
    return getters.getSelectedCompany.users.find((item) => {
      return item.role_name == "admin";
    });
  },
  /**
   * this getter return the detail of selected team
   */
  getSelectedTeam: (state, getters, rootState) =>
    getters.getSelectedCompany.teams.find((item) => {
      return item._id == rootState.selectedTeamId;
    }),
  /**
   * this getter return the detail of selected channel if there is any channel selected otherwise it returns null
   */
  getSelectedChannel: (state, getters, rootState, rootGetters) => {
    if (rootState.selectedChannel) {
      let channelDetail;
      if (
        rootState.selectedChannel.type == "direct" ||
        rootState.selectedChannel.type == "group"
      ) {
        channelDetail = getters.getSelectedCompany.direct.find((channel) => {
          if (channel._id == rootState.selectedChannel._id) {
            channel.is_displayed = true;
            return channel;
          }
        });
      } else {
        channelDetail = getters.getSelectedCompany.channels.find((channel) => {
          if (channel._id == rootState.selectedChannel._id) {
            channel.is_displayed = true;
            return channel;
          }
        });
      }
      return channelDetail ? channelDetail : null;
    } else {
      return null;
    }
  },
  /**
   * this getter return the detail of channel against the company_id and channel_id if there is any channel selected otherwise it returns null
   */
  getChannelByCompanyIdChannelId: (state, getters, rootState, rootGetters) => (
    company_id,
    channel_id
  ) => {
    let channelDetail = null;
    if (company_id && channel_id) {
      let companyDetail = getters.getCompanies.find(
        (company) => company._id == company_id
      );
      if (companyDetail) {
        channelDetail = companyDetail.channels.find(
          (channel) => channel._id == channel_id
        );
        if (!channelDetail) {
          channelDetail = companyDetail.direct.find(
            (channel) => channel._id == channel_id
          );
        }
      }
    }
    return channelDetail;
  },
  /**
   * this getter check if the selected channel is cloud or not
   */
  isNotCloudChannel: (state, getters, rootState) => {
    if (
      rootState.selectedChannel &&
      !getters.getSelectedChannel.invited &&
      !(
        getters.getSelectedChannel.guests &&
        getters.getSelectedChannel.guests.length
      )
    ) {
      return true;
    }
    return false;
  },
  /**
   * this getter check if the selected channel is guest or not
   */
  isNotGuestChannel: (state, getters, rootState) => {
    if (getters.getSelectedChannel?.host_data?.domain) {
      return false;
    }
    return true;
  },
  /**
   * this getter return the detail of channel by providing companyId, teamId, channelId and channel type
   */
  getChannel: (state, getters, rootState) => (cid, tid, id, type) =>
    rootState.storeData.companies
      .find((item) => {
        return item._id == cid;
      })
      .teams.find((item) => {
        return item._id == tid;
      })
      [type].find((item) => {
        return item._id == id;
      }),
  /**
   * this getter find id from team users ids and return that specific id
   */
  getUser: (state, getters) => (id) =>
    getters.getUsers.find((item) => {
      return item == id;
    }),
  /**
   * this getter returns direct channels of selected comapny
   */
  getDirectChannels: (state, getters) => {
    return getters.getSelectedCompany.direct;
  },
  /**
   * this getter returns joined channels of selected team
   */
  getSelTeamJoinedChannels: (state, getters, rootState, rootGetters) => {
    return getters.getSelectedCompany.channels.filter((channel) => {
      if (channel.type == "private" || channel.type == "public") {
        if (
          getters.getSelectedTeam &&
          channel.team_id == getters.getSelectedTeam?._id &&
          channel.joined &&
          channel.archived == null
        ) {
          return channel;
        }
      } else {
        if (
          channel.creator_id == rootGetters.loggedInUserDetails._id ||
          channel.is_company_specific ||
          channel?.users.includes(rootGetters.loggedInUserDetails._id) ||
          (channel?.followers.includes(rootGetters.loggedInUserDetails._id) &&
            channel?.team_ids.length == 0) ||
          channel?.team_ids.includes(getters.getSelectedTeam._id)
        ) {
          return channel;
        }
      }
    });
  },
  /**
   * this getter returns all channels of selected team
   */
  getSelTeamChannels: (state, getters, rootState, rootGetters) => {
    return getters.getSelectedCompany.channels.filter((channel) => {
      if (channel.type == "private" || channel.type == "public") {
        if (
          getters.getSelectedTeam &&
          channel.team_id == getters.getSelectedTeam._id &&
          channel.archived == null
        ) {
          return channel;
        }
      } else {
        if (
          channel.creator_id == rootGetters.loggedInUserDetails._id ||
          channel.is_company_specific ||
          channel.users.includes(rootGetters.loggedInUserDetails._id) ||
          (channel.followers.includes(rootGetters.loggedInUserDetails._id) &&
            channel.team_ids.length == 0) ||
          channel.team_ids.includes(getters.getSelectedTeam._id)
        ) {
          return channel;
        }
      }
    });
  },

  /**
   * this getter returns unjoined public channels of selected team
   */
  getSelCompAllJoinedChannels: (state, getters) => {
    return getters.getSelectedCompany.channels.filter(
      (channel) => channel.joined && channel.archived == null
    );
  },
  getCompanies: (state, getters, rootState) => rootState.storeData.companies,

  getTeams: (state, getters) => getters.getSelectedCompany.teams,
  getDepartments: (state, getters) => getters.getSelectedCompany.departments,
  /**
   * this getter returns the detail of team users from company users
   */
  getChannelUsersDetail: (state, getters) => {
    let channelUsersDetail = [];
    for (let userId of getters.getUsers) {
      for (const compUserId of getters.getSelectedCompany.users) {
        if (compUserId._id == userId) {
          channelUsersDetail.push(compUserId);
          break;
        }
      }
    }
    //find guest users details
    if (!getters.isNotCloudChannel) {
      for (const guestUser of getters.getSelCompGuestUsers) {
        if (
          guestUser.channel_id == getters.getSelectedChannel._id &&
          channelUsersDetail.findIndex((user) => user._id == guestUser._id) ==
            -1
        ) {
          channelUsersDetail.push(guestUser);
        }
      }
    }
    return channelUsersDetail;
  },
  getNonMembers: (state, getters, rootState, rootGetters) => {
    let nonMembers = [];
    for (const compUserId of getters.getSelectedCompany.users) {
      if (!getters.getSelectedChannel.users.includes(compUserId._id)) {
        for (const team of getters.getJoinedTeams) {
          if (
            team.users.includes(rootGetters.loggedInUserDetails._id) &&
            team.users.includes(compUserId._id)
          ) {
            nonMembers.push(compUserId);
            break;
          }
        }
      }
    }
    return nonMembers;
  },

  //get joined teams
  getJoinedTeams: (state, getters) => {
    return getters.getTeams.filter((team) => {
      return team.joined == true && team.archived == null;
    });
  },
  //get not direct channels users detail of all companies
  getAllCompanyNotDirectChannels: (state, getters, rootState, rootGetters) => {
    let notDirectChannels = [];
    let isFind = false;
    let isGuestFind = false;
    for (const company of getters.getCompanies) {
      for (const companyUser of company.users) {
        isFind = false;
        for (const directUser of company.direct) {
          if (directUser.users.length <= 2) {
            let directChannel = directUser.users.some(
              (item) => item == companyUser._id
            );
            if (directChannel == true) {
              isFind = true;
              break;
            }
          }
        }
        if (
          isFind == false &&
          companyUser._id != rootGetters.loggedInUserDetails._id &&
          companyUser.archived == null
        ) {
          for (const team of company.teams) {
            if (team.joined == true && team.archived == null) {
              if (
                team.users.includes(rootGetters.loggedInUserDetails._id) &&
                team.users.includes(companyUser._id)
              ) {
                let user = { ...companyUser };
                user.type = "direct";
                user.company_id = company._id;
                notDirectChannels.push(user);
                break;
              }
            }
          }
        }
      }
      isFind = false;
      //chck for logged in user self direct channel
      for (const directUser of company.direct) {
        if (
          directUser.creator_id == rootGetters.loggedInUserDetails._id &&
          directUser.users.length == 1
        ) {
          isFind = true;
          break;
        }
      }
      //if there is no direct channel of user with self then show in non direct channels
      if (isFind == false) {
        let user = {
          ...company.users.find(
            (user) => user._id == rootGetters.loggedInUserDetails._id
          ),
        };
        if (user.name) {
          user.type = "direct";
          user.company_id = company._id;
          notDirectChannels.push(user);
        }
      }
      //guest users
      if (company.guest_users && company.guest_users.length) {
        for (const guestUser of company.guest_users) {
          isGuestFind = false;
          for (const directUser of company.direct) {
            if (directUser.user_id == guestUser._id) {
              isGuestFind = true;
              break;
            }
          }
          if (isGuestFind === false) {
            notDirectChannels.push(guestUser);
          }
        }
      }
    }
    //get detail of users
    return notDirectChannels;
  },
  // This getter will get non direct channels of selected company
  getNotDirectChannels: (state, getters, rootState, rootGetters) => {
    let notDirectChannels = [];
    let isFind = false;
    for (const companyUser of getters.getSelectedCompany.users) {
      isFind = false;
      for (const directUser of getters.getDirectChannels) {
        if (directUser.users.length <= 2) {
          let directChannel = directUser.users.some(
            (item) => item == companyUser._id
          );
          if (directChannel == true) {
            isFind = true;
            break;
          }
        }
      }
      if (
        isFind == false &&
        companyUser._id != rootGetters.loggedInUserDetails._id &&
        companyUser.archived == null
      ) {
        for (const team of getters.getJoinedTeams) {
          if (
            team.users.includes(rootGetters.loggedInUserDetails._id) &&
            team.users.includes(companyUser._id)
          ) {
            notDirectChannels.push(companyUser);
            break;
          }
        }
      }
    }
    isFind = false;
    //chck for logged in user self direct channel
    for (const directUser of getters.getDirectChannels) {
      if (
        directUser.creator_id == rootGetters.loggedInUserDetails._id &&
        directUser.users.length == 1 &&
        !directUser.hosted &&
        !directUser.invited
      ) {
        isFind = true;
        break;
      }
    }
    //if there is no direct channel of user with self then show in non direct channels
    if (isFind == false) {
      notDirectChannels.push(
        getters.getSelectedCompany.users.find(
          (user) => user._id == rootGetters.loggedInUserDetails._id
        )
      );
    }

    //get detail of users
    return notDirectChannels;
  },
  // This getter will get non direct channels of selected company
  getNotGuestDirectChannels: (state, getters, rootState, rootGetters) => {
    let notDirectChannels = [];
    let isFind = false;
    if (
      getters.getSelectedCompany.guest_users &&
      getters.getSelectedCompany.guest_users.length
    ) {
      for (const guestUser of getters.getSelectedCompany.guest_users) {
        isFind = false;
        for (const directUser of getters.getDirectChannels) {
          if (directUser.user_id == guestUser._id) {
            isFind = true;
            break;
          }
        }
        if (isFind == false) {
          notDirectChannels.push(guestUser);
        }
      }
    }

    //get detail of users
    return notDirectChannels;
  },
  /**
   * this getter find the company guest users
   */
  getSelCompGuestUsers: (state, getters) => {
    return getters.getSelectedCompany.guest_users;
  },
  /**
   * this getter find the company id against team id
   * if company id is find it returns id
   * else it returns null
   */
  getCompanyId: (state, getters, rootState) => (teamID) => {
    for (let company of rootState.storeData.companies) {
      let teamObj = company.teams.find((item) => {
        return item._id == teamID;
      });
      if (teamObj) {
        return company._id;
      }
    }
    return null;
  },
  /**
   * this getter returns the channel id, channel type, team id and copmany id against channel id.
   * args: (context, data)
   */
  getChannelDetails: (state, getters, rootState) => (id) => {
    for (const company of rootState.storeData.companies) {
      let company_id = company.teams.find((item) => {
        return item._id == id;
      });
      if (company_id) {
        return company._id;
      }
    }
  },
  /**
   * this getter returns the online users of selected company
   */
  getOnlineUsers: (state, getters) =>
    getters.getSelectedCompany.users.filter((user) => user.status == true),
  /**
   * this getter returns the all direct or non direct channels of selected company
   */
  getCompanyDirectChannels: (state, getters) => {
    let directChannels = cloneDeep(getters.getNotDirectChannels);
    directChannels.forEach((channel) => (channel.type = "direct"));
    //add guest users
    directChannels = directChannels.concat(
      cloneDeep(getters.getNotGuestDirectChannels)
    );
    directChannels = directChannels.concat(
      cloneDeep(getters.getDirectChannels)
    );

    return directChannels;
  },
  /**
   * this getter returns the all channels of selected company
   */
  allCompanyChannels: (state, getters, rootState, rootGetters) => {
    const {
      getAllCompanies,
      getNonSelectedTeams,
      getSelectedCompany,
      getAllCompanyNotDirectChannels,
      getAllCompaniesChannels
    } = getters;
  
    return [
      ...getAllCompanies,
      ...getNonSelectedTeams,
      ...getSelectedCompany.channels,
      ...getAllCompanyNotDirectChannels,
      ...getAllCompaniesChannels
    ];
  },
  /**
   * this getter returns new message count of direct channels
   */
  getSelCompanyDirectChannelsMsgCount: (state, getters) => {
    let newMessageCount = 0;
    getters.getSelectedCompany.direct.forEach((channel) => {
      if (
        (channel.new_message_count || channel.thread_child_messages.length) &&
        channel.archived == null
      ) {
        newMessageCount +=
          channel.new_message_count + channel.thread_child_messages.length;
      }
    });
    return newMessageCount;
  },
  /**
   * this getter returns new message count of public channels
   */
  getSelTeamChannelsMsgCount: (state, getters) => {
    let newMessageCount = 0;
    getters.getSelTeamJoinedChannels.forEach((channel) => {
      if (
        (channel.new_message_count || channel.thread_child_messages.length) &&
        !channel.muted
      ) {
        newMessageCount +=
          channel.new_message_count + channel.thread_child_messages.length;
      }
    });
    return newMessageCount;
  },
  /**
   * this getter returns all companies except selected Company
   */
  getAllCompanies: (state, getters, rootState, rootGetters) => {
    let companies = [];
    getters.getCompanies.forEach((company) => {
      if (getters.getSelectedCompany._id != company._id) {
        companies.push({
          _id: company._id,
          name: company.name,
          new_message_count: 0,
          thread_child_messages: rootGetters.getMsgsCountCompany(company._id),
          users: [],
          joined: true,
          muted: false,
          type: "workspace",
        });
      }
    });
    return companies;
  },
  getAllCompaniesChannels: (state, getters, rootState, rootGetters) => {
    let channels = [];
    getters.getCompanies.forEach((company) => {
      let directChannels = JSON.parse(JSON.stringify(company.direct));
      let userDetail = null;
      directChannels.forEach((directCh) => {
        userDetail = null;
        if (
          directCh?.users?.length > 1 &&
          directCh.type === "direct" &&
          !(directCh.invited || directCh.hosted)
        ) {
          userDetail = company.users.find(
            (user) =>
              user._id ==
              directCh.users.filter(
                (userId) => userId != rootGetters.loggedInUserDetails._id
              )[0]
          );
        } else if (
          directCh?.users?.length === 1 &&
          directCh.type === "direct" &&
          directCh?.users[0] === rootGetters.loggedInUserDetails._id &&
          !(directCh.invited || directCh.hosted)
        ) {
          userDetail = company.users.find(
            (user) => user._id == rootGetters.loggedInUserDetails._id
          );
        }
        if (userDetail) {
          directCh.designation = userDetail.designation;
          directCh.alias = userDetail.alias;
        }
      });
      channels = channels.concat(directChannels);
      channels = channels.concat(
        company.channels.filter((channel) => {
          return (
            channel.type != "support" &&
            channel.company_id != rootState.selectedCompanyId
          );
        })
      );
    });
    return channels;
  },
  getAllCompaniesTeams: (state, getters) => {
    let teams = [];
    getters.getCompanies.forEach((company) => {
      teams = teams.concat(company.teams);
    });
    return teams;
  },
  //return selected channel name
  getSelectedChannelName: (state, getters) => {
    return getters.getSelectedChannel.name;
  },
  //return selected channel description
  getSelectedChannelDescription: (state, getters) => {
    return getters.getSelectedChannel.description;
  },
  /**
   * this getter returns non selected teams of selected Company
   */
  getNonSelectedTeams: (state, getters, rootState, rootGetters) => {
    let nonSelectedTeams = [];
    getters.getCompanies.forEach((company) => {
      // if (getters.getSelectedCompany._id != company._id) {
      company.teams.forEach((team) => {
        if (
          getters.getSelectedTeam &&
          getters.getSelectedTeam._id != team._id
        ) {
          team.type = "team";
          team.thread_child_messages = rootGetters.getMsgsCountTeam(team._id);
          team.new_message_count = 0;
          nonSelectedTeams.push(team);
        }
      });
    });
    return nonSelectedTeams;
  },
};

const actions = {
  /**
   * This action is responsible for clearing previous states by shifting to new company, team and channel
   * args:(dispatch)
   */
  resetStates({ commit, dispatch }) {
    //check messages if they are more than 20 message then retain first 20 and remove others
    dispatch("checkAndRmvMsgs");
    //clear search message histroy on either company, team or channel change
    commit("setJumpMessage", null);
    //reset pagination var which is used to show channel meta data
    commit("setPaginationRes", false);
    //reset new message response
    commit("setIsAnyNewMessage", false);
    //for previous messages searching
    commit("setPreMsgPaginateRes", false);
    //for next messages searching
    commit("setNxtMsgPaginateRes", false);
  },
  // COMPANIES

  // SELECT
  async selectCompany(
    { rootState, getters, commit, dispatch, rootGetters },
    companyId
  ) {
    //reset unread channels buttons state
    commit("UPDATE_TOP_UNREAD_CHANNELS_LIST", false);
    commit("UPDATE_BOTTOM_UNREAD_CHANNELS_LIST", false);
    let team_id;
    //clear previous states
    dispatch("resetStates");
    //set provided company id as a selected company
    commit("setSelectedCompany", companyId);
    //set first joined team
    if (rootGetters.getJoinedTeams.length > 0) {
      commit(
        "setSelectedTeam",
        getters.getSelectedCompany.teams.find(
          (team) => team.joined == true && team.archived == null
        )._id
      );
      //limit channels to 10
      dispatch("displayOnlyTenChannels");
      //select first joined public channel
      let selectedChannel = getters.getSelectedCompany.channels.find(
        (channel) =>
          channel.joined == true &&
          channel.archived == null &&
          channel.team_id == rootState.selectedTeamId
      );
      //set selected channel
      commit("setSelectedChannel", {
        _id: selectedChannel._id,
        type: selectedChannel.type,
      });

      dispatch("changeHomeRoute");
    } else if (getters.getSelectedCompany.blocked) {
      router.push({
        name: "home",
        params: {
          companyId: getters.getSelectedCompany._id,
          teamId: "team_ip_blocked",
          channelId: "channel_ip_blocked",
        },
      });
    } else {
      router.push({
        name: "home",
        params: {
          companyId: getters.getSelectedCompany._id,
          teamId: "no_team",
          channelId: "no_channel",
        },
      });
    }
    //setting route according to selected company, team and channel

    //if there is new_message_count then make api call and remove new_message_count
    dispatch("clearMessageRead");
    //if there is mark as unread set then make api call and set it to false
    if (
      getters.getSelectedChannel &&
      getters.getSelectedChannel.unread_channel
    ) {
      dispatch("updateMarkAsUnread", {
        channel: getters.getSelectedChannel,
        company: getters.getSelectedCompany,
      });
    }
  },

  // TEAMS

  // SELECT
  async selectTeam({ commit, getters, dispatch, rootGetters }, teamId) {
    //reset unread channels buttons state
    commit("UPDATE_TOP_UNREAD_CHANNELS_LIST", false);
    commit("UPDATE_BOTTOM_UNREAD_CHANNELS_LIST", false);
    //clear previous states
    dispatch("resetStates");
    //set selected team id
    commit("setSelectedTeam", teamId);
    let selectedChannel,
      teamIndex = -1;
    //traverse previous selected teams states
    for (
      let index = 0;
      index < rootGetters.getPrevTeamsSelectChannel.length;
      index++
    ) {
      if (rootGetters.getPrevTeamsSelectChannel[index].teamId == teamId) {
        selectedChannel = rootGetters.getPrevTeamsSelectChannel[index].channel;
        teamIndex = index;
        break;
      }
    }
    //if team is find then remove previous selected team and its channel state from prevTeamsSelectChannel array
    if (teamIndex != -1) {
      rootGetters.getPrevTeamsSelectChannel.splice(teamIndex, 1);
    }
    //if team is not find then select general channel i.e. announcements
    else {
      //select first joined public channel
      selectedChannel = getters.getSelectedCompany.channels.find(
        (channel) =>
          channel.joined == true &&
          channel.archived == null &&
          channel.team_id == teamId
      );
    }
    //limit channels to 10
    dispatch("displayOnlyTenChannels");
    //set selected channel
    commit("setSelectedChannel", {
      _id: selectedChannel._id,
      type: selectedChannel.type,
    });
    //setting route according to selected team and channel
    dispatch("changeHomeRoute");
    //if there is new_message_count then make api call and remove new_message_count
    dispatch("clearMessageRead");
    //if there is mark as unread set then make api call and set it to false
    if (
      getters.getSelectedChannel &&
      getters.getSelectedChannel.unread_channel
    ) {
      dispatch("updateMarkAsUnread", {
        channel: getters.getSelectedChannel,
        company: getters.getSelectedCompany,
      });
    }
  },

  // CHANNELS

  // CHANNELS -- CREATE
  createGroupChannel({ commit, dispatch, rootGetters }, body) {
    return new Promise((resolve, reject) => {
      commit("addChannel", body);
      //  Response callback
      var done = (response) => {
        if (response.status == 201) {
          if (response.data.type == "direct") {
            let currentUser = rootGetters.getSelectedCompany.users.find(
              (user) => user._id == response.data.user_id
            );
            response.data.online = currentUser ? currentUser.online : true;
          }

          commit("addChannelResponse", {
            company: body.company,
            tempId: body.channel._id,
            newData: response.data,
            type: response.data.type,
          });
          //check if user is in same company and team after channel creation then select new created channel
          if (
            body.company._id == rootGetters.getSelectedCompany._id &&
            body.team_id == rootGetters.getSelectedTeam._id
          ) {
            if (response.data.type == "group") {
              commit("setSelectedChannel", {
                _id: response.data._id,
                type: "direct",
              });
            } else {
              commit("setSelectedChannel", {
                _id: response.data._id,
                type: response.data.type,
              });
            }
          }
          //setting route according to new channel
          dispatch("changeHomeRoute");
          resolve(response);
        } else {
          commit("addChannelFailure", {
            channel: body.channel,
            company: body.company,
          });
          reject(response);
        }
      };
      // Calls API with done
      channelAPI.createChannel(
        body.channel.company_id, //for direct channel
        body.channel.team_id, //for public and private channels
        body.channel.name,
        body.channel.type,
        body.channel.description,
        body.channel.direct_channel_id, //for direct channel
        body.is_group ? body.channel.users : undefined,
        body.channel.secret_key_url,
        body.guest_email,
        body.domain,
        body.guest_company_id,
        done
      );
    });
  },
  //check and remove more than 20 messages
  checkAndRmvMsgs({ commit, rootState, getters, rootGetters }) {
    let firstTempMsgIndex = 0;
    //check if messages array is greater than 20, keep first 20 messages and remove remaining
    if (
      rootState.selectedChannel != null &&
      rootState.selectedChannel &&
      rootGetters.getCurrentMessages.length > 20
    ) {
      //check for messages which are in pending state
      if (
        !rootGetters.getCurrentMessages.some((message) =>
          message._id.includes("Temp")
        )
      ) {
        //if there is no message in pending state then keep only first 20
        commit("sliceMessages", {
          messages: rootState.messages,
          channelId: rootState.selectedChannel._id,
        });
      }
      //if any message is in pending state then keep messages from first pending state to zero
      else {
        //traverse messages array in reverse order because we push new message in front of array
        //find first pending message
        for (
          let index = rootGetters.getCurrentMessages.length - 1;
          index >= 0;
          index--
        ) {
          //check for first pending message state and index is greater than 19
          if (
            rootGetters.getCurrentMessages[index]._id.includes("Temp") &&
            index >= 20
          ) {
            firstTempMsgIndex = index;
            break;
          }
        }
        if (firstTempMsgIndex) {
          commit("sliceMessageForSpecificLength", {
            messages: rootState.messages,
            channelId: rootState.selectedChannel._id,
            firstTempMsgIndex: firstTempMsgIndex + 1,
          });
        } else {
          commit("sliceMessages", {
            messages: rootState.messages,
            channelId: rootState.selectedChannel._id,
          });
        }
      }
    }
  },

  // CHANNELS -- READ
  // Select channel
  async selectChannel({ commit, dispatch, getters }, payload) {
    //reset previous selected channel states
    dispatch("resetStates");
    //set selected channel
    commit("setSelectedChannel", payload);
    //setting route according to selected channel
    dispatch("changeHomeRoute");
    //if there is new_message_count then make api call and remove new_message_count
    dispatch("clearMessageRead");
    //if there is mark as unread set then make api call and set it to false
    if (
      getters.getSelectedChannel &&
      getters.getSelectedChannel.unread_channel
    ) {
      dispatch("updateMarkAsUnread", {
        channel: getters.getSelectedChannel,
        company: getters.getSelectedCompany,
      });
    }
  },
  /**
   * This action is responsible for setting route according to selected company, team and channel
   * args:(getters)
   */
  changeHomeRoute({ getters, dispatch, rootState }) {
    if (getters.getJoinedTeams.length > 0) {
      try {
        if (
          getters.getSelectedCompany.blocked == false &&
          getters.getSelectedTeam.joined &&
          getters.getSelectedTeam.archived == null &&
          (rootState.selectedChannel.type == "support" ||
            getters.getSelectedChannel.joined) &&
          getters.getSelectedChannel.archived == null
        ) {
          router
            .push({
              name: "home",
              params: {
                companyId: getters.getSelectedCompany._id,
                teamId: getters.getSelectedTeam._id,
                channelId: rootState.selectedChannel._id,
              },
            })
            .catch(() => {});
        } else if (getters.getCompanies.length) {
          dispatch(
            "selectCompany",
            getters.getCompanies.find((company) => company.blocked == false)._id
          );
        }
      } catch (error) {
        debug.log("error::", error);
        if (getters.getCompanies.length) {
          dispatch(
            "selectCompany",
            getters.getCompanies.find((company) => company.blocked == false)._id
          );
        }
      }
    } else if (getters.getSelectedCompany.blocked) {
      router.push({
        name: "home",
        params: {
          companyId: getters.getSelectedCompany._id,
          teamId: "team_ip_blocked",
          channelId: "channel_ip_blocked",
        },
      });
    } else {
      router.push({
        name: "home",
        params: {
          companyId: getters.getSelectedCompany._id,
          teamId: "no_team",
          channelId: "no_channel",
        },
      });
    }
  },
  markAsRead({ commit, getters, rootGetters }, channel) {
    var done = (response) => {
      if (response.status == 200) {
        //change new_message_count of channel
        commit("MARK_CHANNEL_AS_READ", {
          channel,
          company: getters.getSelectedCompany,
        });
      }
    };
    messageAPI.markMessageRead(channel.company_id, channel._id, done);
  },

  /**
   * This action is responsible for clearing messages count of selected channel and call api
   * args:(context, data)
   */
  clearMessageRead({ getters, commit, rootGetters, rootState }) {
    if (
      getters.getSelectedChannel &&
      getters.getSelectedChannel.new_message_count > 0 &&
      !rootGetters.getIsUnreadMessage
    ) {
      // let channel = getters.getSelectedChannel;
      var done = () => {
        // if (response.status == 200) {
        //   //change new_message_count of channel
        //   context.commit("updateMessageRead", {
        //     channel,
        //   });
        // }
      };
      //if new message count is greater than zero then make api call

      messageAPI.updateMessage(
        undefined,
        rootState.selectedChannel._id,
        undefined,
        undefined,
        undefined,
        1,
        undefined,
        undefined,
        undefined,
        getters.getSelectedChannel.invited,
        getters.getSelectedChannel.secret_key,
        undefined,
        done
      );
      //change new_message_count of channel
      commit("updateMessageRead", {
        channel: getters.getSelectedChannel,
      });
    }
  },
  /**
   * This action is responsible to update mark as read of selected channel and call api
   * args:(getters, channel)
   */
  updateMarkAsUnread({ commit, rootGetters }, payload) {
    return new Promise((resolve, reject) => {
      let updatedStatusValue = !payload.channel.unread_channel;
      let done = (response) => {
        if (response.status == 200) {
          commit("updateMuteFavUnReadStatus", {
            ...payload,
            changeKey: "unread_channel",
            updatedStatusValue,
          });
          resolve();
        } else {
          reject();
        }
      };
      //if new message count is greater than zero then make api call
      // if (payload.channel.unread_channel) {
      userAPI.updateMarkAsUnread(
        {
          channel_id: payload.channel._id,
          unread_channel: !payload.channel.unread_channel,
        },
        done
      );
      // }
    });
  },
};
const mutations = {
  sliceMessages(state, payload) {
    if (payload.messages && payload.messages[payload.channelId]) {
      Vue.set(
        payload.messages,
        payload.channelId,
        payload.messages[payload.channelId].slice(0, 20)
      );
    }
  },
  sliceMessageForSpecificLength(state, payload) {
    if (payload.messages && payload.messages[payload.channelId]) {
      Vue.set(
        payload.messages,
        payload.channelId,
        payload.messages[payload.channelId].slice(0, payload.firstTempMsgIndex)
      );
    }
  },
  /**
   * this mutation will mark read all channels of selected company
   * args:((state, payload))
   */
  MARK_CHANNEL_AS_READ(state, payload) {
    let findedChannel = null;
    if (payload.channel.type == "direct" || payload.channel.type == "group") {
      findedChannel = payload.company.direct.find(
        (channel) => channel._id == payload.channel._id
      );
    } else {
      findedChannel = payload.company.channels.find(
        (channel) => channel._id == payload.channel._id
      );
    }
    if (findedChannel) {
      findedChannel.new_message_count = 0;
      findedChannel.thread_child_messages = [];
    }
  }, 
};

export default {
  getters,
  mutations,
  actions,
};
