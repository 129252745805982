/**
System: Whistle It
Developer: Menahil Tanveer
Organization: Whistle It
Purpose: This file is responsible for handling all the store functionality for payments.
 */
import paymentAPI from "@/api/paymentAPI";
import debug from "@/console";

/**
 * this function returns the data object that will pass to socket.
 * args : (payload)
 */
var PaymentStore = {

    getters: {
        getAutoChargeStatus: (state) => state.autoChargeStatus,
        getFreeTrialStatus: (state) => state.isFreeTrial,
        getBillingDetails: (state) => state.billingDetails,
        getPaymentMethods: (state) => state.paymentMethods,
        getPaymentHistory: (state) => state.paymentHistory,
        getNotificationStatus: (state) => state.notification,
    },
    actions: {
        /**
         * This function is responsible for fetching the payment plan 
         * of company, including saved payment methods, auto-renewal status, and billing
         * details
         * args :(requestBody) 
         */
        fetchPaymentDetails: ({ context, commit }, requestBody) => {
            return new Promise((resolve, reject) => {
                var done = (response) => {
                    if (response.status == 200) {
                        commit("SET_AUTO_CHARGE_STATUS", response.data.stripe_subscription);
                        commit("SET_PAYMENT_METHODS", response.data.cards);
                        commit("SET_FREE_TRIAL_STATUS", response.data.stripe_free_trial);
                        commit("SET_PAYMENT_HISTORY", response.data.payments);
                        let billing_details = {
                            formula: response.data.formula,
                            number_of_active_users: response.data.number_of_active_users,
                            payment_due_date: response.data.stripe_due_date,
                            total_payment: response.data.total_amount_to_pay,
                        }
                        commit("SET_BILLING_DETAILS", billing_details);
                        resolve(response);
                    }
                    else {
                        reject(response);
                    }
                };
                paymentAPI.fetchPaymentDetails(requestBody, done);
            });
        },
        /**
         * This function is responsible for adding a new payment method
         * args :(requestBody) 
         */
        addNewPaymentCard: (context, requestBody) => {
            return new Promise((resolve, reject) => {
                var done = (response) => {
                    if (response.status == 200) {
                        context.commit("ADD_PAYMENT_METHOD", response.data.card_details)
                        resolve(response);
                    }
                    else {
                        reject(response)
                    }
                };
                paymentAPI.addPaymentCard(requestBody, done);
            });
        },
        /**
         * This function is responsible for auto-renewal of payment
         * args :(requestBody) 
         */
        updateAutoRenewalSettings: (context, requestBody) => {
            return new Promise((resolve, reject) => {
                var done = (response) => {
                    if (response.status == 200) {
                        resolve(response);
                    }
                    else {
                        reject(response)
                    }
                };
                paymentAPI.updateAutoRenewalSettings(requestBody, done);
            });
        },
        /**
         * This function is responsible for deletion of saved payment methods
         * args :(requestBody) 
         */
        deletePaymentCard: (context, requestBody) => {
            return new Promise((resolve, reject) => {
                var done = (response) => {
                    if (response.status == 200) {
                        context.commit("DELETE_PAYMENT_METHOD", requestBody);
                        resolve(response);
                    }
                    else {
                        reject(response)
                    }
                };
                paymentAPI.deletePaymentCard(requestBody, done);
            });
        },
        /**
         * This function is responsible for setting a default payment method
         * args :(requestBody) 
         */
        updateDefaultPaymentMethod: (context, requestBody) => {
            return new Promise((resolve, reject) => {
                var done = (response) => {
                    if (response.status == 200) {
                        context.commit("UPDATE_DEFAULT_PAYMENT_METHOD", requestBody);
                        resolve(response);
                    }
                    else {
                        reject(response)
                    }
                };
                paymentAPI.updateDefaultPaymentCard(requestBody, done);
            });
        },
        /**
         * This function is responsible for charging payment
         * args :(requestBody) 
         */
        chargePayment: (context, requestBody) => {
            return new Promise((resolve, reject) => {
                var done = (response) => {
                    if (response.status == 200) {
                        resolve(response);
                    }
                    else {
                        reject(response)
                    }
                };
                paymentAPI.chargePayment(requestBody.company_id, done);
            })
        },
        /**
         * This function is responsible for hiding the bell icon once the user has seen the
         * free trial OR payment due notification
         * args :(requestBody) 
         */
        hideNotificationIcon: (context, requestBody) => {
            return new Promise((resolve, reject) => {
                var done = (response) => {
                    if (response.status == 200) {
                        resolve(response);
                    }
                    else {
                        reject(response)
                    }
                };
                paymentAPI.hideNotificationIcon(requestBody, done);
            })
        },
    },
    mutations: {
        SET_AUTO_CHARGE_STATUS(state, payload) {
            state.autoChargeStatus = payload;
        },
        SET_PAYMENT_METHODS(state, payload) {
            state.paymentMethods = payload;
        },
        ADD_PAYMENT_METHOD(state, payload) {
            state.paymentMethods.unshift(payload);
        },
        SET_FREE_TRIAL_STATUS(state, payload) {
            state.isFreeTrial = payload;
        },
        SET_BILLING_DETAILS(state, payload) {
            state.billingDetails = payload;
        },
        UPDATE_DEFAULT_PAYMENT_METHOD(state, payload) {
            state.paymentMethods.forEach(item => {
                payload.new_default_card == item.card_id ? item.is_default = true : item.is_default = false
            });
        },
        DELETE_PAYMENT_METHOD(state, payload) {
            state.paymentMethods = state.paymentMethods.filter((item) => {
                return payload.customer_card_id != item.card_id;
            })
        },
        SET_PAYMENT_HISTORY(state, payload) {
            state.paymentHistory = payload;
        },
        SET_NOTIFICATION_STATUS(state, payload){
            state.notification = payload;
        }
    },
    state: {
        paymentMethods: [],
        autoChargeStatus: null,
        isFreeTrial: null,
        billingDetails: null,
        paymentHistory: null,
        notification: "show",
    },
};

export default PaymentStore;
