import pluginAPI from "@/api/pluginAPI.js";
let state = {};
let getters = {
  getUserPlugins: (getters, rootGetters) =>
    rootGetters.getLoggedInUserDetail.plugins,
};
let actions = {
  /*
    this method add plugin to user
    args ()
       */
  addPlugin: (context, body) => {
    return new Promise((resolve, reject) => {
      var done = (response) => {
        if (response.status == 200) {
          resolve(response);
          let payload = {
            userPlugins : context.getters.getUserPlugins,
            pluginId: body.plugin_id,
            plugin:response.data
          }
          context.commit("addPlugin",payload)
        } else {
          reject(response);
        }
      };
      pluginAPI.addPlugin(body, done);
    });
  },
  /*
    this method remove plugin from user
    args ()
       */
  removePlugin: (context, body) => {
    return new Promise((resolve, reject) => {
      var done = (response) => {
        if (response.status == 200) {
          resolve(response);
          let payload = {
            userPlugins : context.getters.getUserPlugins,
            pluginId: body.plugin_id
          }
          context.commit("removePlugin",payload)
        } else {
          reject(response);
        }
      };
      pluginAPI.removePlugin(body, done);
    });
  },
  /*
    this method read all plugin of user 
    args ()
       */
  readPlugin: (context, body) => {
    return new Promise((resolve, reject) => {
      var done = (response) => {
        if (response.status == 200) {
          resolve(response);
        } else {
          reject(response);
        }
      };
      pluginAPI.readPlugin(done);
    });
  },
  /*
    this method create plugin link for user
    this method works for both simple and reply message
    If body includes 'replying_id' then we treat this method for reply message (vice versa)
    this methods returns two possible values:
      1: Join URL (if user authenticated from zoom then 'JOIN URL' key will return and we create message body with that
         link and send to backend)
      2: Without Join Url key : Simply pushed that message in local store.   
    args (
      shared_company_ids,
      shared_team_ids,
      shared_user_ids,
      announcement,
      support_message
    )
    Note: Above args only be recieved when user create link from support channel
       */
  createPluginLink: (context, body) => {
    return new Promise((resolve, reject) => {
      var done = (response) => {
        if (response.status == 200) {
          resolve(response);
          // if body includes 'replying_id' key then push plugin message response to reply message store
          if ("replying_id" in body) {
            let previousMessages = context.rootGetters.getReplyedMessages.child;
            if ("join_url" in response.data) {
              let randNumberFront = Math.floor(Math.random() * 90000) + 10000;
              let randNumberRear = Math.floor(Math.random() * 90000) + 10000;
               body = {
                _id: `Temp + ${randNumberFront}${Date.now()}${randNumberRear}`,
                sender_id: context.rootGetters.loggedInUserDetails._id,
                zoom:{
                  name:response.data.name,
                  meeting_id:response.data.meeting_id,
                  join_url:response.data.join_url,
                  picture:response.data.picture,
                  password:response.data.password
                },
                is_forwarded: 0,
                is_edited: 0,
                is_read: [],
                is_joined: 0,
                deleted_at: null,
                updated_at: null,
                delete_after: null,
                user_time: new Date().toUTCString(),
                created_at: new Date().toUTCString(),
                pinned_by: [],
                replied_ids: [],
                replying_id: context.rootGetters.getParentReplyId,
                replying_message:body.replying_message,
                replied_sender_id: context.rootGetters.loggedInUserDetails._id,
                replied_created_at: new Date().toUTCString(),
                reactions: [],
                reminded_by: [],
                child_read : [],
              };
              context.dispatch("replyMessage", body);
            } 
            else
            {
            //add new message in state
            context.commit("addNewReplyMessage", {
              currentMessages: previousMessages,
              message: response.data,
            });
          }
          }
          // else body do not includes replying_id key then push plugin message response to main message store
          else {
            let previousMessages = context.rootGetters.getCurrentMessages;
            //add new message in state
            if ("join_url" in response.data) {
              let randNumberFront = Math.floor(Math.random() * 90000) + 10000;
              let randNumberRear = Math.floor(Math.random() * 90000) + 10000;
              let payload = {
                _id: `Temp + ${randNumberFront}${Date.now()}${randNumberRear}`,
                sender_id: context.rootGetters.loggedInUserDetails._id,
                zoom:{
                  name: response.data.name,
                  meeting_id: response.data.meeting_id,
                  join_url: response.data.join_url,
                  picture: response.data.picture,
                  password:response.data.password
                },
                is_forwarded: 0,
                is_edited: 0,
                is_read: [],
                is_joined: 0,
                deleted_at: null,
                updated_at: null,
                delete_after: null,
                user_time: new Date().toUTCString(),
                created_at: new Date().toUTCString(),
                pinned_by: [],
                replied_ids: [],
                replying_id: null,
                replied_sender_id: context.rootGetters.loggedInUserDetails._id,
                replied_created_at: new Date().toUTCString(),
                reactions: [],
                reminded_by: [],
              };
              //if plugin link send in support channel
              if('shared_company_ids' in body) payload.shared_company_ids = body.shared_company_ids;
              if('shared_team_ids' in body) payload.shared_team_ids = body.shared_team_ids;
              if('shared_user_ids' in body) payload.shared_users_ids = body.shared_user_ids;
              if('announcement' in body) payload.announcement = body.announcement;
              if('support_message' in body) payload.support_message = body.support_message;
              context.dispatch("sendMessageStore", payload);
            } else {
              context.commit("addNewMessage", {
                currentMessages: previousMessages,
                message: response.data,
              });
            }
          }
        } else {
          reject(response);
        }
      };
      pluginAPI.createPluginLink(body, done);
    });
  },
};
let mutations = {
  // Add plugin to local store
  addPlugin(state,payload)
  {
   let index = payload.userPlugins.findIndex(plugin => plugin._id == payload.pluginId);
   if(index > -1) payload.userPlugins[index].user_plugin = true;
   else{
     payload.userPlugins.push(payload.plugin)
   }
  },
  // Remove plugin from local store
  removePlugin(state,payload)
  {
   let index = payload.userPlugins.findIndex(plugin => plugin._id == payload.pluginId);
   if(index > -1) payload.userPlugins[index].user_plugin = false;
  }
};
export default {
  state,
  getters,
  mutations,
  actions,
};
