/**
 * System: Whistle It
 * Developer: Ahmad Hanan and Nabil Ahmad
 * Organization: Whistle It
 * Purpose: This file is for listening and changing updated data in corresponding local store.
 */

import debug from "@/console";
import firebase from "firebase/app";
import "firebase/messaging";
import router from "@/router";
//verify if logged-in user has udate permissions in user
var companySocket = {
  state: {
    notificationData: null,
    companyDataUpdated: false,
    isGoingToLogout: false,
    leaveCompanyName: "",
  },
  getters: {
    getNotificationData: (state) => state.notificationData,
    getIsGoingToLogout: (state) => state.isGoingToLogout,
    getLeaveCompanyName: (state) => state.leaveCompanyName,
    getCompanyDataUpdated: (state) => state.companyDataUpdated,
  },
  actions: {
    /**
     * This action listen for socket whenver admin settings changed of a company
     *
     * @param {Object} data recieved from socket
     * @return {void}
     */
    socket_adminSettingsChanged(context, data) {
      // find company
      let company = context.rootGetters.getCompanyById(data.company_id);
      // find selected company
      let selectedCompany = context.rootGetters.getSelectedCompany;
      //if data exist call mutation
      if (company && selectedCompany)
        context.commit(
          "companyDataUpdated",
          company._id == selectedCompany._id
        );
    },

    /**
     * This action listen for socket whenver new admin is added to company
     *
     * @param {Object} payload recieved from socket
     * @return {void}
     */
    socket_newAdminsAdded(context, payload) {
      // find company
      let company = context.rootGetters.getCompanyById(payload.company_id);
      //loop through all the new admin ids
      for (const new_admin of payload.new_admin_ids) {
        // find user match with admin id
        let user = company.users.find((user) => user._id == new_admin);
        //if user find, call mutation to change user role to admin
        if (user) {
          context.commit("changeRoleToAdmin", {
            user,
            company,
            loggedInUserId: context.rootGetters.loggedInUserDetails._id,
          });
        }
      }
    },

    /**
     * This action listen for socket whenver admin is removed from admin role
     *
     * @param {Object} payload recieved from socket
     * @return {void}
     */
    socket_newAdminsRemoved(context, payload) {
      // find company
      let company = context.rootGetters.getCompanyById(payload.company_id);
      //loop through all the user ids which will be removed
      for (const new_admin of payload.new_user_ids) {
        // find user match with admin id
        let user = company.users.find((user) => user._id == new_admin);
        //if user find, call mutation to change user role from admin to user
        if (user) {
          context.commit("changeRoleToUser", {
            user,
            company,
            loggedInUserId: context.rootGetters.loggedInUserDetails._id,
          });
        }
      }
    },

    /**
     * This action listen for socket recieved to creator of Company. New company object push in companies array
     *
     * @param {Object} data recieved from socket
     * @return {void}
     */
    socket_newCompany(context, data) {
      //find company index, in store data, company in temp state
      let companyIndex = context.rootGetters.getCompanies.findIndex(
        (item) => item.name.toLowerCase() == data.company.name.toLowerCase()
      );
      //call mutation to add new company
      context.commit("newCompanyCreated", {
        companies: context.rootGetters.getCompanies,
        companyIndex: companyIndex,
        data: data,
      });
    },

    /**
     * This action is listened by all users who added in new company. New company obj push in companies array.
     *
     * @param {Object} data recieved from socket
     * @return {void}
     */
    socket_addedInNewCompany(context, data) {
      //call mutation
      context.commit("addedInCompany", {
        storeData: context.rootGetters.getCompanies,
        data: data,
      });
    },

    /**
     * This action is listened by admin and all users who already added in new company and push new users in there store.
     *
     * @param {Object} data recieved from socket
     * @return {void}
     */
    socket_newUserAdded(context, data) {
      //find company in which user is added
      let company = context.rootGetters.getCompanyById(data.company_id);
      //call mutation to add user
      context.commit("userAdded", {
        companyUsers: company.users,
        newUsers: data.users,
      });
    },

    /**
     * This action is listened by all users who already added in new company.
     *
     * @param {Object} data recieved from socket
     * @return {void}
     */
    socket_companyNameChange(context, data) {
      //find company index whose name is changed
      let company_index = context.rootState.storeData.companies.findIndex(
        (item) => item._id == data.company_id
      );
      //call mutation to change name
      context.commit("companyNameChange", {
        storeData: context.rootState.storeData.companies[company_index],
        data: data.name,
      });
    },

    /**
     * This action is listened by all users of company when delete file time is changed by admin
     *
     * @param {Object} data recieved from socket
     * @return {void}
     */
    socket_deleteFileTimeChange(context, data) {
      //find company index
      let company_index = context.rootState.storeData.companies.findIndex(
        (item) => item._id == data.company_id
      );
      //call mutation to set new shareable file time
      context.commit("SET_SHAREABLE_FILE_TIME", {
        company: context.rootState.storeData.companies[company_index],
        time: data.delete_file_after,
      });
    },

    /**
     * This action is listened by all users of company when a file shareable link is changed by admin
     *
     * @param {Object} data recieved from socket
     * @return {void}
     */
    socket_shareAbleLinkChange(context, data) {
      //find company index in which shareable link is changed
      let company_index = context.rootState.storeData.companies.findIndex(
        (item) => item._id == data.company_id
      );
      //find that company using index
      let company = context.rootState.storeData.companies[company_index];
      //call mutation to change shareable link
      context.commit("SET_SHAREABLE_LINK_CHANGE", {
        company,
        shareable_link: data.shareable_link,
      });
    },

    /**
     * This action is listened by all users of company when ever ip status is changed
     *
     * @param {Object} data recieved from socket
     * @return {void}
     */
    socket_ipStatusChange(context, data) {
      //find company index
      let company_index = context.rootState.storeData.companies.findIndex(
        (item) => item._id == data.company_id
      );
      //call mutation to change ip status
      context.commit("IP_STATUS_CHANGE", {
        company: context.rootState.storeData.companies[company_index],
        ip_status: data.ip_status,
      });
    },

    /**
     * This action is listened by all users of company when ever users ips change
     *
     * @param {Object} data recieved from socket
     * @return {void}
     */
    socket_ipsChange(context, data) {
      //find company index of the company
      let company_index = context.rootState.storeData.companies.findIndex(
        (item) => item._id == data.company_id
      );
      //call mutation to change company data
      context.commit("IPS_CHANGE", {
        company: context.rootState.storeData.companies[company_index],
        ips: data.ips,
      });
    },

    /**
     * This action is listened by all users of company when company 2fa is updated
     *
     * @param {Object} data recieved from socket
     * @return {void}
     */
    socket_companyTwoFaChange(context, data) {
      //find company index
      let company_index = context.rootState.storeData.companies.findIndex(
        (item) => item._id == data.company_id
      );
      //call mutation to update company settings
      context.commit("COMPANY_TWO_FA", {
        company: context.rootState.storeData.companies[company_index],
        two_fa: data.two_fa,
      });
    },

    /**
     * This action is listened by user who removed from company
     *
     * @param {Object} data recieved from socket
     * @return {void}
     */
    async socket_removedCompany(context, data) {
      //find company in which user is added
      let company = context.rootGetters.getCompanyById(data.company_id);

      if (company) {
        context.commit("setRemovedCompanyName", company.name);
      }
    },

    /**
     * This action is listened by all users who already added in new company.
     *
     * @param {Object} data recieved from socket
     * @return {void}
     */
    async socket_userRemovedFromCompany(context, data) {
      //if logged in user is not removed from company then remove user_id from all teams and channels
      if (data.user_id != context.rootGetters.loggedInUserDetails._id) {
        context.commit("userRemovedFromCompany", {
          company: context.rootGetters.getCompanyById(data.company_id),
          user_id: data.user_id,
        });
      }
    },

    /**
     * This action is listened by a user which fcm token is expired.
     *
     * @param {Object} data recieved from socket
     * @return {void}
     */
    async socket_tokenRefresh(context, data) {
      if (localStorage.getItem("mobile_token")) {
        context.commit("SAVE_NOTIFICATION_DATA", data);
        try {
          window.android.getDeviceToken();
        } catch (error) {
          debug.log("android ", error);
        }
        try {
          window.webkit.messageHandlers.ios.postMessage(
            JSON.stringify({
              "event-type": "session_expire",
            })
          );
          // window.webkit.messageHandlers.ios.session_expire()
        } catch (error) {
          debug.log("ios", error);
        }
      } else {
        try {
          const messaging = firebase.messaging();
          const token = await messaging.getToken();
          //add new fcm token to data object which is received from socket
          data.fcm_token = token;

          //send new fcm token
          await context.dispatch("sendRefreshedNotifToken", data);
          return token;
        } catch (error) {
          debug.log(error);
        }
      }
    },

    /**
     * This action is listened by admin/subadmin to update file status.
     *
     * @param {Object} data recieved from socket
     * @return {void}
     */
    socket_companyFileStatusChanged(context, data) {
      //find company in which user is added
      let company = context.rootGetters.getCompanyById(data.company_id);
      //if company find call mutation to updat
      if (company) {
        context.commit("updateFileStatus", {
          company,
          file_status: data.file_status,
        });
      }
    },

    /**
     * This action is listened by admin/subadmin to update file ips.
     *
     * @param {Object} data recieved from socket
     * @return {void}
     */
    socket_companyFileIpsChanged(context, data) {
      //find company in which user is added
      let company = context.rootGetters.getCompanyById(data.company_id);
      if (company) {
        context.commit("updateFileIps", {
          company,
          file_ips: data.file_ips,
        });
      }
    },

    /**
     * This action is triggered when user switch company
     *
     * @param {Object} data recieved from socket
     * @return {void}
     */
    switchCompany(context, data) {
      //Pass id of current company and the switched company
      this._vm.$socket.client.emit("switchCompany", data);
    },

    /**
     * This action is triggered when company archived
     *
     * @param {Object} data recieved from socket
     * @return {void}
     */
    socket_companyArchived(context, data) {
      //find company
      let company = context.rootGetters.getCompanyById(data.company_id);
      if (company) {
        context.commit("setRemovedCompanyName", company.name);
      }
    },
  },

  mutations: {
    /**
     * This mutation set flag value of the state
     *
     * @param {Boolean} flag value to show popup
     * @return {void}
     */
    companyDataUpdated(state, flag) {
      if (flag) state.companyDataUpdated = true;
    },

    /**
     * This mutation reset flag value
     *
     * @return {void}
     */
    resetCompanyDataUpdated(state) {
      state.companyDataUpdated = false;
    },

    /**
     * This mutation update user role to admin
     *
     * @param {Object} payload object to update user role
     * @return {void}
     */
    changeRoleToAdmin(state, payload) {
      payload.user.role_name = "admin";
      if (payload.loggedInUserId == payload.user._id) {
        payload.company.role_name = "admin";
      }
    },

    /**
     * This mutation change role of admin to user
     *
     * @param {Object} payload object to update user role
     * @return {void}
     */
    changeRoleToUser(state, payload) {
      payload.user.role_name = "user";
      if (payload.loggedInUserId == payload.user._id) {
        payload.company.role_name = "user";
        router.push("/");
      }
    },

    /**
     * This mutation add new company object in store data
     *
     * @param {Object} payload new company object
     * @return {void}
     */
    newCompanyCreated(state, payload) {
      //if company already not exist i.e. temp state
      if (payload.companyIndex == -1) {
        payload.companies.push(payload.data.company);
      }
      //replace existing object
      else {
        Object.assign(
          payload.companies[payload.companyIndex],
          payload.data.company
        );
      }
    },

    /**
     * This mutation push new company object for all other users who are added in company
     *
     * @param {Object} payload new company object
     * @return {void}
     */
    addedInCompany(state, payload) {
      if (
        !payload.storeData.find((company) => company._id == payload.data._id)
      ) {
        payload.storeData.push(payload.data);
      }
    },

    /**
     * This mutation update company name
     *
     * @param {Object} payload new company object
     * @return {void}
     */
    companyNameChange(state, payload) {
      payload.storeData.name = payload.data;
    },

    SET_SHAREABLE_FILE_TIME(state, payload) {
      payload.company.delete_file_after = payload.time;
    },

    SET_SHAREABLE_LINK_CHANGE(state, payload) {
      payload.company.shareable_link = payload.shareable_link;
    },

    IP_STATUS_CHANGE(state, payload) {
      payload.company.ip_status = payload.ip_status;
    },

    IPS_CHANGE(state, payload) {
      payload.company.ips_array = payload.ips;
    },

    COMPANY_TWO_FA(state, payload) {
      payload.company.two_fa = payload.two_fa;
    },

    /**
     * This mutation add new user in the company users array
     *
     * @param {Object} payload new user
     * @return {void}
     */
    userAdded(state, payload) {
      //loop through all users, if not exist push else replace object
      payload.newUsers.forEach((user) => {
        //find user index
        let userIndex = payload.companyUsers.findIndex(
          (compUser) => compUser._id == user._id || compUser.email == user.email
        );
        //if not find push
        if (userIndex == -1) {
          payload.companyUsers.push(user);
        }
        //replace current object
        else {
          Object.assign(payload.companyUsers[userIndex], user);
        }
      });
    },

    /**
     * This mutation remove specific user from company. It will remove user from
     * direct, public and private then remove from all teams
     *
     * @param {Object} payload user to remove
     * @return {void}
     */
    userRemovedFromCompany(state, payload) {
      //check company users
      if (payload.company.users) {
        //find index
        let index = payload.company.users.findIndex((item) => {
          return item._id == payload.user_id;
        });
        if (index != -1) {
          // check whether the logged in user is admin or sub-admin then set archive date
          payload.company.users[index].archived = new Date().toLocaleString(
            "sv-SE"
          );
        }
        //find direct channel
        let directChannel = payload.company.direct.find((channel) =>
          channel.users.includes(payload.user_id)
        );
        if (directChannel) {
          directChannel.activate = false;
        }
        // loop through all teams
        payload.company.teams.forEach((team) => {
          // remove the user from all channels
          payload.company.channels.forEach((channel) => {
            if (channel.team_id == team._id) {
              let userIndex = channel.users.findIndex(
                (userId) => userId == payload.user_id
              );
              if (userIndex != -1) {
                channel.users.splice(userIndex, 1);
              }
            }
          });
          // remove that user from team
          let userIndex = team.users.findIndex(
            (userId) => userId == payload.user_id
          );
          if (userIndex != -1) {
            team.users.splice(userIndex, 1);
          }
        });
      }
    },
    //update file status is  global access true or false
    updateFileStatus(state, payload) {
      payload.company.file_restrict_status = payload.file_status;
    },
    //which ips have access of files
    updateFileIps(state, payload) {
      payload.company.file_allowed_ips = payload.file_ips;
    },

    SAVE_NOTIFICATION_DATA(state, payload) {
      state.notificationData = payload;
    },

    setRemovedCompanyName(state, payload) {
      state.leaveCompanyName = payload;
      if (!state.isGoingToLogout) {
        state.isGoingToLogout = true;
      }
    },
  },
};

export default companySocket;
