import departmentAPI from "@/api/departmentAPI.js";
import axios from "axios";
// import debug from "@/console";
const state = {};
const getters = {
  getClickedDepartment: (getters, rootGetters) => (department_id) => {
    return getters.getDepartments.find((item) => {
      return item._id == department_id;
    });
  },
  /**
   * this getter return the detail of selected department
   */
  //  getDepartments: (state, rootGetters)=>rootGetters.getSelectedCompany.departments,
  // // Get Archived Departments
  getArchivedDepartments: (state, rootGetters) =>
    //find all archived Departments of selected company
    rootGetters.getDepartments.filter((item) => {
      return item.archived != null;
    }),

  // // Get UnArchived Departments
  getUnarchivedDepartments: (state, rootGetters) => {
    //find all unarchived Departments of selected company
    return rootGetters.getDepartments.filter((item) => {
      return item.archived == null;
    });
  },
  getDepartmentById: (state, rootGetters) => (department_id) =>
    //get user obj by passing its id
    rootGetters.getSelectedCompany.departments.find((item) => {
      return item._id == department_id;
    }),
};
const actions = {
  /*
    this method fetch selected company departments from API
    args (company_id)
       */
  fetchDepartments: (context, body) => {
    return new Promise((resolve, reject) => {
      var done = (response) => {
        if (response.status == 200) {
          resolve(response);
        } else {
          reject(response);
        }
      };
      departmentAPI.fetchDepartments(body, done);
    });
  },
  /*
    this method fetch non members of specific department
    args (department_id)
       */
  fetchDepartmentNonMembers: (context, body) => {
    return new Promise((resolve, reject) => {
      var done = (response) => {
        if (response.status == 200) {
          resolve(response);
        } else {
          reject(response);
        }
      };
      departmentAPI.fetchDepartmentNonMembers(body, done);
    });
  },
  /*
    this method fetch  members of specific department
    args (department_id)
       */
  fetchDepartmentMembers: (context, body) => {
    return new Promise((resolve, reject) => {
      var done = (response) => {
        if (response.status == 200) {
          resolve(response);
        } else {
          reject(response);
        }
      };
      departmentAPI.fetchDepartmentMembers(body, done);
    });
  },
  //Create New Team
  // This Action is Dispatched From AddDepartment Modal File
  // Data to send { CompanyID,DepartmentName, ProfilePicture }
  addDepartment: (context, body) => {
    return new Promise((resolve, reject) => {
      let departmentArray = context.rootGetters.getSelectedCompany.departments;
      let company = context.rootGetters.getSelectedCompany;
      context.commit("createDepartment", {
        departmentArray,
        newDepartmentObj: body,
      });
      var done = (response) => {
        if (response.status == 201) {
          let responseDepartment = {
            name: response.data.name,
            user_ids: response.data.user_ids,
            archived: null,
            created_at: response.data.created_at,
            _id: response.data._id,
          };
          context.commit("createDepartmentResponse", {
            departmentArray,
            tempId: body._id,
            newId: response.data._id,
            responseDepartment: responseDepartment,
            name: response.data.name,
          });
          resolve(response);
        } else {
          context.commit("removeDepartmentOnError", {
            departmentArray,
            tempId: body._id,
          });
          //remove specific department from store if API send error response on team creation
          reject(response);
        }
      };
      departmentAPI.createDepartment(company._id, body.name, done);
    });
  },

  // add members to department (args : user_ids,department_id)
  addDepartmentMembers: (context, body) => {
    let company = context.rootGetters.getSelectedCompany;
    return new Promise((resolve) => {
      var done = (response) => {
        resolve(response);
        context.commit("ADD_DEPARTMENT_MEMBERS", {
          dept: company.departments.find(
            (department) => department._id == body.department_id
          ),
          company,
          user_ids: body.user_ids,
        });
      };
      departmentAPI.addUsersInDepartment(
        body.department_id,
        body.user_ids,
        body.company_id,
        done
      );
    });
  },
  // //remove team members (args : user_ids,team_id)
  removeDepartmentMembers: (context, body) => {
    let company = context.rootGetters.getSelectedCompany;
    return new Promise((resolve) => {
      var done = (response) => {
        resolve(response);
        context.commit("REMOVE_DEPT_MEMBERS", {
          dept: company.departments.find(
            (department) => department._id == body.department_id
          ),
          user_ids: body.user_ids,
        });
      };
      departmentAPI.removeUsersFromDepartment(
        body.department_id,
        body.user_ids,
        body.company_id,
        done
      );
    });
  },

  // //Archive Team
  // // This Action is Dispatched From TeamsPannel File
  // // Data to send { CompanyID, TeamID }
  archiveDepartment: ({commit, rootGetters}, body) => {
    let company = rootGetters.getSelectedCompany;
    return new Promise((resolve) => {
      departmentAPI.archiveDepartment(
        body.company_id,
        body.department_id,
        body.deleted_at,
        async (response) => {
          if (response.status == 200) {
            commit("ARCHIVE_DEPARTMENT", {
              department_id: body.department_id,
              company,
            });
            resolve(response);
          }
        }
      );
    });
  },

  // //Archive Department
  // // This Action is Dispatched From DepartmentPannel File
  // // Data to send { CompanyID, DepartmentID }
  UnarchiveDepartment: ({commit, rootGetters}, body) => {
    let company = rootGetters.getSelectedCompany;
    return new Promise((resolve) => {
      departmentAPI.unarchiveDepartment(
        body.company_id,
        body.department_id,
        body.deleted_at,
        async (response) => {
          if (response.status == 200) {
            commit("UNARCHIVE_DEPARTMENT", {
              department_id: body.department_id,
              company,
            });
            resolve(response);
          }
        }
      );
    });
  },
  // // Update Team Name
  // // This Action is Dispatched From EditTeamModal File
  // // Data to send { CompanyID, TeamName, TeamID  }
  updateDepartmentName: (context, body) => {
    return new Promise((resolve, reject) => {
      departmentAPI.updateDepartmentName(
        body.company_id,
        body.name,
        body.department_id,
        (response) => {
          //send back response to calling function
          if (response.status == 200) {
            resolve(response);
          } else {
            reject(response);
          }
        }
      );
    });
  },
  //API call for update User Name and Company Name
  changeUserDepartment: (context, data) => {
    return new Promise((resolve, reject) => {
      departmentAPI.changeUserDepartment(data, (response) => {
        if (response.status == 200) {
          let company = context.rootGetters.getSelectedCompany;
          let companyUsers = context.rootGetters.getSelectedCompany.users;
          let department = context.getters.getDepartmentById(
            data.department_id
          );

          context.commit("CHANGE_USER_DEPARTMENT", {
            data: data,
            companyUsers: companyUsers,
            company: company,
            department: department,
          });
          resolve(response);
        } else {
          reject(response);
        }
      });
    });
  },
};
const mutations = {
  //This will create Department in selected company
  createDepartment: (state, payload) => {
    payload.departmentArray.push(payload.newDepartmentObj);
  },
  //This will change department data after api response
  // departmentArray,
  //                     tempId: body._id,
  //                     newId: response.data._id,
  createDepartmentResponse: (state, payload) => {
    //find department with temp id and replace it with original id after response
    let findedDepartment = payload.departmentArray.find(
      (department) =>
        department.name.toLowerCase() == payload.name.toLowerCase()
    );
    if (findedDepartment) {
      Object.assign(findedDepartment, payload.responseDepartment);
    }
  },
  removeDepartmentOnError: (state, payload) => {
    let index = payload.departmentArray.findIndex(
      (department) => department._id == payload.tempId
    );
    payload.departmentArray.splice(index, 1);
  },
  //Add ids of users to local store
  ADD_DEPARTMENT_MEMBERS: (state, payload) => {
    if (payload.dept) {
      for (let id of payload.user_ids) {
        if (!payload.dept.user_ids.includes(id)) {
          payload.dept.user_ids.push(id);
        }
        //
        payload.company.departments.forEach((department) => {
          if (
            department._id != payload.dept._id &&
            department.user_ids.includes(id)
          ) {
            let index = department.user_ids.findIndex((iD) => iD == id);
            if (index > -1) department.user_ids.splice(index, 1);
          }
        });
      }
    }
  },

  // //remove ids of users from local store
  REMOVE_DEPT_MEMBERS: (state, payload) => {
    for (let userId of payload.user_ids) {
      if (payload.dept && payload.dept.user_ids) {
        let index = payload.dept.user_ids.findIndex((item) => {
          return item == userId;
        });
        if (index > -1) {
          payload.dept.user_ids.splice(index, 1);
        }
      }
    }
  },

  // //This will archive team
  ARCHIVE_DEPARTMENT: (state, payload) => {
    //set department to archived
    //find that department  and set archived to current date
    let department = payload.company.departments.find(
      (department) => department._id == payload.department_id
    );
    if (department) {
      department.archived = new Date().toLocaleString("sv-SE");
    }
  },

  // //   Unarchive Department
  UNARCHIVE_DEPARTMENT: (state, payload) => {
    //set department to unarchived
    //find that department and set unarchived to null
    let department = payload.company.departments.find((department) => {
      return department._id == payload.department_id;
    });
    if (department) department.archived = null;
  },

  // //This will update department name data in state
  UPDATE_DEPARTMENT_NAME: (state, payload) => {
    //set new name of department,
    //find that department  and set archived to current date
    payload.departmentArray.find(
      (item) => item._id == payload.newDepartmentObj.department_id
    ).name = payload.newDepartmentObj.name;
  },
  CHANGE_USER_DEPARTMENT: (state, payload) => {
    let user = payload.companyUsers.find(
      (user) => user._id == payload.data.user_ids[0]
    );
    //let departmentIndex = payload.company.departments.findIndex(department => department._id == payload.data.department_id);
    if (user) {
      user.department = payload.data.department_name;
    }
    //remoev user id from other previous department
    payload.company.departments.forEach((department) => {
      if (
        department._id != payload.data.department_id &&
        department.user_ids.includes(payload.data.user_ids[0])
      ) {
        let index = department.user_ids.findIndex(
          (id) => id == payload.data.user_ids[0]
        );
        if (index > -1) department.user_ids.splice(index, 1);
      }
    });
    if (payload.department) {
      if (!payload.department.user_ids.includes(payload.data.user_ids[0]))
        payload.department.user_ids.push(payload.data.user_ids[0]);
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
