/*
System: Whistle It
Developer: Menahil 
Organization: Whistle It
Purpose: This file is used for making api calls for payment module
*/
import requestQueue from "@/service/requestQueue";
import debug from "@/console";
var requestBody;
var requestEndPoint;
var token;

const paymentAPI = {
    /*
    This function is responsible for making an api call to fetch payment details
    args: requestBody, callback function for done
    */    
    fetchPaymentDetails: (requestBody, done) => {
        requestBody = {
            company_id: requestBody.company_id,
        };
        requestEndPoint = "adminPanel/paymentDetails";
        token = JSON.parse(localStorage.getItem("token"));
        requestQueue.post(requestEndPoint, token, requestBody, done);
    },
    /*
    This function is responsible for making an api call to add a new payment method
    args: requestBody, callback function for done
    */
    addPaymentCard: (requestBody, done) => {
        requestBody = {
            company_id: requestBody.company_id,
            stripe_customer_token: requestBody.stripe_customer_token,
            card_details: requestBody.card_details,
        };
        requestEndPoint = "stripe/addCard";
        token = JSON.parse(localStorage.getItem("token"));
        requestQueue.post(requestEndPoint, token, requestBody, done);
    },
    /*
    This function is responsible for making an api call to delete a payment method
    args: requestBody, callback function for done
    */    
    deletePaymentCard: (requestBody, done) => {
        requestBody = {
            company_id: requestBody.company_id,
            customer_card_id: requestBody.customer_card_id,
        };
        requestEndPoint = "stripe/card/delete";
        token = JSON.parse(localStorage.getItem("token"));
        requestQueue.post(requestEndPoint, token, requestBody, done);
    },
    /*
    This function is responsible for making an api call to activate/deactivate subscription
    args: requestBody, callback function for done
    */     
    updateAutoRenewalSettings: (requestBody, done) => {
        requestBody = {
            company_id: requestBody.company_id,
            status: requestBody.status
        };
        requestEndPoint = "stripe/pauseUnpauseSubscription";
        token = JSON.parse(localStorage.getItem("token"));
        requestQueue.post(requestEndPoint, token, requestBody, done);
    },
    /*
    This function is responsible for making an api call to set a default payment method
    args: requestBody, callback function for done
    */
    updateDefaultPaymentCard: (requestBody, done) => {
        requestBody = {
            company_id: requestBody.company_id,
            new_default_card: requestBody.new_default_card
        };
        requestEndPoint = "stripe/updateDefaultCard";
        token = JSON.parse(localStorage.getItem("token"));
        requestQueue.post(requestEndPoint, token, requestBody, done);
    },
    /*
    This function is responsible for making an api call to charge payment
    args: company_id, callback function for done
    */
    chargePayment: (company_id, done) => {
        requestBody = {
            company_id: company_id,
        };
        requestEndPoint = "stripe/chargeSubscription";
        token = JSON.parse(localStorage.getItem("token"));
        requestQueue.post(requestEndPoint, token, requestBody, done);
    },
    /*
    This function is responsible for making an api call to hide the notification icon
    args: company_id, callback function for done
    */
    hideNotificationIcon: (requestBody, done) => {
        requestBody = {
            company_id: requestBody.company_id,
            show_trial_payment_badge: requestBody.show_trial_payment_badge
        };
        requestEndPoint = "company/update";
        token = JSON.parse(localStorage.getItem("token"));
        requestQueue.post(requestEndPoint, token, requestBody, done);
    }

};

export default paymentAPI;