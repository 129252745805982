/*
System: Whistle It
Developer: Ahmad Hannan
Organization: Whistle It
Purpose: This file is used for making api calls for companies
*/
import requestQueue from "@/service/requestQueue";
import debug from "@/console";
const status = 200;
var requestBody;
var requestEndPoint;
var token;

const companyAPI = {
    fetchIPSetting(company_id, done) {
        requestBody = {
            company_id,
        };
        requestEndPoint = "adminPanel/companyIpSetting";
        token = JSON.parse(localStorage.getItem("token"));
        requestQueue.post(requestEndPoint, token, requestBody, done);
    },
    /* Admin CRUD */

    /*
This function is responsible for making api call for create company
args:(name of the company), callback function for done
    */
    createCompany: (name, company_id, done) => {
        requestBody = {
            name,
            company_id,
        };
        requestEndPoint = "company/create";
        token = JSON.parse(localStorage.getItem("token"));
        requestQueue.post(requestEndPoint, token, requestBody, done);
    },
    companyAttachmentsSetting: (requestBody, done) => {
        requestEndPoint = "adminPanel/companyAttachmentsSetting";
        token = JSON.parse(localStorage.getItem("token"));
        requestQueue.post(requestEndPoint, token, requestBody, done);
    },
    // Update Company Name
    addAdminInCompany: (operation, company_id, new_admin_ids, done) => {
        requestBody = {
            operation,
            company_id,
            new_admin_ids,
        };
        requestEndPoint = "company/leave";
        token = JSON.parse(localStorage.getItem("token"));
        requestQueue.post(requestEndPoint, token, requestBody, done);
    },
    removeAdminInCompany: (operation, company_id, new_user_ids, done) => {
        requestBody = {
            operation,
            company_id,
            new_user_ids,
        };
        requestEndPoint = "company/leave";
        token = JSON.parse(localStorage.getItem("token"));
        requestQueue.post(requestEndPoint, token, requestBody, done);
    },
    verifyOTP: (operation, company_id, new_admin_ids, code, done) => {
        requestBody = {
            operation,
            company_id,
            new_admin_ids,
            code,
        };
        requestEndPoint = "company/verifyOtp";
        token = JSON.parse(localStorage.getItem("token"));
        requestQueue.post(requestEndPoint, token, requestBody, done);
    },
    verifyOTPToRemoveAdmin: (operation, company_id, new_user_ids, code, done) => {
        requestBody = {
            operation,
            company_id,
            new_user_ids,
            code,
        };
        requestEndPoint = "company/verifyOtp";
        token = JSON.parse(localStorage.getItem("token"));
        requestQueue.post(requestEndPoint, token, requestBody, done);
    }, 
     // Update Company Name
    updateCompanyName: (company_id, name, two_fa, done) => {
        requestBody = {
            company_id,
            name,
            two_fa,
        };
        requestEndPoint = "company/update";
        token = JSON.parse(localStorage.getItem("token"));
        debug.log(requestBody);
        requestQueue.post(requestEndPoint, token, requestBody, done);
    },
    /*
                This function is responsible for making api call for adding user company
                args:(company_id,emails[] of user to add,team_ids[]), callback function for done 
                */
    createUser: (company_id, emails, team_ids, done) => {
        debug.log("in client api");
        requestBody = {
            emails: emails,
            company_id: company_id,
            team_ids: team_ids,
        };
        requestEndPoint = "company/member/add";
        token = JSON.parse(localStorage.getItem("token"));
        requestQueue.post(requestEndPoint, token, requestBody, done);
    },
    /*
                This function is responsible for making api call for archiving user
                args:(user_id,company_id), callback function for done
                */
    archiveUser: (user_id, company_id, deleted_at, done) => {
        debug.log(user_id, company_id, deleted_at);
        requestBody = {
            user_id: user_id,
            company_id: company_id,
            deleted_at: deleted_at,
        };
        requestEndPoint = "company/member/setArchiveStatus";
        token = JSON.parse(localStorage.getItem("token"));
        requestQueue.post(requestEndPoint, token, requestBody, done);
    },
    /*
This function is responsible for making api call for unarchive user
args:(user_id,company_id), callback function for done
*/
    unarchiveUser: (user_id, company_id, deleted_at, team_ids, done) => {
        requestBody = {
            user_id: user_id,
            company_id: company_id,
            deleted_at: deleted_at,
            team_ids,
        };
        requestEndPoint = "company/member/setArchiveStatus";
        token = JSON.parse(localStorage.getItem("token"));
        requestQueue.post(requestEndPoint, token, requestBody, done);
    },
    /*
This function is responsible for making api call for assigning role to user
args:(company_id, user_id, role_name), callback function for done
*/
    assignRoleToUser: (company_id, user_id, role_name, done) => {
        //debug due to eslint error
        debug.log(company_id, user_id, role_name);
        //execute done after 2 second
        //this code will be replaced by API in future
        setTimeout(() => {
            done(status);
        }, 2000);
    },

    /*
This function is responsible for making api call for creating link for any specific team
args:(company_id, team_id), callback function for done
*/
    createInvitationLink: (company_id, team_id, done) => {
        requestBody = {
            company_id,
            team_id,
        };

        requestEndPoint = "team/createLink";
        token = JSON.parse(localStorage.getItem("token"));
        requestQueue.post(requestEndPoint, token, requestBody, done);
    },
    /*
This function is responsible for making api call for creating role
args:(company_id, permissions[], role_name), callback function for done
*/
    createRole: (company_id, permissions, role_name, assignee, done) => {
        //debug due to eslint error
        debug.log(company_id, permissions, role_name, assignee);
        //execute done after 2 second
        //this code will be replaced by API in future
        setTimeout(() => {
            done(status);
        }, 2000);
    },

    /*
This function is responsible for making api call for delete role
args:(company_id, role_name), callback function for done
*/
    deleteRole: (company_id, role_name, done) => {
        //debug due to eslint error
        debug.log(company_id, role_name);
        //execute done after 2 second
        //this code will be replaced by API in future
        setTimeout(() => {
            done(status);
        }, 2000);
    },

    /*
This function is responsible for making api call for enable public URL
args:(company_id, allow), callback function for done
*/
    enablePublicURL: (company_id, allow, done) => {
        requestBody = {
            company_id,
            shareable_link: allow,
        };
        requestEndPoint = "company/update";
        token = JSON.parse(localStorage.getItem("token"));
        requestQueue.post(requestEndPoint, token, requestBody, done);
    },
    /*
This function is responsible for making api call for time allowed to share file
args:(company_id, time), callback function for done
*/
    fileShareTime: (company_id, delete_file_after, done) => {
        requestBody = {
            company_id,

            delete_file_after,
        };
        debug.log(requestBody);
        requestEndPoint = "company/update";
        token = JSON.parse(localStorage.getItem("token"));
        requestQueue.post(requestEndPoint, token, requestBody, done);
    },

    // IP Restriction
    addIps: (company_id, ip_data, ip, done) => {
        requestBody = {
            ip: ip,
            company_id: company_id,
            ip_data: ip_data,
        };

        requestEndPoint = "company/update";
        token = JSON.parse(localStorage.getItem("token"));
        requestQueue.post(requestEndPoint, token, requestBody, done);
    },
    // Change Default IP Policy of the Company
    changeIP_Policy: (company_id, ip_status, done) => {
        requestBody = {
            company_id,
            ip_status,
        };

        requestEndPoint = "company/update";
        token = JSON.parse(localStorage.getItem("token"));
        requestQueue.post(requestEndPoint, token, requestBody, done);
    },
    // Change Default IP Policy of the Company for file global access
    changeFileIP_Policy: (data, done) => {
        requestBody = {
            company_id: data.company._id,
        };
        'file_status' in data ? requestBody.file_status = data.file_status : requestBody.file_ips = data.file_ips;
        requestEndPoint = "company/update";
        token = JSON.parse(localStorage.getItem("token"));
        requestQueue.post(requestEndPoint, token, requestBody, done);
    },

    // Archive Company
    archiveCompany: (company_id, archive_id, deleted_at, done) => {
        requestBody = {
            company_id,
            archive_id,
            deleted_at
        };

        requestEndPoint = "company/update";
        token = JSON.parse(localStorage.getItem("token"));
        requestQueue.post(requestEndPoint, token, requestBody, done);
    },

    archiveCompanyOTP: (company_id, archive_id, code, operation, deleted_at, done) => {
        requestBody = {
            company_id,
            archive_id,
            code,
            operation,
            deleted_at
        };

        requestEndPoint = "company/verifyOtp";
        token = JSON.parse(localStorage.getItem("token"));
        requestQueue.post(requestEndPoint, token, requestBody, done);
    },
    /*
    This function is responsible for making api call for update company profile picture
    args:(company_id, profile_picture, progress), callback function for done
    */
    updateCompanyPicture: (data, done) => {
        if ('profile_picture' in data.requestBody && data.requestBody.profile_picture == null) {
           data.progress = null;
        }
        requestEndPoint = "company/update";
        token = JSON.parse(localStorage.getItem("token"));
        requestQueue.post(requestEndPoint, token, data.requestBody, done, data.progress);
    },

};

export default companyAPI;