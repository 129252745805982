import companyAPI from "@/api/companyAPI.js";
import debug from "@/console";
const state = {};
const getters = {
  //this getter will return
  getMsgsCountCompany: (getters, rootGetters) => (companyId) => {
    let compMsgsCount = 0;
    let isAnyUnreadChannel = false;
    let company = rootGetters.getCompanies.find((item) => {
      return item._id == companyId;
    });
    company.channels.map((channel) => {
      if (
        !channel.muted &&
        channel.joined == true &&
        channel.archived == null
      ) {
        compMsgsCount +=
          channel.new_message_count + channel.thread_child_messages.length;
      }
      if (channel.unread_channel) {
        isAnyUnreadChannel = true;
      }
    });
    company.direct.map((channel) => {
      if (
        !channel.muted &&
        channel.joined == true &&
        channel.archived == null
      ) {
        compMsgsCount +=
          channel.new_message_count + channel.thread_child_messages.length;
      }
      if (channel.unread_channel) {
        isAnyUnreadChannel = true;
      }
    });
    if (isAnyUnreadChannel) {
      return 1;
    }
    return compMsgsCount;
  },
  getMsgsCountCompanyTitle: (getters, rootGetters) => {
    let compMsgsCount = 0;
    rootGetters.getCompanies.map((company) => {
      company.channels.map((channel) => {
        if (
          !channel.muted &&
          channel.joined == true &&
          channel.archived == null
        ) {
          compMsgsCount +=
            channel.new_message_count + channel.thread_child_messages.length;
        }
      });
      company.direct.map((channel) => {
        if (
          !channel.muted &&
          channel.joined == true &&
          channel.archived == null
        ) {
          compMsgsCount +=
            channel.new_message_count + channel.thread_child_messages.length;
        }
      });
    });
    return compMsgsCount;
  },
  getSelectedCompanyUsers: (getters, rootGetters) => {
    return rootGetters.getSelectedCompany.users;
  },
  getSelectedCompanyUnarchivedUsers: (getters, rootGetters) => {
    return rootGetters.getSelectedCompany.users.filter(
      (user) => !user.archived
    );
  },

  //get any company by providing its ID
  getCompanyById: (getters, rootGetters) => (company_id) => {
    return rootGetters.getCompanies.find((item) => {
      return item._id == company_id;
    });
  },
  getRestrictedIPs: (state, rootGetters) =>
    rootGetters.getSelectedCompany.ips_array,
  getCompaniesIds: (state, rootGetters) => {
    let company_ids = [];
    rootGetters.getCompanies.forEach((company) => {
      company_ids.push(company._id);
    });
    return company_ids;
  },
};
const actions = {
  fetchIPSetting: (context, body) => {
    return new Promise((resolve, reject) => {
      var done = (response) => {
        if (response.status == 200) {
          resolve(response);
        } else {
          reject(response);
        }
      };
      companyAPI.fetchIPSetting(body, done);
    });
  },
  companyAttachmentsSetting: (context, body) => {
    return new Promise((resolve, reject) => {
      var done = (response) => {
        if (response.status == 200) {
          resolve(response);
        } else {
          reject(response);
        }
      };
      companyAPI.companyAttachmentsSetting(body, done);
    });
  },

  removeCompany(context, company_id) {
    let companyIndex = context.rootGetters.getCompanies.findIndex(
      (company) => company._id == company_id
    );
    if (companyIndex != -1) {
      context.commit("removeComany", {
        companies: context.rootGetters.getCompanies,
        companyIndex,
      });
    }
  },
  addAdminInCompany(context, requestObj) {
    return new Promise((resolve, reject) => {
      companyAPI.addAdminInCompany(
        requestObj.operation,
        requestObj.company_id,
        requestObj.new_admin_ids,
        (response) => {
          if (response.status == 200) resolve(response);
          else reject(response);
        }
      );
    });
  },
  removeAdminInCompany(context, requestObj) {
    return new Promise((resolve, reject) => {
      companyAPI.removeAdminInCompany(
        requestObj.operation,
        requestObj.company_id,
        requestObj.new_user_ids,
        (response) => {
          if (response.status == 200) resolve(response);
          else reject(response);
        }
      );
    });
  },
  verifyOTP(context, requestObj) {
    return new Promise((resolve, reject) => {
      companyAPI.verifyOTP(
        requestObj.operation,
        requestObj.company_id,
        requestObj.new_admin_ids,
        requestObj.code,
        (response) => {
          if (response.status == 200) {
            resolve(response);
            if (requestObj.operation == "leave_company") {
              setTimeout(() => {
                context.dispatch("logOut");
              }, 1000);
            }
          } else {
            reject(response);
          }
        }
      );
    });
  },
  verifyOTPToRemoveAdmin(context, requestObj) {
    return new Promise((resolve, reject) => {
      companyAPI.verifyOTPToRemoveAdmin(
        requestObj.operation,
        requestObj.company_id,
        requestObj.new_user_ids,
        requestObj.code,
        (response) => {
          if (response.status == 200) {
            resolve(response);
          } else {
            reject(response);
          }
        }
      );
    });
  },
  //Create New Company
  // This Action is Dispatched From AdminDashboardView File
  // Data to send { CompanyName }
  createNewCompany: (context, body) => {
    context.commit("addCompany", {
      companies: context.rootGetters.getCompanies,
      obj: body.company,
    });
    return new Promise((resolve) => {
      companyAPI.createCompany(
        body.newCompanyName,
        body.company_id,
        (response) => {
          resolve(response);
          if (response.status == 201) {
            debug.log(response.data);
            context.commit("addCompanyResponse", {
              companies: context.rootGetters.getCompanies,
              tempId: body.company._id,
              newId: response.data._id,
              company: response.data,
            });

            //emit company creation to socket
          } else {
            context.commit("removeTempCompany", {
              companies: context.rootGetters.getCompanies,
              tempId: body.company._id,
            });
          }
        }
      );
    });
  },
  // enable Public URL
  // This Action is Dispatched From Attachments File
  // Data to send { CompanyID, Checked value  }
  enablePublicURL: (context, data) => {
    companyAPI.enablePublicURL(data.company_id, data.checked, (response) => {
      if (response.status == 200) {
        debug.log("");
      }
    });
  },
  // enable file global access
  // This Action is Dispatched From Attachments File
  // Data to send { CompanyID, Checked value  }
  changeFileIP_Policy: (context, requestBody) => {
    return new Promise((resolve, reject) => {
      companyAPI.changeFileIP_Policy(requestBody, (response) => {
        if (response.status == 200) {
          context.commit("allowGlobalIPAccessFiles", requestBody);
          resolve(response);
        } else {
          reject(response);
        }
      });
    });
  },
  // Update Company Name
  updateCompanyName: (context, body) => {
    return new Promise((resolve) => {
      companyAPI.updateCompanyName(
        body.company_id,
        body.name,
        body.two_fa,
        (response) => {
          if (response.status == 200) {
            resolve(response);
            context.commit("UPDATE_COMPANY_NAME", {
              name: body.name,
              selectedCompany: context.rootGetters.getSelectedCompany,
            });
          }
        }
      );
    });
  },
  // File Share Time
  // This Action is Dispatched From Attachments File
  // Data to send { CompanyID, time in seconds  }
  fileShareTime: (context, data) => {
    companyAPI.fileShareTime(data.company_id, data.seconds, (response) => {
      if (response.status == 200) {
        debug.log("Time has been set");
      }
    });
  },

  // IP Restriction
  addIps: (context, body) => {
    return new Promise((resolve) => {
      companyAPI.addIps(body.company_id, body.ip_data, body.ip, (response) => {
        if (response.status == 200) {
          context.commit("ADD_IPS", {
            ips_array: context.getters.getSelectedCompany.ips_array,
            ip_data: body.ip_data,
            company: context.rootGetters.getSelectedCompany,
          });
          resolve(response);
          debug.log(response);
        }
      });
    });
  },

  removeIps: (context, body) => {
    return new Promise((resolve) => {
      companyAPI.addIps(body.company_id, body.ip_data, body.ip, (response) => {
        if (response.status == 200) {
          resolve(response);
          context.commit("REMOVE_IPS", {
            ips_array: context.rootGetters.getSelectedCompany.ips_array,
            ip_data: body.ip_data,
          });
          debug.log(response);
        }
      });
    });
  },

  // Change Default IP Policy of the Company
  changeIP_Policy: (context, body) => {
    companyAPI.changeIP_Policy(body.company_id, body.ip_status, (response) => {
      if (response.status == 200) {
        debug.log(response);
        context.commit("UPDATE_IP_POLICY", {
          company: context.rootGetters.getSelectedCompany,
          ip_status: body.ip_status,
        });
      }
    });
  },
  createInvitationLink: (context, body) => {
    return new Promise((resolve) => {
      var done = (response) => {
        resolve(response);
        //update state invitationLink to response data
        context.state.invitationLink = response.data;
      };

      companyAPI.createInvitationLink(body.company_id, body.team_id, done);
    });
  },
  // this action is responsible for archiving company
  archiveCompany: (context, body) => {
    return new Promise((resolve, reject) => {
      // let next_company = context.rootGetters.getCompanies.find((company) => company._id != body.company_id)
      var done = (response) => {
        if (response.status == 200) {
          resolve(response);
        } else {
          reject(response);
        }
      };

      companyAPI.archiveCompany(
        body.company_id,
        body.archive_id,
        body.deleted_at,
        done
      );
    });
  },
  archiveCompanyOTP: (context, body) => {
    return new Promise((resolve, reject) => {
      var done = (response) => {
        if (response.status == 200) {
          resolve(response);
          context.dispatch("logOut");
        } else {
          reject(response);
        }
      };

      companyAPI.archiveCompanyOTP(
        body.company_id,
        body.archive_id,
        body.code,
        body.operation,
        body.deleted_at,
        done
      );
    });
  },
  //API call for  update Company Profile Picture
  updateCompanyPicture: ({ commit, rootGetters }, data) => {
    return new Promise((resolve, reject) => {
      companyAPI.updateCompanyPicture(data, (response) => {
        if (response.status == 200) {
          commit("updateCompanyPic", {
            company: rootGetters.getSelectedCompany,
            profile_picture: response.data.profile_picture,
          });
          resolve(response);
        } else {
          reject(response);
        }
      });
    });
  },
};
const mutations = {
  removeComany(state, payload) {
    payload.companies.splice(payload.companyIndex, 1);
  },
  changeRoleAdmin(state, payload) {
    for (const id of payload.new_admin_ids) {
      payload.users.find((user) => user._id == id).role_name = "admin";
    }
  },
  changeRoleUser(state, payload) {
    for (const id of payload.new_user_ids) {
      payload.users.find((user) => user._id == id).role_name = "user";
    }
  },
  //This will add company in store data companies arraty
  addCompany: (state, payload) => {
    payload.obj.direct = [];
    payload.obj.channels = [];
    payload.obj.teams = [];
    //push company that in root state
    payload.companies.push(payload.obj);
  },

  removeTempCompany: (state, payload) => {
    let companyIndex = payload.companies.findIndex((item) => {
      return item._id == payload.tempId;
    });
    if (companyIndex != -1) {
      payload.companies.splice(companyIndex, 1);
    }
  },

  //After getting API response replace that company data
  addCompanyResponse: (state, payload) => {
    //find company with temp id and replace it with original id after response
    let companyIndex = payload.companies.findIndex((item) => {
      return item._id == payload.tempId || item._id == payload.newId;
    });
    //replcae new company data
    Object.assign(payload.companies[companyIndex], payload.company);
  },
  //locally updates company name
  UPDATE_COMPANY_NAME: (state, payload) => {
    payload.selectedCompany.name = payload.name;
  },

  ADD_IPS: (state, payload) => {
    if (!payload.ips_array.find((ip) => ip.labl == payload.ip_data.label))
      payload.ips_array.push(...payload.ip_data);
  },

  REMOVE_IPS: (state, payload) => {
    for (var i = payload.ips_array.length - 1; i >= 0; i--) {
      for (var j = 0; j < payload.ip_data.length; j++) {
        if (
          payload.ips_array[i] &&
          payload.ips_array[i].label === payload.ip_data[j].label
        ) {
          payload.ips_array.splice(i, 1);
        }
      }
    }
  },

  UPDATE_IP_POLICY: (state, payload) => {
    payload.company.ip_policy = payload.ip_status;
  },
  //this will update company file access criteria
  allowGlobalIPAccessFiles: (state, payload) => {
    if ("file_status" in payload) {
      payload.company.file_restrict_status = payload.file_status;
    } else {
      payload.file_ips.forEach((ip) => {
        if (payload.company.file_allowed_ips.includes(ip)) {
          const index = payload.company.file_allowed_ips.indexOf(ip);
          if (index > -1) {
            payload.company.file_allowed_ips.splice(index, 1);
          }
        } else {
          payload.company.file_allowed_ips.push(ip);
        }
      });
    }
  },
  updateCompanyPic: (state, payload) => {
    payload.company.company_profile_picture = payload.profile_picture;
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
