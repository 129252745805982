import merge from 'lodash/merge';
var inputState = {
  state: {
    tiptapState: [],
    fileState: []
  },
  getters: {
    getChannelContent: state => channel_id => {
      return state.tiptapState.find(item => {
        return item.channel_id == channel_id;
      });
    },
    getChannelFiles: state => channel_id => {
    
      return state.fileState.find(item => {
        return item.channel_id == channel_id;
      });
    },
  },
  actions: {
    async setTiptapState(context, payload) {
      let obj = await context.getters.getChannelContent(payload.channel_id);
      if (obj === undefined) {
        context.commit("setTiptapState", payload);
      } else {
        context.commit("updateTiptapState", { obj, payload });
      }
    },
    async setFileState(context,payload){
      let obj = await context.getters.getChannelFiles(payload.channel_id);
      if (obj === undefined) {
        context.commit("setFileState", payload);
      } else {
        context.commit("updateFileState", { obj, payload });
      }
    },
    async removeErrorFile(context, payload)
    {
      let obj = await context.getters.getChannelFiles(payload.channelId);
      if(obj)
      {
        context.commit("removeErrorFile", {obj:obj,index:payload.index});
      }
    }
  },
  mutations: {
    setTiptapState(state, payload) {
      state.tiptapState.push(payload);
    },
    updateTiptapState(state, payload) {
      merge(payload.obj, { content: payload.payload.content });
    },
    setFileState(state, payload) {
      state.fileState.push(payload);
    },
    updateFileState(state, payload) {
      payload.obj.filteredFiles = payload.payload.filteredFiles;
      payload.obj.originalFiles = payload.payload.originalFiles;
      payload.obj.index = payload.payload.index;
      payload.obj.selectedFiles = payload.payload.selectedFiles;
      payload.obj.promiseArray = payload.payload.promiseArray;
    //  payload.obj.cancelTokenArray = payload.payload.cancelTokenArray;
      payload.obj.isFilesAttached = payload.payload.isFilesAttached;
      payload.obj.resumable = payload.payload.resumable
    },
    removeErrorFile(state, payload)
    {
      if(payload.index > - 1) {
        payload.obj.filteredFiles.splice(payload.index,1);
        payload.obj.originalFiles.splice(payload.index, 1);
       // payload.obj.cancelTokenArray.splice(payload.index, 1);
        payload.obj.index = payload.obj.index - 1;
      }
    }
  
  }
};
export default inputState;
