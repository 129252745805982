<!-- 
System: Whistle It
Developer: Azwar Khan And Nouman Saifi 
Revised By: Hafiz Syed Zain Ali Shah 
Organization: Whistle It
Purpose: This file is responsible for displaying audio in the header. 
-->
<template>
  <div
    class="rounded-lg audio-tray"
    :ref="dragHandleId"
    v-draggable="draggableValue"
  >
    <!-- Header section with action buttons -->
    <div
      class="row header-class rounded-lg rounded-b-0 d-flex justify-space-between mt-0"
    >
      <!-- Tooltip and button to navigate back to the channel -->
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            text
            icon
            x-small
            v-bind="attrs"
            v-on="on"
            color="white"
            @click="onClickGotoChannel"
          >
            <v-icon>mdi-open-in-new</v-icon>
          </v-btn>
        </template>
        <span>Go Back</span>
      </v-tooltip>

      <!-- Tooltip and button to close the audio player -->
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            text
            icon
            x-small
            v-bind="attrs"
            v-on="on"
            color="white"
            @click="audioClose"
          >
            <v-icon>mdi-close-circle-outline</v-icon>
          </v-btn>
        </template>
        <span>Close</span>
      </v-tooltip>
    </div>

    <!-- Display the audio user's name -->
    <v-row class="row justify-center">
      <p>{{ Urlpath.audioUserName?.name }}</p>
    </v-row>

    <!-- Audio playback controls and waveform visualization -->
    <v-row class="d-flex align-center justify-center flex-nowrap audio-message-row">
      <!-- Play/Pause button -->
      <v-col cols="auto" class="px-1">
        <v-btn icon @click="togglePlayback">
          <v-icon class="pa-4 audio-message-playpause-icon" color="#EEEFF7" rounded>
            {{ isPlaying ? 'mdi-pause' : 'mdi-play' }}
          </v-icon>
        </v-btn>
      </v-col>

      <!-- Waveform container -->
      <v-col cols="auto" class="px-1">
        <div :id="waveformId" style="width: 145px; height: 40px; align-self: center;"></div>
      </v-col>

      <!-- Playback duration text -->
      <v-col cols="auto" class="px-1 duration-text">
        <span>{{ formatTime(currentTime) }} / {{ formatTime(duration) }}</span>
      </v-col>

      <!-- Playback speed control -->
      <v-col cols="auto" class="px-1">
        <v-chip small link outlined icon @click="audioPlaybackSpeed">
          {{ audioSpeed }}x
        </v-chip>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { Draggable } from "draggable-vue-directive";
import WaveSurfer from 'wavesurfer.js';

export default {
  name: "AudioPlayerInHeader",
  props: ["Urlpath"], // Props passed to the component
  directives: {
    Draggable, // Registering the draggable directive
  },
  data() {
    return {
      closeButtonCheck: 0, // Flag to check if close button was clicked
      dragHandleId: "drag-handle-id", // ID for the draggable handle
      draggableValue: {
        handle: undefined,
        initialPosition: false,
        resetInitialPos: true,
        mediaStreamObj: undefined,
        showMaxLimitMsg: false,
      },
      wavesurfer: null, // Instance of WaveSurfer
      waveformId: `waveform-${this._uid}`, // Unique ID for the waveform container
      isPlaying: false, // Flag to track if audio is playing
      currentTime: 0, // Current playback time
      duration: 0, // Duration of the audio
      audioSpeed: 1.0, // Playback speed
    };
  },

  mounted() {
    this.draggableValue.handle = this.$refs[this.dragHandleId];
    this.initWaveSurfer(); // Initialize WaveSurfer after component mounts
  },

  methods: {
    // Initialize WaveSurfer instance
    initWaveSurfer() {
      this.wavesurfer = WaveSurfer.create({
        container: `#${this.waveformId}`,
        waveColor: '#c4d3e4',
        progressColor: '#423D68',
        responsive: true,
        height: 40,
        barWidth: 6,
        barGap: 1,
        cursorWidth: 1,
        cursorColor: 'none',
        interact: true,
      });

      // Load audio file into WaveSurfer
      this.wavesurfer.load(this.Urlpath.base64 || this.Urlpath.url);

      // On audio ready, set duration and playback rate, and start playing if not paused
      this.wavesurfer.on('ready', () => {
        this.duration = this.wavesurfer.getDuration();
        this.audioSpeed = this.Urlpath.audioSpeed || 1.0;
        this.wavesurfer.setPlaybackRate(this.audioSpeed);
        this.wavesurfer.seekTo(this.Urlpath.urlTime / this.duration);
        if (!this.Urlpath.pauseCheck) {
          this.wavesurfer.play();
          this.isPlaying = true;
        }
      });

      // Update current time on audio process
      this.wavesurfer.on('audioprocess', () => {
        this.currentTime = this.wavesurfer.getCurrentTime();
      });

      // Set isPlaying to false when audio finishes
      this.wavesurfer.on('finish', () => {
        this.isPlaying = false;
      });

      // Update current time on seek
      this.wavesurfer.on('seek', () => {
        this.currentTime = this.wavesurfer.getCurrentTime();
      });
    },

    // Toggle between play and pause states
    togglePlayback() {
      if (this.isPlaying) {
        this.wavesurfer.pause();
      } else {
        this.wavesurfer.play();
      }
      this.isPlaying = !this.isPlaying;
    },

    // Change the playback speed between predefined values
    audioPlaybackSpeed() {
      const speeds = [1.0, 1.5, 2.0];
      const currentSpeedIndex = speeds.indexOf(this.audioSpeed);
      const nextSpeedIndex = (currentSpeedIndex + 1) % speeds.length;
      this.audioSpeed = speeds[nextSpeedIndex];
      this.wavesurfer.setPlaybackRate(this.audioSpeed);
    },

    // Format time in minutes:seconds
    formatTime(seconds) {
      const minutes = Math.floor(seconds / 60);
      const secs = Math.floor(seconds % 60);
      return `${minutes}:${secs < 10 ? '0' : ''}${secs}`;
    },

    // Close the audio player and reset store state
    audioClose() {
      this.closeButtonCheck = 1;
      this.wavesurfer.pause();
      this.$store.commit("setCurrentLiveAudioState", {});
      this.$store.commit("setCurrentRemainingAudio", {});
    },

    // Navigate back to the channel view
    onClickGotoChannel() {
      let channelsId = this.Urlpath.channelsIdDetail;
      this.$router.push({
        name: "home",
        params: {
          companyId: channelsId.selectedCompanyId,
          teamId: channelsId.selectedTeamId,
          channelId: channelsId.selectedChannel._id,
        },
      });
    },
  },

  beforeDestroy() {
    // Handle component destruction, save playback state if necessary
    if (this.closeButtonCheck === 0) {
      let remainingAudioTime = this.wavesurfer.getCurrentTime();
      let remainingAudioURL = this.Urlpath.url;
      this.$store.commit("setCurrentRemainingAudio", {
        pauseCheck: !this.isPlaying,
        url: remainingAudioURL,
        urlTime: remainingAudioTime,
      });
      this.$store.commit("setCurrentLiveAudioState", {});
    }
    if (this.wavesurfer) {
      this.wavesurfer.destroy(); // Clean up WaveSurfer instance
    }
  },
};
</script>

<style scoped>
audio::-webkit-media-controls-panel {
  background-color: white;
}

.a-audio {
  height: 30px !important;
  border: 1px solid #dee2ed !important;
  border-radius: 20px;
}

.medium-display {
  width: 300px;
}

.small-display {
  width: 290px;
  place-content: center;
  align-content: center;
  padding-bottom: 0;
  margin-left: 2px;
  margin-right: 2px;
}

.color-for-audio {
  background-color: #2d2b51;
  color: white;
}

.audio-tray {
  position: fixed !important;
  right: 0 !important;
  top: 60px;
  z-index: 999 !important;
  width: 320px !important;
  padding-left: 12px;
  padding-right: 6px;
  background-color: white;
  cursor: move;
}

.header-class {
  width: 320px !important;
  cursor: move;
  color: #34325f;
  background-color: #34325f;
  /* padding: 1px; */
}
.duration-text {
  font-size: 0.7rem;
}
.audio-message-playpause-icon {
  background-color: #423D68; 
  border-radius: 50%;
}
.audio-message-row {
  max-height: 55px;
  width: 320px;
  justify-content: center;
  /* min-height: fit-content;
  max-width: fit-content;
  min-width: fit-content; */
  border: #DBDBDD 1px solid;
  border-radius: 20px;
  background-color: #EEEFF7;
}
</style>