import axios from "axios";

const state = {
  timeOutPolling: -1,
  unReadThreads: [],
  vistedChannelList: [],
};
const getters = {
  /**
   * this getter will get hash of user data for polling
   */
  getUserDataHash: (state) => state.userDataHash,
};
const actions = {
  selectedChannelPolling(context) {
    //clear first previous timeout
    if (context.state.timeOutPolling > -1) {
      window.clearTimeout(context.state.timeOutPolling);
    }
    //if channel is selected
    if (
      context.rootState.selectedChannel &&
      context.rootState.selectedChannel._id &&
      !context.state.vistedChannelList.includes(
        context.rootState.selectedChannel._id
      )
    ) {
      context.commit("updateVistedChannelList", {
        channel_id: context.rootState.selectedChannel._id,
        isAdd: true,
      });
      let requestBody = {};
      requestBody.channel_id = context.rootState.selectedChannel._id;
      requestBody.company_id = context.rootState.selectedCompanyId;
      if (context.state.unReadThreads.length) {
        requestBody.thread_replies = context.state.unReadThreads;
      }
      if (context.rootGetters.getCurrentMessages.length) {
        //find first sent message
        let sentMessage = context.rootGetters.getCurrentMessages.find(
          (message) => !message._id.includes("Temp")
        );
        if (sentMessage) {
          requestBody.message_id = sentMessage._id;
        }
      } else {
        requestBody.message_id = null;
      }
      let token = JSON.parse(localStorage.getItem("token"));
      let customAxios = axios.create({
        headers: {
          Token: token,
        },
      });
      customAxios
        .post(
          process.env.VUE_APP_BASE_URL + "/polling/selectedChannelPolling",
          requestBody
        )
        .then(async (response) => {
          for (const message of response.data.messages) {
            await context.dispatch("callSocketsActions", {
              name: "socket_newMessage",
              message,
            });
          }
          for (const message of response.data.thread_messages) {
            context.commit("addUnThread", message.data._id);
            await context.dispatch("callSocketsActions", {
              name: "socket_newReplyMessage",
              message,
            });
          }
          context.commit("updateVistedChannelList", {
            channel_id: response.data.channel_id,
            isAdd: false,
          });
          if (
            response.data.channel_id == context.rootState.selectedChannel._id
          ) {
            context.state.timeOutPolling = setTimeout(() => {
              context.dispatch("selectedChannelPolling");
            }, 5000);
          }
        })
        .catch((error) => {
          if (error.response && error.response.status == 401) {
            context.dispatch("logOut");
          } else {
            context.commit("updateVistedChannelList", {
              channel_id: requestBody.channel_id,
              isAdd: false,
            });
            context.state.timeOutPolling = setTimeout(() => {
              context.dispatch("selectedChannelPolling");
            }, 5000);
          }
        });
    }
  },
  callSocketsActions(context, data) {
    setTimeout(() => {
      context.dispatch(data.name, data.message);
    }, 1000);
  },
};

const mutations = {
  clearSelectedChannelPollingState(state) {
    state.unReadThreads = [];
  },
  addUnThread(state, payload) {
    if (!state.unReadThreads.includes(payload)) {
      state.unReadThreads.push(payload);
    }
  },
  updateVistedChannelList(state, payload) {
    if (payload.isAdd) {
      state.vistedChannelList.push(payload.channel_id);
    } else {
      state.vistedChannelList = state.vistedChannelList.filter(
        (channelId) => channelId != payload.channel_id
      );
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
