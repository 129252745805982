/**
 * setting the data to be display to user in notifications.
 * @param {*} payload
 */
let getAlertMessage = function(payload) {
  let message = payload.data;
  if (message.audio_video_file) {
    if (message.audio_video_file.split(".").pop() == "mp3") {
      return "Voice Message";
    } else if (message.audio_video_file.split(".").pop() == "mp4") {
      return "Video Message";
    }
  } else if (message.attachments) {
    return "File Attachments";
  } else if (message.webhook_message) {
    return "Webhook message";
  } else if (message?.gifs_data?.length) {
    return "Gif Received";
  } else {
    return message.message;
  }
};
var messageSocket = {
  state: {
    newMessageNotifaction: false,
    newMessageSound: false,
    newMessageText: null,
    newMessageSender: null,
    channelName: "temp",
    redirectCompanyId: null,
    redirectTeamId: null,
    redirectChannelId: null,
    reminderOf: null,
    reminderNotification: {},
    channelTypeforInApp: null,
  },
  getters: {
    getRedirectData(state) {
      return {
        redirectCompanyId: state.redirectCompanyId,
        redirectTeamId: state.redirectTeamId,
        redirectChannelId: state.redirectChannelId,
        redirectChannelType: state.redirectChannelType,
        redirectReminderOf: state.reminderOf,
      };
    },
    getNewMessageNotifaction: (state) => state.newMessageNotifaction,
    getNewMessageSound: (state) => state.newMessageSound,
    getNewMessageText: (state) => state.newMessageText,
    getNewMessageSender: (state) => state.newMessageSender,
    getChannelName: (state) => state.channelName,
    getReminderNotification: (state) => state.reminderNotification,
    getChannelTypeforInApp: (state) => state.channelTypeforInApp,
  },
  actions: {},
  mutations: {
    /**
     * this mutation is responsible for setting data for in app notificitions.
     * (1): in first if it checks the the sender_id is should not be loginuser. user should be on different channel and channel should joined by user and he must be in same company also channel should be unmuted.
     * (2): in first inner if notifications on every message will be displayed.
     * (3): in second inner if notification will be display to user with out sound.
     * (4): in third inner if no notification will be display to user also no sound.
     * (5) : then it checks if user has mention and direct message setting.
     * @param {*} state
     * @param {*} payload
     */
    setNotification(state, payload) {
      if ("reminder_of" in payload.data) {
        state.reminderOf = payload.data.reminder_of;
        state.reminderNotification = payload;
      }
      if (payload.channel) {
        let senderId = payload.data.sender_id;
        payload.channel.is_displayed = true;
        let supportTest;
        if (payload.channel.type == "support") {
          supportTest = true;
        } else if (payload.channel.joined) {
          supportTest = true;
        } else {
          supportTest = false;
        }
        if (
          payload.selectedChannel &&
          payload.loginUserDetail._id != payload.data.sender_id &&
          (payload.channel._id != payload.selectedChannel._id ||
            (payload.channel._id == payload.selectedChannel._id &&
              document.hidden)) &&
          supportTest &&
          (payload.channel.type == "support" ||
            payload.selectedCompanyId == payload.company._id) &&
          !payload.channel.muted
        ) {
          state.newMessageText = getAlertMessage(payload);
          state.redirectCompanyId = payload.company._id;
          state.redirectChannelType = payload.channel.type;
          state.channelTypeforInApp = payload.channel.type;
          payload.channel.type == "direct" || payload.channel.type == "group"
            ? (state.redirectTeamId = payload.selectedTeamId)
            : (state.redirectTeamId = payload.teamId);
          state.redirectChannelId = payload.channel._id;
          state.channelName = payload.channel.name;
          state.newMessageSender = senderId;
          if (
            payload.companyUser.in_app_notification == 2 &&
            payload.companyUser.notification_sound == 1 &&
            !payload.loginUserDetail.do_not_disturb
          ) {
            state.newMessageNotifaction = true;
            state.newMessageSound = true;
          } else if (
            payload.companyUser.in_app_notification == 2 &&
            payload.companyUser.notification_sound == 0 &&
            !payload.loginUserDetail.do_not_disturb
          ) {
            state.newMessageNotifaction = true;
            state.newMessageSound = false;
          } else if (
            payload.companyUser.in_app_notification == 0 &&
            payload.companyUser.notification_sound == 0
          ) {
            state.newMessageNotifaction = false;
            state.newMessageSound = false;
          } else if (
            payload.companyUser.in_app_notification == 1 &&
            payload.companyUser.notification_sound == 1 &&
            !payload.loginUserDetail.do_not_disturb
          ) {
            if (payload.data.message != null) {
              if (
                (payload.data.message.includes(
                  `@${payload.loginUserDetail.name}`
                ) ||
                  payload.data.message.includes(`@channel`) ||
                  payload.data.message.includes(`@here`) ||
                  payload.channel.type == "direct") &&
                payload.channel.muted == false
              ) {
                state.newMessageNotifaction = true;
                state.newMessageSound = true;
              }
            }
          } else if (
            payload.companyUser.in_app_notification == 1 &&
            payload.companyUser.notification_sound == 0 &&
            !payload.loginUserDetail.do_not_disturb
          ) {
            if (payload.data.message != null) {
              if (
                (payload.data.message.includes(
                  `@${payload.loginUserDetail.name}`
                ) ||
                  payload.data.message.includes(`@channel`) ||
                  payload.data.message.includes(`@here`) ||
                  payload.channel.type == "direct") &&
                payload.channel.muted == false
              ) {
                state.newMessageNotifaction = true;
                state.newMessageSound = false;
              }
            }
          }
        }
      }
    },
    setNewMessageNotifaction(state, payload) {
      state.newMessageNotifaction = payload;
      state.reminderNotification = {};
    },
    setReminderNotification(state, payload) {
      state.reminderNotification = payload;
    },
    setNewMessageSound(state, payload) {
      state.newMessageSound = payload;
    },
    setNewMessageText(state, payload) {
      state.newMessageText = payload;
    },
  },
};
export default messageSocket;
