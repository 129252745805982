/**
System: Whistle It
Developer: Hamid Hussain
Organization: Whistle It
Purpose: This file is responsible for handling all the store functionality for poll messages.
 */
import messageAPI from "@/api/messageAPI";
import debug from "@/console";
import axios from "axios";
// import Vue from "vue";
// import messageBody from "./messageBody";
// import { eventBus } from "@/main";
/**
 * this function returns the data object that will pass to socket.
 * args : (payload)
 */
var pollStore = {
  getters: {},
  actions: {
    sendPollMessage(
      { getters, state, rootState, rootGetters, dispatch, commit },
      payload
    ) {
      return new Promise((resolve, reject) => {
        var done = (response) => {
          if (response.status == 200) {
            // messageBody.poll_id = response._id

            let messageBody = {
              _id: `Temp + ${Math.floor(Math.random() * 90000) +
                10000}${Date.now()}${Math.floor(Math.random() * 90000) +
                10000}`,
              is_forwarded: 0,
              is_edited: 0,
              is_read: [],
              is_joined: 0,
              deleted_at: null,
              updated_at: null,
              delete_after: null,
              user_time: new Date().toUTCString(),
              created_at: new Date().toUTCString(),
              pinned_by: [],
              replied_ids: [],
              replying_id: null,
              replied_created_at: new Date().toUTCString(),
              reactions: [],
              reminded_by: [],
              poll_id: response.data._id,
              poll_data: payload.obj,
              sender_id: getters.loggedInUserDetails._id,
              announcement: payload.obj.announcement
                ? payload.obj.announcement
                : false,
              shared_company_ids: payload.messageBody
                ? payload.messageBody.shared_company_ids
                : undefined,
              shared_team_ids: payload.messageBody
                ? payload.messageBody.shared_team_ids
                : undefined,
              shared_users_ids: payload.messageBody
                ? payload.messageBody.shared_users_ids
                : undefined,
              maxRetry: false,
            };

            //store reference of previous clicked channel if user immediatly changed channel after send message then update his/her message status after API response
            let previousMessages = getters.getCurrentMessages;
            //if files are attached then make form data object of message
            // let fileMessage = await dispatch("makeFormDataOfFiles", messageBody);
            let channelId = rootState.selectedChannel._id;
            let teamId = rootGetters.getSelectedTeam._id;
            let channel = rootGetters.getSelectedChannel;
            if (payload.obj.channel_id) {
              channelId = payload.obj.channel_id;
              previousMessages = rootGetters.getMessagesByChannelId(
                payload.obj.channel_id
              );
              channel = rootGetters.getChannelByCompanyIdChannelId(
                payload.obj.company_id,
                payload.obj.channel_id
              );
            }
            if (payload.obj.team_id) {
              teamId = payload.obj.team_id;
            }
            //add new message in state
            commit("addNewMessage", {
              currentMessages: previousMessages,
              message: messageBody,
            });
            // success callback of adding message
            var done = async (response) => {
              //get message index from store Data
              let index = await getters.getSendMessageIndex(
                messageBody._id,
                response.data != undefined ? response.data._id : null,
                previousMessages
              );

              if (response.status == 200) {
                resolve(response);
                if (index != -1) {
                  commit("updateMsgAfterResponse", {
                    currentMessages: previousMessages,
                    index,
                    id: response.data._id,
                    data: response.data,
                    poll_data: response.data.poll_data,
                    channel,
                  });
                  //if messages length is greater then 20, remove last message
                }
              } else {
                reject(response);
                //on message conflict not remove message only update message info
                if (response.response.status == 409) {
                  dispatch("conflictMessageHandling", {
                    currentMessages: previousMessages,
                    index,
                    messageBody,
                    channelId,
                    channel,
                  });
                } else if (response.response.status != 401) {
                  //handle error in sending message (500: internal server error, 400: not send required fields)
                  commit("removeBadRequest", {
                    currentMessages: previousMessages,
                    index,
                  });
                }
              }
            };
            messageAPI.sendMessage(
              channelId,
              teamId,
              messageBody.audio,
              messageBody.video,
              (messageBody.message = response.data.title),
              messageBody.is_important,
              messageBody.send_after_seconds,
              messageBody.files,
              messageBody.mention_users,
              messageBody._id,
              undefined,
              undefined,
              messageBody.user_time,
              messageBody.support_message,
              messageBody.announcement,
              messageBody.shared_company_ids,
              messageBody.shared_team_ids,
              messageBody.shared_users_ids,
              (messageBody.poll_id = response.data._id),
              undefined,
              rootGetters.getSelectedChannel.invited,
              rootGetters.getSelectedChannel.secret_key,
              undefined,
              undefined,
              messageBody,
              done
            );
          } else {
            reject(response);
          }
        };
        messageAPI.sendPollMessage(payload.obj, done);
      });
    },

    submitPollResponse({ state, rootState, rootGetters }, payload) {
      return new Promise((resolve, reject) => {
        var done = (response) => {
          if (response.status == 200) {
            // messageBody.poll_id = response._id
            resolve(response);
          } else {
            reject(response);
          }
        };
        messageAPI.submitPollResponse(payload, done);
      });
    },
    PollResult({ state, rootState, rootGetters }, payload) {
      return new Promise((resolve, reject) => {
        var done = (response) => {
          if (response.status == 200) {
            // messageBody.poll_id = response._id
            resolve(response);
          } else {
            reject(response);
          }
        };
        messageAPI.getPollResult(payload, done);
      });
    },
    PollQuestions({ state, rootState, rootGetters }, payload) {
      return new Promise((resolve, reject) => {
        var done = (response) => {
          if (response.status == 200) {
            // messageBody.poll_id = response._id
            resolve(response);
          } else {
            reject(response);
          }
        };
        messageAPI.getPollQuestions(payload, done);
      });
    },
    PollClose(context, payload) {
      return new Promise((resolve, reject) => {
        var done = (response) => {
          if (response.status == 200) {
            resolve(response);
            // messageBody.poll_id = response._id
            context.commit("CLOSE_POLL", payload);
          } else {
            reject(response);
          }
        };
        messageAPI.PollClose(payload, done);
      });
    },
    getPollsByAPI(context, requestBody) {
      return new Promise((resolve, reject) => {
        var done = (response) => {
          if (response.status == 200) {
            resolve(response);
          } else {
            reject(response);
          }
        };
        messageAPI.getPollsByAPI(requestBody, done);
      });
    },
  },
  mutations: {
    CLOSE_POLL(state, payload) {
      payload.poll.poll_status = "closed";
    },
  },
  state: {},
};

export default pollStore;
