import axios from "axios";
import router from "@/router";
import debug from "@/console";

const state = {
  signedUp: null,
};
const actions = {
   /**
   * Registers a new user with the provided credentials.
   * Sends a POST request to the signup endpoint with the user's details.
   * If successful, commits the user's data to the state and redirects to the thank-you page.
   * If an error occurs, the promise is rejected with the error details.
   * 
   * @param {Object} context - The Vuex context object (contains commit, dispatch, state, etc.).
   * @param {Object} body - The user's registration details (email, password, etc.).
   * @returns {Promise} A promise that resolves with the server response or rejects with an error.
   */
  register(context, body) {
    debug.log("inregister");
    return new Promise((resolve, reject) => {
      axios
        .post(process.env.VUE_APP_BASE_URL + "/signup", body)
        .then((response) => {
          if (response.status == 200) {
            context.commit("SET_SIGNED_UP", body);
            router.push("/thankyou");
            resolve(response);
          }
        })
        .catch((error) => {
          debug.log("in error", error);
          reject(error);
        });
    });
  },
};
const mutations = {
  /**
   * Commits the newly registered user's data to the state.
   * 
   * @param {Object} state - The Vuex state object.
   * @param {Object} data - The user's registration details.
   */
  SET_SIGNED_UP(state, data) {
    state.signedUp = data;
  },
};
/**
   * Retrieves the signed-up user's data from the state.
   * 
   * @param {Object} state - The Vuex state object.
   * @returns {Object|null} The signed-up user's data or null if not available.
   */
const getters = {
  getSignedUp: (state) => state.signedUp,
};

export default {
  state,
  actions,
  mutations,
  getters,
};
