const getters = {
    getUserWithCompany: (getters, state, rootState, rootGetters) => (
      companyID,
      userID
    ) => {
      let company = rootGetters.getCompanies.find((company) => {
        if (
          company._id == companyID &&
          company._id != rootGetters.getSelectedCompany
        ) {
          return company;
        }
      });
      let users = company.users.find((user) => {
        if (user._id == userID) {
          return user;
        }
      });
      if (users) {
        return users;
      }
    },
  };
  export default {
    getters,
  };
  