import store from "@/store/index";

(function() {
  var fileService = {
    startFilesProcessing: function() {
      store.state.preservedMsgs.forEach((message) => {
        if (!message?.filesContent?.length) {
          this.checkAllFilesUploaded(message._id, {});
          return;
        }
        let promiseArray = [];
        //resumable object created
        message.filesContent.forEach(async (file) => {
          // If the "recent_file" property of the current "file" object is false,
          // Push the result of the "uploadFile" function with the current "file" as an argument
          // into the "promiseArray" to store pending upload promises.
          if (Object.keys(file.fileResponse).length) {
            return;
          }
          file.resumableObj = await this.createResumableObj(file.resumableData);
          promiseArray.push(this.uploadFile(file, message._id));
        });
        //wait for all files uploaded and send emit to unable send masg btn
        Promise.allSettled(promiseArray).then((res) => {
          console.log("in all setteled", res);
        });
      });
    },
    uploadFile: function(singleFile, msgId) {
      return new Promise((resolve, reject) => {
        singleFile.file.uId = singleFile.uId;
        singleFile.resumableObj.addFile(singleFile.file);
        singleFile.resumableObj.on("fileAdded", function(file) {
          // trigger when file added
          singleFile.resumableObj.upload();
        });
        singleFile.resumableObj.on("fileProgress", function(file) {
          singleFile.progress.value = file.progress() * 100;
        });
        singleFile.resumableObj.on("fileError", function(file, message) {
          reject(file.file);
          //if token expired
          if (
            message &&
            JSON.parse(message).error &&
            JSON.parse(message).error.error &&
            JSON.parse(message).error.error[0] == "Token not authenticated."
          ) {
            // logout from application
            store.dispatch("logOut");
          }
        });

        singleFile.resumableObj.on(
          "fileSuccess",
          (
            file,
            response // trigger when file uploaded sucessfully
          ) => {
            let jsonResponse = JSON.parse(response);
            // if (!jsonResponse?.file) {
            //   return;
            // }
            jsonResponse.file.uId = file.file.uId;
            //save API response in file object
            singleFile.fileResponse = jsonResponse.file;
            this.checkAllFilesUploaded(msgId, jsonResponse);
            resolve(jsonResponse);
          }
        );
      });
    },
    // Create resumable object
    createResumableObj: function(data) {
      return new Promise((resolve) => {
        resolve(
          new window.Resumable({
            ...data,
            testChunks: false,
            throttleProgressCallbacks: 1,
            simultaneousUploads: 3,
            generateUniqueIdentifier: (file) => {
              let randNumberFront = Math.floor(Math.random() * 90000) + 10000;
              let randNumberRear = Math.floor(Math.random() * 90000) + 10000;
              return `Temp + ${randNumberFront}${Date.now()}${randNumberRear}`;
            },
          })
        );
      });
    },
    checkAllFilesUploaded: function(msgId, jsonResponse) {
      let msgIndex = store.state.preservedMsgs.findIndex(
        (msg) => msg._id == msgId
      );
      if (msgIndex > -1) {
        let files = store.state.preservedMsgs[msgIndex].filesContent.filter(
          (file) => Object.keys(file.fileResponse).length
        );
        store.commit("UPDATE_FILES_OF_SPECIFIC_MESSAGE", {
          jsonResponse,
          msgIndex,
        });
        if (
          files.length ==
          store.state.preservedMsgs[msgIndex].filesContent.length
        ) {
          try {
            // replace url
            store.state.preservedMsgs[msgIndex].attachments.forEach(
              (file) =>
                (file.path = file.path
                  .replace(`${process.env.VUE_APP_BASE_URL}/getFile/`, "")
                  .replace(
                    `${process.env.VUE_APP_BASE_URL}/viewMessageFile/`,
                    ""
                  ))
            );
            store.state.preservedMsgs[msgIndex].files =
              store.state.preservedMsgs[msgIndex].attachments;
            if (store.state.preservedMsgs[msgIndex]?.replying_id) {
              store.state.preservedMsgs[msgIndex].isReplyMsgAlreadyAdded = true;
              store.dispatch(
                "replyMessage",
                store.state.preservedMsgs[msgIndex]
              );
            } else {
              store.state.preservedMsgs[msgIndex].isMsgAlreadyAdded = true;
              store.dispatch(
                "sendMessageStore",
                store.state.preservedMsgs[msgIndex]
              );
            }
          } catch (error) {
            console.log("error:::", error);
          }

          store.commit("REMOVE_MSG_FROM_INSTANT_MSGS_LIST", {
            msgId: store.state.preservedMsgs[msgIndex]._id,
          });
        }
      }
    },
    makeURLConfig: function(channelId, file) {
      return {
        query: {
          company_id: file.companyId,
          channel_id: channelId,
          secret_key: file.secret_key,
          upload_id: store.getters.getLoggedInUserDetail._id,
          is_recording: file?.isRecording ? true : false,
        },
        target: `${process.env.VUE_APP_BASE_URL}${"/uploadFiles"}`,
        headers: {
          Accept: "application/json",
          token: JSON.parse(localStorage.getItem("token")),
        },
      };
    },
  };
  // Browser: Expose to window
  window.fileService = fileService;
})();
