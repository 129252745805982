import customAppsAPI from "@/api/customAppsAPI";
const actions = {
  /*
    Responsible to update custom app
    args:(context, payload)
    */
  readApps(context, payload) {
    return new Promise((resolve, reject) => {
      customAppsAPI.readApps(payload, (response) => {
        if (response.status == 200) {
          resolve(response);
        } else {
          reject(response);
        }
      });
    });
  },
  /*
    Responsible to create new custom app to send and receive messages
    args:(context, payload)
    */
  createNewApp(context, payload) {
    return new Promise((resolve, reject) => {
      customAppsAPI.createNewApp(payload, (response) => {
        if (response.status == 201) {
          resolve(response);
        } else {
          reject(response);
        }
      });
    });
  },
  /*
    Responsible to update custom app
    args:(context, payload)
    */
  updateApp(context, payload) {
    return new Promise((resolve, reject) => {
      customAppsAPI.updateApp(payload, (response) => {
        if (response.status == 200) {
          resolve(response);
        } else {
          reject(response);
        }
      });
    });
  },
  /*
    Responsible to remove custom app
    args:(context, payload)
    */
  removeApp(context, payload) {
    return new Promise((resolve, reject) => {
      customAppsAPI.removeApp(payload, (response) => {
        if (response.status == 200) {
          resolve(response);
        } else {
          reject(response);
        }
      });
    });
  },
};

export default {
  actions,
};
