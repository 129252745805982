import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";
import localForage from "localforage";
import companySocket from "@/store/modules/sockets/companySocket";
import channelSocket from "@/store/modules/sockets/channelSocket";
import teamSocket from "@/store/modules/sockets/teamSocket";
import userSocket from "@/store/modules/sockets/userSocket";
import departmentSocket from "@/store/modules/sockets/DepartmentSocket";
import messageSocket from "@/store/modules/sockets/messageSocket";
import messageStore from "@/store/modules/messageStore.js";
import home from "@/store/modules/home.js";
import AdminStore from "@/store/modules/AdminStore.js";
import debug from "@/console";
import login from "./modules/userStore/Login.js";
import signUp from "./modules/userStore/Signup.js";
import inAppNotifications from "./modules/sockets/inAppNotifications";
import inputState from "./modules/inputState.js";
import messageReminder from "./modules/MessageStore/messageReminder";
import messageReadSocket from "./modules/sockets/messageReadSocket";
import selectedChannelPoling from "./modules/Polling/selectedChannelPoling";
import WebHook from "./modules/WebHook/WebHook";
import axios from "axios";
import searchchannel from "./modules/searchChannels/searchChannel";
import pluginStore from "./modules/PluginStore/PluginStore.js";
import pollStore from "./modules/Poll/PollMessages.js";
import PaymentStore from "./modules/PaymentStore/Payment.js";
import channelPolling from "@/store/modules/Polling/channelPolling.js";
import globalSearchStore from "@/store/modules/globalSearchStore.js";

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  reducer: (state) => ({
    selectedCompanyId: state.selectedCompanyId,
    selectedTeamId: state.selectedTeamId,
    selectedChannel: state.selectedChannel,
    prevTeamsSelectChannel: state.prevTeamsSelectChannel,
    prevDispDirectChannels: state.prevDispDirectChannels,
  }), //only save navigation module
  // filter: (mutation) => mutation.type == 'addNavItem'
});

const vuexIndexedDB = new VuexPersistence({
  key: "APP_DATA",

  //storage object will make sure that the data will be stored in Indexed DB
  storage: {
    /**
     * this function gets value ("messages") against the key (messages array) from Indexed DB.
     * args: (key, value)
     */
    getItem: async (key) => {
      return await localForage.getItem(key).then(function (value) {
        debug.log(value);
        return value;
      });
    },
    /**
     * this function sets the key ("messages") and value (messages array) in Indexed DB.
     * args: (key, value)
     */
    setItem: (key, value) =>
      localForage
        .setItem(key, value)
        .then(function () {
          return localForage.getItem(key);
        })
        .catch(function (err) {
          // we got an error
          debug.log(err);
        }),
    /**
     * this function removes the key ("messages") and value (messages array) in Indexed DB.
     * args: (key, value)
     */
    removeItem: (key) =>
      localForage
        .removeItem(key)
        .then(function () {
          // Run this code once the key has been removed.
          debug.log("Key is cleared!");
        })
        .catch(function (err) {
          // This code runs if there were any errors
          debug.log(err);
        }),
    clear: () =>
      localForage
        .clear()
        .then(function () {
          debug.log("Database is now empty.");
        })
        .catch(function (err) {
          debug.log(err);
        }),
  },
  //Notifing store's plugin that this function is async function.
  asyncStorage: true,
  //registring messages in Indexed DB modules so messages state will save in indexed DB.
  reducer: (state) => ({
    storeData: state.storeData,
    preservedMsgs: state.preservedMsgs,
    notSentFiles: state.notSentFiles,
    isAdmin: state.isAdmin,
    queue: state.queue,
    appVersion: state.appVersion,
    newRelease: state.newRelease,
  }), //only save navigation module
});

const store = new Vuex.Store({
  plugins: [vuexIndexedDB.plugin, vuexLocal.plugin],
  state: {
    storeData: null,
    queue: [],
    signedUp: null,
    notifToken: "null",
    isAdmin: null,
    newRelease: false,
    appVersion: null,
    messages: {},
    selectedCompanyId: null,
    selectedTeamId: null,
    selectedChannel: null,
    prevTeamsSelectChannel: [],
    prevDispDirectChannels: [],
    audioMessageURL: [], //Reaming Audio message is here which will be display on header
    remainingAudioMessageURLandTime: [], //pick the audio url and time on removing Audio component on top right
    instantMessages: [], // for instant messages
    notSentFiles: {},
    preservedMsgs: [],
    isMobile: false
  },
  actions: {
    checkAppVersion(context, data) {
      let appVer = localStorage.getItem("App_Version");
      context.commit("changeAppVersion", {
        appVer,
        data,
      });
    },
    /**
     * this function is responsible to send fcm token when user logged in
     * args:(fcm_token)
     */
    async sendPushNotifToken(context, data) {
      let token = JSON.parse(localStorage.getItem("token"));
      let customAxios = axios.create({
        headers: {
          Token: token,
        },
      });

      customAxios
        .post(process.env.VUE_APP_BASE_URL + "/createDeviceToken", {
          fcm_token: data.token,
          platform: data.platform,
          version: data.version,
        })
        .then(() => {
          context.commit("setNotifToken", data.token);
        })
        .catch((err) => {
          debug.log("err", err);
        });
    },
    /**
     * this function is responsible to send new fcm token when it is expired
     * args:(data) //user id, company id, channel id, channel name, fcm_token & platform
     */
    sendRefreshedNotifToken(context, data) {
      let token = JSON.parse(localStorage.getItem("token"));
      let customAxios = axios.create({
        headers: {
          Token: token,
        },
      });

      if (!localStorage.getItem("mobile_token")) {
        data.platform = "web";
      }

      customAxios
        .post(process.env.VUE_APP_BASE_URL + "/pushNotification/update", data)
        .then(() => {
          // context.commit("setNotifToken", data.fcm_token);
        });
    },
  },

  mutations: {
    setIsMobile(state, payload) {
      state.isMobile = payload
    },
    setCurrentRemainingAudio(state, remainingLiveAudioState) {
      state.remainingAudioMessageURLandTime = { ...remainingLiveAudioState };
    },

    setCurrentLiveAudioState(state, CurrentLiveAudioState) {
      state.audioMessageURL = { ...CurrentLiveAudioState };
    },

    clearChannelMessage: (state) => {
      state.messages = {};
    },
    setMessages: (state, data) => {
      let queueMessage = [];
      let instantMsgs = [];

      state.queue.forEach((queueItem) => {
        if (
          (queueItem.endpoint == "message/create" ||
            queueItem.endpoint == "message/sendGuestMessage") &&
          queueItem.body.requestBody.channel_id == data.channel_id &&
          (queueItem.body.messageBody?.replying_id == null ||
            queueItem.body.messageBody?.replying_id == undefined)
        ) {
          queueMessage.unshift(queueItem.body.messageBody);
        }
      });
      //append instant state message for display in message area on selected channel messages read
      if (state.instantMessages.length) {
        state.instantMessages.forEach((msg) => {
          if (msg.channel_id == data.channel_id && !msg.replying_id) {
            instantMsgs.unshift(msg);
          }
        });
      }
      // add preserved messages
      if (state.preservedMsgs.length) {
        state.preservedMsgs.forEach((msg) => {
          let foundMsg = state.instantMessages.find(
            (instantMsg) => instantMsg._id == msg._id
          );
          if (
            !foundMsg &&
            msg.channel_id == data.channel_id &&
            !msg.replying_id
          ) {
            instantMsgs.unshift(msg);
          }
        });
      }
      if (queueMessage.length) {
        Vue.set(state.messages, data.channel_id, [
          ...instantMsgs,
          ...queueMessage,
          ...data.messages[data.channel_id],
        ]);
      } else {
        // add instant messages to array
        if (instantMsgs.length) {
          data.messages[data.channel_id] = instantMsgs.concat(
            data.messages[data.channel_id] ?? []
          );
        }
        Vue.set(
          state.messages,
          data.channel_id,
          data.messages[data.channel_id]
        );
      }
      let keys = Object.keys(state.messages); // get an array of keys
      if (keys.length > (state.isMobile ? 5 : 10)) {
        delete state.messages[keys[0]]; // delete the first key
      }
    },
    APP_DATA: (state, data) => {
      state.storeData = data;
      let company = data.companies[0];

      state.selectedCompanyId = company._id;
      let team = company.teams.find(
        (team) => team.joined == true && team.archived == null
      );
      if (team) {
        state.selectedTeamId = team._id;
        state.selectedChannel = company.channels.find(
          (channel) =>
            channel.joined == true &&
            channel.archived == null &&
            channel.team_id == team._id
        );
      }
    },

    logout() {
      //clear session storage
      sessionStorage.clear();
      //clear local storage
      localStorage.clear();
      //clear all caches
      caches.keys().then((keys) => {
        keys.forEach((key) => caches.delete(key));
      });
      //clear all DB'S
      indexedDB.databases().then((dbs) => {
        dbs.forEach((db) => indexedDB.deleteDatabase(db.name));
      });
      localStorage.setItem("logout-event", "logout" + Math.random());
    },

    //pushes the provided request in to the queue
    pushRequest(state, request) {
      state.queue.push(request);
    }, //pops the request with provided id, out of the queue
    popRequest(state, reqId) {
      state.queue = state.queue.filter((item) => {
        return item.id != reqId;
      });
    },
    updateQueue(state, payload) {
      let index = state?.queue?.findIndex((item) => {
        return item.id == payload.id;
      });

      if (index > -1) {
        state.queue[index].body.messageBody.maxRetry = payload.maxRetry;
      }
    },
    updateMessages(state, payload) {
      let index = state.messages[
        payload?.request?.body?.requestBody?.channel_id
      ]?.findIndex((item) => {
        return item._id == payload.request.body.requestBody._id;
      });
      if (index > -1) {
        state.messages[payload.request.body.requestBody.channel_id][
          index
        ].maxRetry = payload.maxRetry;
      }
    },
    clearQueue(state) {
      state.queue = [];
    },
    setNotifToken(state, payload) {
      state.notifToken = payload;
    },
    changeAppVersion(state, payload) {
      if (payload.appVer != null) {
        if (payload.appVer != payload.data) {
          state.newRelease = true;
          state.appVersion = payload.data;
        } else {
          state.newRelease = false;
        }
      } else {
        state.newRelease = true;
        state.appVersion = payload.data;
      }
    },
    setSelectedCompany: (state, companyId) => {
      state.selectedCompanyId = companyId;
    },
    setSelectedTeam: (state, teamId) => {
      if (state.selectedTeamId != teamId) state.selectedTeamId = teamId;
    },
    setSelectedChannel: (state, channel) => {
      if (!state.selectedChannel || state.selectedChannel?._id != channel._id)
        state.selectedChannel = channel;
    },
    // Unselects channel
    clearSelectedChannel: (state) => {
      state.selectedChannel = null;
    },
    updatePreTeamsChannel: (state, payload) => {
      state.prevTeamsSelectChannel.push(payload);
    },
    //check if a team state is already in array then remove previous
    checkAndRmvPreTeamsChannel: (state, payload) => {
      state.prevTeamsSelectChannel = state.prevTeamsSelectChannel.filter(
        (team) => team.teamId != payload
      );
    },
    // this mutation / function is responsible to update the audio speed in storeData
    setUserAudioSpeed(state, audioSpeed) {
      state.storeData.audio_speed = audioSpeed;
    },
    // update the channel message when any of the error file generated
    UPDATE_CHANNEL_MSG_FILES(state, payload) {
      if (state.messages[payload.channelId]) {
        let channelMessages = [...state.messages[payload.channelId]];
        const messageIndex = channelMessages.findIndex(
          (message) => message._id === payload.newMessage._id
        );
        if (messageIndex > -1) {
          channelMessages[messageIndex] = payload.newMessage;
          state.messages[payload.channelId] = channelMessages;
        }
      }
    },
    // remove the message from the channel message state
    REMOVE_MSG_FROM_CHANNEL_MSGS(state, payload) {
      if (state.messages[payload.channelId]) {
        let channelMessages = [...state.messages[payload.channelId]];
        const messageIndex = channelMessages.findIndex(
          (message) => message._id === payload.removeMessage._id
        );
        if (messageIndex > -1) {
          channelMessages.splice(messageIndex, 1);
          state.messages[payload.channelId] = channelMessages;
        }
      }
    },
    // update the replied count for parent message
    UPDATE_PARENT_REPLY_COUNT(state, payload) {
      if (state.messages[payload.message.channel_id]) {
        let channelMessages = [...state.messages[payload.message.channel_id]];
        const messageIndex = channelMessages.findIndex(
          (message) => message._id === payload.message.replying_id
        );
        if (messageIndex > -1) {
          channelMessages[messageIndex].replied_ids.push(payload.message._id);
          channelMessages[
            messageIndex
          ].replied_created_at = new Date().toUTCString();
          state.messages[payload.message.channel_id] = channelMessages;
        }
      }
    },
    // set the instant message in state
    SET_INSTANT_MSG(state, instantMsg) {
      state.instantMessages.push(instantMsg);
    },
    // remove the error file from the instant message state
    REMOVE_ERROR_FILE_FROM_INSTANT_MSGS(state, payload) {
      state.instantMessages.forEach((instantMsg) => {
        let errorFileIndex = instantMsg?.files?.findIndex(
          (instantFile) => instantFile.uId == payload.removeFile.uId
        );
        if (errorFileIndex >= 0) {
          instantMsg?.files?.splice(errorFileIndex, 1);
          instantMsg?.attachments?.splice(errorFileIndex, 1);
        }
      });
    },
    // remove the message from the instant message state
    REMOVE_MSG_FROM_INSTANT_MSGS(state, payload) {
      let instantMsgIndex = state.instantMessages.findIndex(
        (instantMsg) => instantMsg._id == payload.removeMessage._id
      );
      if (instantMsgIndex >= 0) {
        state.instantMessages.splice(instantMsgIndex, 1);
      }
      // remove from preserved msgs
      instantMsgIndex = state.preservedMsgs.findIndex(
        (instantMsg) => instantMsg._id == payload.removeMessage._id
      );
      if (instantMsgIndex >= 0) {
        state.preservedMsgs.splice(instantMsgIndex, 1);
      }
    },
    // append instant state message for display in message area on selected channel messages read
    UPDATE_FILES_OF_SPECIFIC_MESSAGE(state, payload) {
      let fileIndex = state.preservedMsgs[
        payload.msgIndex
      ].attachments.findIndex(
        (attachment) => attachment.uId == payload?.jsonResponse?.file?.uId
      );
      if (fileIndex > -1) {
        let newMsg = state.preservedMsgs[payload.msgIndex];

        newMsg.attachments[fileIndex] = payload.jsonResponse.file;

        Object.assign(state.preservedMsgs[payload.msgIndex], newMsg);
      }
    },
    REMOVE_MSG_FROM_INSTANT_MSGS_LIST(state, payload) {
      let msgIndex = state.preservedMsgs.findIndex(
        (msg) => msg._id == payload.msgId
      );
      if (msgIndex > -1) {
        state.preservedMsgs.splice(msgIndex, 1);
      }
    },
    PRESERVE_NOT_SENT_FILES(state, payload) {
      if (payload.filesContent.length) {
        let formattedFiles = [];
        payload.filesContent.forEach((file) => {
          formattedFiles.push({
            file,
            uId: file.uId,
            progress: file.progress,
            fileResponse: file.fileResponse ?? {},
            companyId: payload.companyId,
            channelId: payload.channelId,
            secretKey: payload.channelSecretKey,
            is_recording: file.is_recording,
          });
        });
        payload.filesContent = formattedFiles;

        state.notSentFiles[payload.channelId] = payload;
      } else if (state.notSentFiles[payload.channelId]) {
        delete state.notSentFiles[payload.channelId];
      }
    },
    REMOVE_CHANNEL_MAIN_FILES(state, payload) {
      if (state.notSentFiles[payload.channelId]) {
        state.notSentFiles[payload.channelId].filesContent = [];
        state.notSentFiles[payload.channelId].uploadedFilesResponses = [];
      }
    },
    /**
     * this mutation is responsible to preserve files which are attached
     * args:(state, payload)
     */
    SAVE_NOT_SENT_FILES(state, payload) {
      // initialize if the files are first time being uploaded
      if (!state.notSentFiles[payload.channelId]) {
        state.notSentFiles[payload.channelId] = {
          filesContent: [],
          uploadedFilesResponses: [],
        };
      } else {
        if (!state.notSentFiles[payload.channelId]?.filesContent) {
          state.notSentFiles[payload.channelId].filesContent = [];
        }
        if (!state.notSentFiles[payload.channelId]?.uploadedFilesResponses) {
          state.notSentFiles[payload.channelId].uploadedFilesResponses = [];
        }
      }

      // iterate over all files and add them to proper list
      payload.files.forEach((file) => {
        // if recent file is attached
        if (file.saved_file_name) {
          // if new file is already uploaded
          let fileIndex = state.notSentFiles[
            payload.channelId
          ].uploadedFilesResponses.findIndex(
            (fileData) =>
              fileData.uId == file.uId &&
              fileData.saved_file_name == file.saved_file_name
          );
          // if not found then add new one
          if (fileIndex == -1) {
            // add file to the array
            state.notSentFiles[payload.channelId].uploadedFilesResponses.push(
              file
            );
          }
        } else {
          // if new file is being uploaded
          let fileIndex = state.notSentFiles[
            payload.channelId
          ].filesContent.findIndex((fileData) => fileData.uId == file.uId);
          // if not found then add new one
          if (fileIndex == -1) {
            // add file to the array
            state.notSentFiles[payload.channelId].filesContent.push({
              file,
              uId: file.uId,
              isRecording: file.isRecording,
            });
          }
        }
      });
    },
    /**
     * this mutation is responsible to update preserved files which are not sent but uploaded on server successfully
     * args:(state, payload)
     */
    UPDATE_STATUS_SUCCESSFULLY_UPLOADED_NOT_SENT_FILE(state, payload) {
      if (state.notSentFiles[payload.channelId]?.filesContent?.length) {
        // find file index
        let fileIndex = state.notSentFiles[
          payload.channelId
        ]?.filesContent.findIndex(
          (file) => file.uId == payload.fileResponse.uId
        );
        // if file found
        if (fileIndex > -1) {
          // remove from files content
          state.notSentFiles[payload.channelId].filesContent.splice(
            fileIndex,
            1
          );
          // add to file responses
          state.notSentFiles[payload.channelId].uploadedFilesResponses.push(
            payload.fileResponse
          );
        }
      }
    },
    /**
     * this mutation is responsible to remove file from preserve files
     * args:(state, payload)
     */
    REMOVE_NOT_SENT_FILE(state, payload) {
      // remove from files content
      if (state.notSentFiles[payload.channelId]?.filesContent?.length) {
        // find file index
        let fileIndex = state.notSentFiles[
          payload.channelId
        ]?.filesContent.findIndex((file) => file.uId == payload.uId);
        // if file found
        if (fileIndex > -1) {
          // remove from files content
          state.notSentFiles[payload.channelId].filesContent.splice(
            fileIndex,
            1
          );
        }
      }

      // remove file from already uploaded files
      if (
        state.notSentFiles[payload.channelId]?.uploadedFilesResponses?.length
      ) {
        // find file index
        let fileIndex = state.notSentFiles[
          payload.channelId
        ]?.uploadedFilesResponses.findIndex(
          (file) =>
            file.uId == payload.uId &&
            file.saved_file_name == payload.saved_file_name //to handle if user select same recent files multiple time
        );
        // if file found
        if (fileIndex > -1) {
          // remove from files content
          state.notSentFiles[payload.channelId].uploadedFilesResponses.splice(
            fileIndex,
            1
          );
        }
      }
    },
    /**
     * this mutation is responsible to preserve reply files which are attached
     * args:(state, payload)
     */
    SAVE_REPLY_NOT_SENT_FILES(state, payload) {
      // initialize if the files are first time being uploaded
      if (!state.notSentFiles[payload.channelId]) {
        state.notSentFiles[payload.channelId] = {
          replyFilesContent: [],
          replyUploadedFilesResponses: [],
        };
      } else {
        if (!state.notSentFiles[payload.channelId]?.replyFilesContent) {
          state.notSentFiles[payload.channelId].replyFilesContent = [];
        }
        if (
          !state.notSentFiles[payload.channelId]?.replyUploadedFilesResponses
        ) {
          state.notSentFiles[
            payload.channelId
          ].replyUploadedFilesResponses = [];
        }
      }

      // iterate over all files and add them to proper list
      payload.files.forEach((file) => {
        // if recent file is attached
        if (file.saved_file_name) {
          // if new file is already uploaded
          let fileIndex = state.notSentFiles[
            payload.channelId
          ].replyUploadedFilesResponses.findIndex(
            (fileData) =>
              fileData.uId == file.uId &&
              fileData.saved_file_name == file.saved_file_name
          );
          // if not found then add new one
          if (fileIndex == -1) {
            // add file to the array
            file.parentId = payload.parentId;
            // add file to array
            state.notSentFiles[
              payload.channelId
            ].replyUploadedFilesResponses.push(file);
          }
        } else {
          // if new file is being uploaded
          let fileIndex = state.notSentFiles[
            payload.channelId
          ].replyFilesContent.findIndex((fileData) => fileData.uId == file.uId);
          // if not found then add new one
          if (fileIndex == -1) {
            // add file to the array
            state.notSentFiles[payload.channelId].replyFilesContent.push({
              file,
              uId: file.uId,
              isRecording: file.isRecording,
              parentId: payload.parentId,
            });
          }
        }
      });
    },
    /**
     * this mutation is responsible to update successfully uploaed files status which are attached
     * args:(state, payload)
     */
    UPDATE_REPLY_STATUS_SUCCESSFULLY_UPLOADED_NOT_SENT_FILE(state, payload) {
      if (state.notSentFiles[payload.channelId]?.replyFilesContent?.length) {
        // find file index
        let fileIndex = state.notSentFiles[
          payload.channelId
        ]?.replyFilesContent.findIndex(
          (file) => file.uId == payload.fileResponse.uId
        );
        // if file found
        if (fileIndex > -1) {
          // add parent id
          payload.fileResponse.parentId =
            state.notSentFiles[payload.channelId].replyFilesContent[
              fileIndex
            ].parentId;
          // remove from files content
          state.notSentFiles[payload.channelId].replyFilesContent.splice(
            fileIndex,
            1
          );
          // add to file responses
          state.notSentFiles[
            payload.channelId
          ].replyUploadedFilesResponses.push(payload.fileResponse);
        }
      }
    },
    /**
     * this mutation is responsible to remove preserved file which is attached
     * args:(state, payload)
     */
    REMOVE_REPLY_NOT_SENT_FILE(state, payload) {
      // remove from files content
      if (state.notSentFiles[payload.channelId]?.replyFilesContent?.length) {
        // find file index
        let fileIndex = state.notSentFiles[
          payload.channelId
        ]?.replyFilesContent.findIndex((file) => file.uId == payload.uId);
        // if file found
        if (fileIndex > -1) {
          // remove from files content
          state.notSentFiles[payload.channelId].replyFilesContent.splice(
            fileIndex,
            1
          );
        }
      }

      // remove file from already uploaded files
      if (
        state.notSentFiles[payload.channelId]?.replyUploadedFilesResponses
          ?.length
      ) {
        // find file index
        let fileIndex = state.notSentFiles[
          payload.channelId
        ]?.replyUploadedFilesResponses.findIndex(
          (file) =>
            file.uId == payload.uId &&
            file.saved_file_name == payload.saved_file_name &&
            file.parentId == payload.parentId //to handle if user select same recent files multiple time
        );
        // if file found
        if (fileIndex > -1) {
          // remove from files content
          state.notSentFiles[
            payload.channelId
          ].replyUploadedFilesResponses.splice(fileIndex, 1);
        }
      }
    },
    /**
     * this mutation is responsible to remove reply preserved file which is attached
     * args:(state, payload)
     */
    REMOVE_CHANNEL_REPLY_FILES(state, payload) {
      if (state.notSentFiles[payload.channelId]) {
        // remove files from files content array
        if (state.notSentFiles[payload.channelId]?.replyFilesContent?.length) {
          state.notSentFiles[
            payload.channelId
          ].replyFilesContent = state.notSentFiles[
            payload.channelId
          ].replyFilesContent.filter(
            (file) => file.parentId != payload.parentId
          );
        }
        // remove files from files responses array
        if (
          state.notSentFiles[payload.channelId]?.replyUploadedFilesResponses
            ?.length
        ) {
          state.notSentFiles[
            payload.channelId
          ].replyUploadedFilesResponses = state.notSentFiles[
            payload.channelId
          ].replyUploadedFilesResponses.filter(
            (file) => file.parentId != payload.parentId
          );
        }
      }
    },
    CHECK_AND_REMOVE_CHANNEL_OBJECT(state, payload) {
      // remove object if there is no file is associated to specific channel
      if (
        !state.notSentFiles[payload.channelId]?.filesContent?.length &&
        !state.notSentFiles[payload.channelId]?.uploadedFilesResponses
          ?.length &&
        !state.notSentFiles[payload.channelId]?.replyFilesContent?.length &&
        !state.notSentFiles[payload.channelId]?.replyUploadedFilesResponses
          ?.length
      ) {
        delete state.notSentFiles[payload.channelId];
      }
    },
    PRESERVE_MESSAGE(state, payload) {
      state.preservedMsgs.push(payload);
    },
  },
  modules: {
    home,
    messageStore,
    AdminStore,
    companySocket,
    messageSocket,
    channelSocket,
    teamSocket,
    userSocket,
    departmentSocket,
    login,
    signUp,
    inAppNotifications,
    inputState,
    messageReminder,
    messageReadSocket,
    WebHook,
    searchchannel,
    selectedChannelPoling,
    pluginStore,
    pollStore,
    PaymentStore,
    channelPolling,
    globalSearchStore,
  },
  getters: {
    getIsMobile: (state) => state.isMobile,
    getChannelMessages: (state) => (channel_id) => {
      return state.messages[channel_id];
    },
    getMessages: (state) => state.messages,
    //returns queue for provided endpoint
    getRequestQueue: (state) => state.queue,
    getNotifToken: (state) => state.notifToken,
    getStoreData: (state) => state.storeData,
    getReleaseUpdate: (state) => state.newRelease,
    getAppVersion: (state) => state.appVersion,
    getURLandTime: (state) => state.audioMessageURL,
    getRemainingAudio: (state) => state.remainingAudioMessageURLandTime,
    getInstantMsgs: (state) => state.instantMessages,
    getNotSentFiles: (state) => state.notSentFiles,
  },
});
export default store;
