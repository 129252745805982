import axios from "axios";
import router from "@/router";
import debug from "@/console";
const actions = {
  login(context, body) {
    return new Promise((resolve, reject) => {
      axios.post(process.env.VUE_APP_BASE_URL + "/login", body).then(
        (response) => {
          if (response.data.email == true) {
            debug.log("2fa", response);
            context.commit("SET_INTERVAL", 59);
            context.dispatch("sendMobileToken");
            router.push({
              name: "Otp",
              params: {
                token: response.data.token,
              },
            });
          }
          //User will redirect to this componenent if logged-in for first time
          else if (response.data.qr) {
            debug.log("2QR", response);
            context.dispatch("sendMobileToken");
            router.push({
              name: "2FA_QR",
              params: {
                QRData: response.data,
              },
            });
          }
          //user route to this component if he/she set QR authentication
          else if (response.data.qr_authentication) {
            debug.log("2QR_auth", response);
            context.dispatch("sendMobileToken");
            router.push({
              name: "2FA_Key",
              params: {
                token: response.data.token,
              },
            });
          }
          //user route to this route if he/she does not enable any 2-FA authentication
          else {
            debug.log(response);
            localStorage.setItem("token", JSON.stringify(response.data.token));
            localStorage.setItem("App_Version", response.data.version);
            context.commit("APP_DATA", response.data);
            context.commit("setIsMobile", response?.data?.is_mobile || false)
            //to get count of cloud channels
            context.dispatch("getCloudChannelsMsgsCount", {
              page: 1,
              channel_id: context.rootGetters.getSelectedChannel?._id,
            });
            context.dispatch("sendMobileToken");
            if (
              context.rootGetters.getJoinedTeams.length > 0 &&
              context.rootState.selectedChannel
            ) {
              context.dispatch("getMessagesFromAPI");
            }

            //clear first selected channel message if there is any count exist

            context.dispatch("clearMessageRead");

            response.data.selected_company =
              context.getters.getSelectedCompany._id;

            context.dispatch("authenticateSocket", {
              token: response.data.token,
              selected_company: response.data.selected_company,
              status: context.rootGetters.loggedInUserDetails.status,
              version: response.data.version,
            });
            //display 10 channels
            context.dispatch("displayOnlyTenChannels");
            //get channels messages count
            context.dispatch("channelsPolling");

            if (localStorage.getItem("platform")) {
              if (
                (JSON.parse(localStorage.getItem("platform")) == "android" ||
                  JSON.parse(localStorage.getItem("platform")) == "ios") &&
                !response.data.skip_tutorial
              ) {
                router.push("/intro");
              } else {
                // route to home page
                context.dispatch("changeHomeRoute");
              }
            } else {
              // route to home page
              context.dispatch("changeHomeRoute");
            }

            //send Hash
          }
          // http success, call the mutator and change something in state
          resolve(response); // Let the calling function know that http is done. You may send some data back
        },
        (error) => {
          // http failed, let the calling function know that action did not work out
          reject(error);
        }
      );
    });
  },

  //this function invoke when user verify through email (2FA)
  login2FA(context, token) {
    let customAxios = axios.create({
      headers: {
        Token: token,
      }, 
    });
    customAxios
      .post(process.env.VUE_APP_BASE_URL + "/getUserData")
      .then((response) => {
        if (response.status == 200) {
          localStorage.setItem("token", JSON.stringify(response.data.token));
          localStorage.setItem("App_Version", response.data.version);
          context.commit("APP_DATA", response.data);
          //to get count of cloud channels
          context.dispatch("getCloudChannelsMsgsCount", {
            page: 1,
            channel_id: context.rootGetters.getSelectedChannel?._id,
          });
          //clear first selected channel message if there is any count exist
          context.dispatch("clearMessageRead");
          localStorage.setItem("token", JSON.stringify(response.data.token));

          response.data.selected_company =
            context.getters.getSelectedCompany._id;

          context.dispatch("authenticateSocket", {
            token: response.data.token,
            status: context.rootGetters.loggedInUserDetails.status,
            selected_company: response.data.selected_company,
            version: response.data.version,
          });
          //display 10 channels
          context.dispatch("displayOnlyTenChannels");
          //get channels messages count
          context.dispatch("channelsPolling");
          context.dispatch("sendMobileToken");
          if (localStorage.getItem("platform")) {
            if (
              (JSON.parse(localStorage.getItem("platform")) == "android" ||
                JSON.parse(localStorage.getItem("platform")) == "ios") &&
              !response.data.skip_tutorial
            ) {
              router.push("/intro");
            } else {
              // route to home page
              context.dispatch("changeHomeRoute");
            }
          } else {
            // route to home page
            context.dispatch("changeHomeRoute");
          }
          //send Hash
        }
      })
      .catch((err) => {
        debug.log(err);
      });
  },

  //this function invoke when user verify through QR code (2FA)
  send2FA_Key(context, requestObj) {
    return new Promise((resolve, reject) => {
      let customAxios = axios.create({
        headers: {
          Token: requestObj.token,
        },
      });

      customAxios
        .post(process.env.VUE_APP_BASE_URL + "/readGoogle2fa", {
          secret: requestObj.key,
          secret_key: requestObj.secret_key,
        })
        .then((response) => {
          debug.log(response);

          if (response.status == 200) {
            localStorage.setItem("token", JSON.stringify(response.data.token));
            localStorage.setItem("App_Version", response.data.version);
            context.commit("APP_DATA", response.data);
            //to get count of cloud channels
            context.dispatch("getCloudChannelsMsgsCount", {
              page: 1,
              channel_id: context.rootGetters.getSelectedChannel?._id,
            });
            //clear first selected channel message if there is any count exist
            context.dispatch("clearMessageRead");

            response.data.selected_company =
              context.getters.getSelectedCompany._id;

            context.dispatch("authenticateSocket", {
              token: response.data.token,
              status: context.rootGetters.loggedInUserDetails.status,
              selected_company: response.data.selected_company,
              version: response.data.version,
            });
            //display 10 channels
            context.dispatch("displayOnlyTenChannels");
            //get channels messages count
            context.dispatch("channelsPolling");
            resolve(response);

            context.dispatch("sendMobileToken");
            if (localStorage.getItem("platform")) {
              if (
                (JSON.parse(localStorage.getItem("platform")) == "android" ||
                  JSON.parse(localStorage.getItem("platform")) == "ios") &&
                !response.data.skip_tutorial
              ) {
                router.push("/intro");
              } else {
                // route to home page
                context.dispatch("changeHomeRoute");
              }
            } else {
              // route to home page
              context.dispatch("changeHomeRoute");
            }
            //send Hash
          }
        })
        .catch((err) => {
          reject(err);
          debug.log(err);
        });
    });
  },
  sendOTP_Key(context, requestObj) {
    return new Promise((resolve, reject) => {
      let customAxios = axios.create({
        headers: {
          Token: requestObj.token,
        },
      });

      customAxios
        .post(process.env.VUE_APP_BASE_URL + "/authenticateLoginCode", {
          code: requestObj.key,
        })
        .then((response) => {
          debug.log(response);

          if (response.status == 200) {
            if (response.data.qr) {
              localStorage.setItem(
                "token",
                JSON.stringify(response.data.token)
              );

              debug.log("2QR", response);
              router.push({
                name: "2FA_QR",
                params: {
                  QRData: response.data,
                },
              });
            }
            //user route to this component if he/she set QR authentication
            else if (response.data.qr_authentication) {
              debug.log("2QR_auth", response);
              router.push({
                name: "2FA_Key",
                params: {
                  token: response.data.token,
                },
              });
            } else {
              localStorage.setItem(
                "token",
                JSON.stringify(response.data.token)
              );
              localStorage.setItem("App_Version", response.data.version);
              context.commit("APP_DATA", response.data);
              //to get count of cloud channels
              context.dispatch("getCloudChannelsMsgsCount", {
                page: 1,
                channel_id: context.rootGetters.getSelectedChannel?._id,
              });
              //clear first selected channel message if there is any count exist
              context.dispatch("clearMessageRead");

              response.data.selected_company =
                context.getters.getSelectedCompany._id;
              response.data.status =
                context.rootGetters.loggedInUserDetails.status;

              context.dispatch("authenticateSocket", response.data);
              //display 10 channels
              context.dispatch("displayOnlyTenChannels");
              //get channels messages count
              context.dispatch("channelsPolling");
              resolve(response);

              context.dispatch("sendMobileToken");
              if (localStorage.getItem("platform")) {
                if (
                  (JSON.parse(localStorage.getItem("platform")) == "android" ||
                    JSON.parse(localStorage.getItem("platform")) == "ios") &&
                  !response.data.skip_tutorial
                ) {
                  router.push("/intro");
                } else {
                  // route to home page
                  context.dispatch("changeHomeRoute");
                }
              } else {
                // route to home page
                context.dispatch("changeHomeRoute");
              }
              //send Hash
            }
          }
        })
        .catch((err) => {
          reject(err);
          debug.log(err);
        });
    });
  },

  resendOTP(context, requestObj) {
    return new Promise((resolve, reject) => {
      let customAxios = axios.create({
        headers: {
          Token: requestObj.token,
        },
      });

      customAxios
        .post(process.env.VUE_APP_BASE_URL + "/resendEmailAuthenticationCode")
        .then((response) => {
          debug.log(response);

          if (response.status == 200) {
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
          debug.log(err);
        });
    });
  },
  // First Login
  firstLogin(context, obj) {
    return new Promise((resolve, reject) => {
      let token = obj.token;
      debug.log(obj.body, obj.token);

      let customAxios = axios.create({
        headers: {
          Token: token,
        },
      });

      customAxios
        .post(process.env.VUE_APP_BASE_URL + "/resetPassword", obj.body) //this will read data from store.json file which have complete structure and dummy data
        .then((response) => {
          debug.log(response);
          resolve(response);
          // route to home page
          debug.log("firstLogin");
          router.push("/");
        })
        .catch((err) => {
          debug.log(err);
          reject(err);
        });
    });
  },
  logOut(context) {
    let token = JSON.parse(localStorage.getItem("token"));
    let customAxios = axios.create({
      headers: {
        Token: token,
      },
    });
    customAxios
      .post(process.env.VUE_APP_BASE_URL + "/logout", {
        fcm_token: context.rootState.notifToken,
      })
      .then(async (response) => {
        debug.log(response);

        await context.dispatch("disconnectSocket");
        context.commit("logout");
        if (response.data?.saml_logout_url) {
          window.location.assign(response.data?.saml_logout_url);
        } else {
          let login = router.resolve({
            name: "login",
            params: "/",
          });
          window.location.assign(login.href);
        }
      })
      .catch(async (err) => {
        await context.dispatch("disconnectSocket");
        context.commit("logout");
        let login = router.resolve({
          name: "login",
          params: "/",
        });
        window.location.assign(login.href);

        debug.log(err);
      });
    //call logout for mobile apps
    context.dispatch("logoutForMobileApps");
  },
  sendMobileToken(context) {
    if (localStorage.getItem("mobile_token")) {
      context.dispatch("sendPushNotifToken", {
        token: JSON.parse(localStorage.getItem("mobile_token")),
        platform: JSON.parse(localStorage.getItem("platform")),
        version: JSON.parse(localStorage.getItem("version")),
      });
    }
  },
  getUserDataFromSuperAdmin(context, payload) {
    return new Promise((resolve, reject) => {
      let customAxios = axios.create({
        headers: {
          Token: payload.token,
        },
      });
      customAxios
        .post(process.env.VUE_APP_BASE_URL + "/getUserDataFromSuperAdmin", {
          user_id: payload.user_id,
        })
        .then((response) => {
          if (response.status == 200) {
            localStorage.setItem("token", JSON.stringify(response.data.token));
            context.commit("APP_DATA", response.data);
            context.dispatch("selectCompany", response.data.companies[0]._id);
            //to get count of cloud channels
            context.dispatch("getCloudChannelsMsgsCount", {
              page: 1,
              channel_id: context.rootGetters.getSelectedChannel?._id,
            });
            resolve(response);
          }
        })
        .catch((error) => {
          router.push("/linkexpire");

          reject(error);
        });
    });
  },
  /**
   * this action is responsible to call logout function of mobile apps
   * args: ()
   */
  logoutForMobileApps() {
    try {
      window.android.logout();
    } catch (error) {
      debug.log("android ", error);
    }
    try {
      window.webkit.messageHandlers.ios.postMessage(
        JSON.stringify({
          "event-type": "logout",
        })
      );
    } catch (error) {
      debug.log("ios", error);
    }
  },
  /**
   * this action is responsible to call userLoginViaSaml function for login user via saml
   * args: (context, payload)
   */
  userLoginViaSaml(context, payload) {
    let customAxios = axios.create({
      headers: {
        Token: payload.token,
      },
    });
    return new Promise((resolve, reject) => {
      customAxios.post(process.env.VUE_APP_BASE_URL + "/getUserData").then(
        (response) => {
          debug.log(response);
          localStorage.setItem("token", JSON.stringify(response.data.token));
          localStorage.setItem("App_Version", response.data.version);
          context.commit("APP_DATA", response.data);
          //to get count of cloud channels
          context.dispatch("getCloudChannelsMsgsCount", {
            page: 1,
            channel_id: context.rootGetters.getSelectedChannel?._id,
          });
          context.dispatch("sendMobileToken");
          if (
            context.rootGetters.getJoinedTeams.length > 0 &&
            context.rootState.selectedChannel
          ) {
            context.dispatch("getMessagesFromAPI");
          }

          //clear first selected channel message if there is any count exist

          context.dispatch("clearMessageRead");

          response.data.selected_company =
            context.getters.getSelectedCompany._id;

          context.dispatch("authenticateSocket", {
            token: response.data.token,
            selected_company: response.data.selected_company,
            status: context.rootGetters.loggedInUserDetails.status,
            version: response.data.version,
          });
          //display 10 channels
          context.dispatch("displayOnlyTenChannels");
          //get channels messages count
          context.dispatch("channelsPolling");

          if (localStorage.getItem("platform")) {
            if (
              (JSON.parse(localStorage.getItem("platform")) == "android" ||
                JSON.parse(localStorage.getItem("platform")) == "ios") &&
              !response.data.skip_tutorial
            ) {
              router.push("/intro");
            } else {
              // route to home page
              context.dispatch("changeHomeRoute");
            }
          } else {
            // route to home page
            context.dispatch("changeHomeRoute");
          }
          resolve(response);
        },
        (error) => {
          // http failed, let the calling function know that action did not work out
          reject(error);
          context.dispatch("logOut");
        }
      );
    });
  },
};
export default {
  actions,
};
