/*
System: Whistle It
Developer: M Saud Saleem
Organization: Whistle It
Purpose: This file is used for making api calls for plugin
*/
import requestQueue from "@/service/requestQueue";
import debug from "@/console";
let requestBody;
let requestEndPoint;
let token;

const pluginAPI = {
     /*
    This function is responsible for making api call for add user in plugin
    args:(plugin_id,company_id), callback function for done
    */
   addPlugin: (body, done) => {
    requestBody = body
    token = JSON.parse(localStorage.getItem("token"));
    requestEndPoint = "plugin/member/add";
    requestQueue.post(requestEndPoint, token, requestBody, done);
},
removePlugin: (body, done) => {
    requestBody = body
    token = JSON.parse(localStorage.getItem("token"));
    requestEndPoint = "plugin/member/remove";
    requestQueue.post(requestEndPoint, token, requestBody, done);
},
readPlugin:(done)=>{
    token = JSON.parse(localStorage.getItem("token"));
    requestEndPoint = "plugin/read";
    requestQueue.post(requestEndPoint, token,{}, done);
},
createPluginLink:(body, done)=>{
     requestBody = body
     token = JSON.parse(localStorage.getItem("token"));
     requestEndPoint = "zoomMeeting";
     requestQueue.post(requestEndPoint, token, requestBody, done);
}

};

export default pluginAPI;