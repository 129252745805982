/*
System: Whistle It
Developer: Ahmad Hannan
Organization: Whistle It
Purpose: This file is used for making api calls for teams
*/
import requestQueue from "@/service/requestQueue";
import debug from "@/console";
var requestBody;
var requestEndPoint;
var token;

const teamAPI = {
    /*
This function is responsible for making api call for add team
args:(name,company_id,profile_picture), callback function for done
*/
    createTeam: (body, done) => {
        requestEndPoint = "team/create";
        token = JSON.parse(localStorage.getItem("token"));
        requestQueue.post(requestEndPoint, token, body, done);
    },
    /*
This function is responsible for making api call for add users in team
args:(team_ids[],users[]), callback function for done
*/
    addUsersInTeam: (team_id, user_ids, done) => {
        requestBody = {
            team_id: team_id,
            user_ids: user_ids,
        };
        requestEndPoint = "team/member/add";
        token = JSON.parse(localStorage.getItem("token"));
        requestQueue.post(requestEndPoint, token, requestBody, done);
    },

    /*
This function is responsible for making api call for remove users in team
args:(team_ids[],users[]), callback function for done
*/
    removeUsersInTeam: (team_id, user_ids, done) => {
        requestBody = {
            team_id: team_id,
            user_ids: user_ids,
        };
        requestEndPoint = "team/member/remove";
        token = JSON.parse(localStorage.getItem("token"));
        requestQueue.post(requestEndPoint, token, requestBody, done);
        debug.log(team_id, user_ids);
    },
    /*
This function is responsible for making api call for update team
args:(team_name,team_id), callback function for done
*/
    updateTeamName: (team_id, name, profile_picture, deleted_at, done) => {
        requestBody = {
            team_id,
            name,
            profile_picture,
            deleted_at,
        };
        requestEndPoint = "team/update";
        token = JSON.parse(localStorage.getItem("token"));
        requestQueue.post(requestEndPoint, token, requestBody, done);
    },
    /*
This function is responsible for making api call for update team
args:(team_id, company_id, team_icon), callback function for done
*/
    updateTeamIcon: (requestObj, done) => {
        requestBody = requestObj;
        requestEndPoint = "team/update";
        token = JSON.parse(localStorage.getItem("token"));
        requestQueue.post(requestEndPoint, token, requestBody, done);
    },
    /*
This function is responsible for making api call for delete team icon
args:(team_id, company_id), callback function for done
*/
    deleteTeamIcon: (team_id, profile_picture, done) => {
        requestBody = {
            team_id: team_id,
            profile_picture: profile_picture,
        };
        requestEndPoint = "team/update";
        token = JSON.parse(localStorage.getItem("token"));
        requestQueue.post(requestEndPoint, token, requestBody, done);
    },
    /*
This function is responsible for making api call for archive team
args:(company_id, team_id), callback function for done
*/
    archiveTeam: async(company_id, team_id, deleted_at, done) => {
        requestBody = {
            company_id: company_id,
            team_id: team_id,
            deleted_at: deleted_at,
        };
        requestEndPoint = "team/update";
        token = JSON.parse(localStorage.getItem("token"));
        requestQueue.post(requestEndPoint, token, requestBody, done);
    },
    /*
This function is responsible for making api call for unarchive team
args:(company_id, team_id), callback function for done
*/
    unarchiveTeam: (company_id, team_id, deleted_at, done) => {
        requestBody = {
            company_id: company_id,
            team_id: team_id,
            deleted_at: deleted_at,
        };
        requestEndPoint = "team/update";
        token = JSON.parse(localStorage.getItem("token"));
        requestQueue.post(requestEndPoint, token, requestBody, done);
    },
    /*
This function is responsible for making api call for leave team
args:(user_id[] users to leave, team_id), callback function for done
*/
    leaveTeam: (user_ids, team_id, done) => {
        requestBody = {
            user_ids,
            team_id,
        };
        requestEndPoint = "team/member/remove";
        token = JSON.parse(localStorage.getItem("token"));
        requestQueue.post(requestEndPoint, token, requestBody, done);
    },
 /*
    this method fetch selected company data from API
    args (company_id)
*/
    fetchTeams: (requestBody, done) => {
        requestEndPoint = "adminPanel/getTeams";
        token = JSON.parse(localStorage.getItem("token"));
        requestQueue.post(requestEndPoint, token, requestBody, done);
    },

  /*
    this method fetch non members of specific team
    args (team_id)
       */
fetchTeamNonMembers: (requestBody, done) => {
    requestEndPoint = "adminPanel/usersNotInCompanyTeam";
    token = JSON.parse(localStorage.getItem("token"));
    requestQueue.post(requestEndPoint, token, requestBody, done);
},
  /*
    this method fetch  members of specific team
    args (team_id)
       */
fetchTeamMembers: (requestBody, done) => {
    requestEndPoint = "adminPanel/teamUsers";
    token = JSON.parse(localStorage.getItem("token"));
    requestQueue.post(requestEndPoint, token, requestBody, done);
},
};

export default teamAPI;